<template>
  <div class="show-img" @mouseup="e => characterUp(e)">
    <div class="show-img-content">
      <div class="show-img-top" @mousemove="e => disMovePage(e)">
        <div class="show-img-top-border"></div>
        <div :style="{ backgroundColor }">
          <div class="show-img-top-left">
            <!-- {{ pageListData.length ? pageListData[selectIdx].fileName : '' }} -->
          </div>
          <div class="show-img-top-center">
            <span
              @click="changeLeftOption(option.value)"
              :class="option.checked ? 'show-option show-option-select' : 'show-option'"
              v-for="option in showOptions.leftOptions"
              :key="option.id"
              >{{ option.title }}</span
            >
          </div>
          <div class="show-img-top-right"></div>
        </div>
        <div :style="{ backgroundColor }">
          <div class="show-img-top-left">
            <!--  {{ pageListData.length ? pageListData[selectIdx].fileName : '' }} -->
          </div>
          <div class="show-img-top-center">
            <span
              @click="changeRightOption(option.value)"
              :class="option.checked ? 'show-option show-option-select' : 'show-option'"
              v-for="option in showOptions.rightOptions"
              :key="option.id"
              >{{ option.title }}</span
            >
          </div>
          <div class="show-img-top-right">
            <div>
              <a-icon type="double-right" />
            </div>
          </div>
        </div>
      </div>
      <div
        :class="
          isAddText
            ? 'show-img-bottom show-img-bottom-textArea'
            : isAddLine
            ? 'show-img-bottom show-img-bottom-line'
            : isAddAnnotation
            ? 'show-img-bottom show-img-bottom-local'
            : 'show-img-bottom'
        "
        @scroll="showImgScroll"
        :style="{ backgroundColor }"
        @click.stop="showImgClick"
        @mouseup="textAreaMouseup"
        @mousemove="e => disMovePage(e)"
        @mouseover="topCenterMouseout"
      >
        <a-dropdown :trigger="['contextmenu']">
          <div class="show-img-bottom-left">
            <div :style="{ minHeight: '100%', backgroundColor }">
              <div
                v-if="checkPermission('showImgLeft:view')"
                @contextmenu.stop
                :style="{ minHeight: '100%', width: showImgListWidth }"
              >
                <template v-if="showOptions.leftOptions[0].checked">
                  <img
                    v-for="(item, idx) in pageListData"
                    :ref="item.id + 'showLeftImg'"
                    @dragstart.prevent
                    :key="item.id"
                    :src="item.filePath"
                    :class="
                      imgShow(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0])
                        ? 'left-show-img'
                        : 'left-show-img left-show-img-blur'
                    "
                    :style="
                      'width:100%;box-siz;height:auto;border-bottom:2px dashed ' +
                        (idx == pageListData.length - 1 ? 'rgba(0,0,0,0)' : 'orange')
                    "
                    alt=""
                  />
                  <!-- <div style="height:500px;width:100%"></div> -->
                  <div
                    class="ocr-show ocr-show-left"
                    ref="dragTextBox"
                    @click="ocrShowClick"
                    @mousedown="dragBoxmousedown"
                    v-if="pageListData.length && showImgLeftListPostions.length"
                  >
                    <div
                      class="text_mask"
                      v-if="is_show_text_mask"
                      :style="
                        'width:' +
                          mask_width +
                          'px;left:' +
                          mask_left +
                          'px;height:' +
                          mask_height +
                          'px;top:' +
                          mask_top +
                          'px;'
                      "
                    ></div>
                    <div
                      class="ocr-show-page"
                      @mousedown.self="clearSelect"
                      v-for="(page, idx) in pageListData"
                      :id="page.id + '-left-' + idx"
                      :key="page.id"
                      :style="{
                        height: showImgLeftListPostions[idx] && showImgLeftListPostions[idx].height + 'px',
                        overflow: rightImgShow ? 'initial' : 'hidden'
                      }"
                    >
                      <div
                        class="delete-border"
                        @mousedown.self="clearSelect"
                        v-if="selectTextAreas.length > 1 && getDeleteBorder.idx === idx"
                        :style="getDeleteBorder"
                      >
                        <div><a-icon type="close" @click.stop="batchDeleteTextArea" /></div>
                      </div>
                      <template v-for="item in page.textAreas">
                        <vue-draggable-resizable
                          v-if="
                            (textShow(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page) ||
                              idx == pageListData.length - 1) &&
                              (item.id + '').indexOf('new') == -1 &&
                              leftImgShow &&
                              !item.imgUrl
                          "
                          class="ocr-show-page-text"
                          :style="
                            `position: absolute; border: none;min-width:${
                              showImgLeftListPostions[idx]
                                ? (showImgLeftListPostions[idx].width / page.width) * 80
                                : '0'
                            }px;min-height:${
                              showImgLeftListPostions[idx]
                                ? (showImgLeftListPostions[idx].width / page.width) * 60
                                : '0'
                            }px`
                          "
                          :ref="'draggable-' + item.id"
                          :v-model:active="item.textAreaRegion.selected ? true : false"
                          @activated="onActivated(item, 'left', page)"
                          @deactivated="onDeactivated(item, 'left', page)"
                          @resizing="
                            (left, top, width, height) => {
                              onResizing(left, top, width, height, item, page, showImgLeftListPostions[idx])
                            }
                          "
                          @resizestop="onResizstop(item, 'left', page)"
                          @dragstop="
                            (left, top) => onDragstop(left, top, item, page, showImgLeftListPostions[idx], 'left')
                          "
                          :parent="true"
                          :draggable="
                            item.translateTextAreaRegion.editble || isCheckText || isDelete
                              ? false
                              : checkPermission('addTextArea:edit') && changeAll && item.textAreaRegion.selected
                              ? true
                              : false
                          "
                          :resizable="checkPermission('addTextArea:edit') && taskStatus == '可开始' ? true : false"
                          :x="
                            getItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page, 'left')
                              .x
                          "
                          :y="
                            getItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page, 'left')
                              .y
                          "
                          :h="
                            getItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page, 'left')
                              .h
                          "
                          :w="
                            getItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page, 'left')
                              .w
                          "
                          :minW="100"
                          :z="item.textAreaRegion.selected ? 99 : 97"
                          :key="item.id"
                        >
                          <div
                            @click.stop
                            @mousedown="textAreaMousedown"
                            @mouseup="textAreaMouseup"
                            @mousemove="e => leftMove(e, item)"
                            @mouseleave="e => leftOut(e, item)"
                            :id="page.id + '-' + item.id + '-' + 'left'"
                            :class="
                              item.textAreaRegion.selected
                                ? 'ocr-show-item-selected ocr-show-item'
                                : item.isReject
                                ? 'ocr-show-item ocr-show-item-reject'
                                : 'ocr-show-item'
                            "
                          >
                            <div
                              v-if="item.textAreaRegion.selected && select_list.length == 1"
                              @mousemove="isCheckText = true"
                              @mouseout="isCheckText = false"
                              @click.stop
                              style="user-select: text;"
                            >
                              <!-- 陈立说只有审核岗显示错误类型选择 -->
                              <!-- 王坤喊改成校对被驳回不显示 -->

                              <!-- (((taskDetail.beingTested && !taskDetail.rejectTaskId) ||
                                    glossaryTags.filter(
                                      w =>
                                        (item.originalText &&
                                          (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                            w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                        (item.showText &&
                                          (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                                            w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
                                    ).length ||
                                    terms.filter(
                                      w =>
                                        (item.originalText &&
                                          item.originalText.length > 0 &&
                                          w.valueX &&
                                          (item.originalText.replaceAll('\n', '').indexOf(w.valueX) > -1 ||
                                            w.valueX.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                        (item.originalText &&
                                          item.originalText.length > 0 &&
                                          w.valueM &&
                                          (item.originalText.replaceAll('\n', '').indexOf(w.valueM) > -1 ||
                                            w.valueM.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                        (item.showText &&
                                          item.showText.length > 0 &&
                                          w.targetX &&
                                          (item.showText.replaceAll('\n', '').indexOf(w.targetX) > -1 ||
                                            w.targetX.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                                        (item.showText &&
                                          item.showText.length > 0 &&
                                          w.targetM &&
                                          (item.showText.replaceAll('\n', '').indexOf(w.targetM) > -1 ||
                                            w.targetM.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                                        (item.showText &&
                                          item.showText.length > 0 &&
                                          w.target &&
                                          (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                                            w.target.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                                        (item.originalText &&
                                          item.originalText.length > 0 &&
                                          w.value &&
                                          (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                            w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1))
                                    ).length ||
                                    termBacks.filter(
                                      w =>
                                        (item.originalText &&
                                          w.value &&
                                          item.originalText.length > 0 &&
                                          (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                            w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                        (item.showText &&
                                          w.target &&
                                          item.showText.length > 0 &&
                                          (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                                            w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
                                    ).length ||
                                    termNotes.filter(
                                      w => w.value && item.showText.replaceAll('\n', '').indexOf(w.value) > -1
                                    ).length ||
                                    yahooOrKdxList.length ||
                                    phoneticBlackList.filter(
                                      w =>
                                        item.showText &&
                                        item.showText.length > 0 &&
                                        (item.showText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                          w.value.indexOf(item.showText.replaceAll('\n', '')) > -1)
                                    ).length ||
                                    (blacklist.length &&
                                      (blacklist.some(words => getBlackWordsTwo(item, words).length) ||
                                        blacklist.some(words => words.classId != 12 && getBlackWords(item, words))))) &&
                                    blackListMode == 1) ||
                                    departName.indexOf('日语部') > -1 -->
                              <div
                                @mouseenter="e => leftOut(e, item)"
                                @mousemove.stop="isCheckText = true"
                                class="ocr-show-item-action"
                                :style="getActionStyle(showImgLeftListPostions[idx], page, item, 'left', idx)"
                              >
                                <!-- v-if="
                                  (taskDetail.stageName == '翻译' && topShow) ||
                                    (taskDetail.stageName == '校对' && taskDetail.taskStatus == '可开始') ||
                                    (taskDetail.stageName == '校对' && taskDetail.taskStatus == '已完成')
                                " -->
                                <div class="ocr-show-item-action-top" v-if="changeAll">
                                  <div
                                    v-if="taskDetail.beingTested && !taskDetail.rejectTaskId"
                                    class="ocr-show-item-action-radio"
                                  >
                                    <!-- <span
                                    v-if="
                                      taskDetail.stageName != '翻译' ||
                                        (taskDetail.stageName == '翻译' && topFeedbacks.length)
                                    "
                                    :style="
                                      taskDetail.stageName == '翻译' || !changeAll || isRejectStatus
                                        ? 'width:50px;'
                                        : 'width:50px;'
                                    "
                                    @click.stop="changeInjuryType(0)"
                                    :class="!selectErrorType ? 'error-span' : 'no-error-span'"
                                    >反馈<a-icon
                                      v-if="selectErrorTypes.some(s => s == 0)"
                                      type="check-circle"
                                      theme="filled"
                                  /></span> -->
                                    <!-- v-if="
                                      taskDetail.stageName != '翻译' ||
                                        (taskDetail.stageName == '翻译' && selectErrorType == 3)
                                    " -->
                                    <span
                                      @mousedown.stop="changeInjuryType(3, item)"
                                      :class="selectErrorType == 3 ? 'error-span' : 'no-error-span'"
                                      :style="
                                        taskDetail.stageName == '翻译' || !changeAll || isRejectStatus
                                          ? 'color: red; margin-left: 3px;'
                                          : 'color: red; margin-left: 3px'
                                      "
                                      >错<a-icon v-if="selectErrorType == 3" type="check-circle" theme="filled"
                                    /></span>
                                    <!-- v-if="
                                      taskDetail.stageName != '翻译' ||
                                        (taskDetail.stageName == '翻译' && selectErrorType == 2)
                                    " -->
                                    <span
                                      @mousedown.stop="changeInjuryType(2, item)"
                                      :class="selectErrorType == 2 ? 'error-span' : 'no-error-span'"
                                      style="color: red; margin-left: 3px"
                                      :style="
                                        taskDetail.stageName == '翻译' || !changeAll || isRejectStatus
                                          ? 'color: red; margin-left: 3px;'
                                          : 'color: red; margin-left: 3px'
                                      "
                                      >硬<a-icon v-if="selectErrorType == 2" type="check-circle" theme="filled"
                                    /></span>
                                    <!-- v-if="
                                      taskDetail.stageName != '翻译' ||
                                        (taskDetail.stageName == '翻译' && selectErrorType == 1)
                                    " -->
                                    <span
                                      @mousedown.stop="changeInjuryType(1, item)"
                                      :class="selectErrorType == 1 ? 'error-span' : 'no-error-span'"
                                      :style="
                                        taskDetail.stageName == '翻译' || !changeAll || isRejectStatus
                                          ? 'color: red; margin-left: 3px;'
                                          : 'color: red; margin-left: 3px'
                                      "
                                      >软<a-icon v-if="selectErrorType == 1" type="check-circle" theme="filled"
                                    /></span>
                                    <!-- v-if="
                                      taskDetail.stageName != '翻译' ||
                                        (taskDetail.stageName == '翻译' && topRejectComments.length)
                                    " -->
                                    <span
                                      @mousedown.stop="changeInjuryType(4, item)"
                                      :class="selectErrorType == 4 ? 'error-span' : 'no-error-span'"
                                      :style="
                                        taskDetail.stageName == '翻译' || !changeAll || isRejectStatus
                                          ? 'color: orange; margin-left: 3px;'
                                          : 'color: orange; margin-left: 3px'
                                      "
                                      >驳<a-icon v-if="selectErrorType == 4" type="check-circle" theme="filled"
                                    /></span>
                                  </div>
                                  <div style="display:flex">
                                    <div
                                      class="check-phonetic"
                                      v-if="
                                        taskDetail.targetLang == 'JAPANESE' &&
                                          ((taskDetail.taskStatus == '驳回' &&
                                            taskDetail.rejectTaskId &&
                                            item.isReject) ||
                                            taskDetail.taskStatus == '可开始')
                                      "
                                    >
                                      <a @click="checkPhonetic(item)">检测注音</a>
                                    </div>
                                    <div class="check-phonetic" v-if="taskDetail.isMachineTranslation">
                                      <a-spin :spinning="ocrLoading">
                                        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                                        <a @click="changeTranslate(item)">切换机翻</a>
                                      </a-spin>
                                    </div>
                                    <div class="check-phonetic">
                                      <a-spin :spinning="ocrLoading">
                                        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                                        <a @click="ocr(item)">识别文字</a>
                                      </a-spin>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  v-if="
                                    glossaryTags.filter(
                                      w =>
                                        (item.originalText &&
                                          (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                            w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                        (item.showText &&
                                          (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                                            w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
                                    ).length ||
                                      terms.filter(
                                        w =>
                                          (item.originalText &&
                                            item.originalText.length > 0 &&
                                            w.valueX &&
                                            (item.originalText.replaceAll('\n', '').indexOf(w.valueX) > -1 ||
                                              w.valueX.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                          (item.originalText &&
                                            item.originalText.length > 0 &&
                                            w.valueM &&
                                            (item.originalText.replaceAll('\n', '').indexOf(w.valueM) > -1 ||
                                              w.valueM.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                          (item.showText &&
                                            item.showText.length > 0 &&
                                            w.targetX &&
                                            (item.showText.replaceAll('\n', '').indexOf(w.targetX) > -1 ||
                                              w.targetX.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                                          (item.showText &&
                                            item.showText.length > 0 &&
                                            w.targetM &&
                                            (item.showText.replaceAll('\n', '').indexOf(w.targetM) > -1 ||
                                              w.targetM.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                                          (item.showText &&
                                            item.showText.length > 0 &&
                                            w.target &&
                                            (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                                              w.target.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                                          (item.originalText &&
                                            item.originalText.length > 0 &&
                                            w.value &&
                                            (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                              w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1))
                                      ).length ||
                                      termBacks.filter(
                                        w =>
                                          (item.originalText &&
                                            w.value &&
                                            item.originalText.length > 0 &&
                                            (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                              w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                          (item.showText &&
                                            w.target &&
                                            item.showText.length > 0 &&
                                            (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                                              w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
                                      ).length ||
                                      termNotes.filter(
                                        w => w.value && item.showText.replaceAll('\n', '').indexOf(w.value) > -1
                                      ).length ||
                                      (yahooOrKdxList.length &&
                                        (yahooOrKdxList.some(words => getBlackWordsTwo(item, words).length) ||
                                          yahooOrKdxList.some(
                                            words => words.classId != 12 && getBlackWords(item, words)
                                          ))) ||
                                      checkPhoneticList.length ||
                                      (blacklist.length &&
                                        (blacklist.some(words => getBlackWordsTwo(item, words).length) ||
                                          blacklist.some(
                                            words => words.classId != 12 && getBlackWords(item, words)
                                          ))) ||
                                      phoneticBlackList.filter(
                                        w =>
                                          item.showText &&
                                          item.showText.length > 0 &&
                                          (item.showText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                            w.value.indexOf(item.showText.replaceAll('\n', '')) > -1)
                                      ).length
                                  "
                                  style="font-size:12px;"
                                  class="ocr-show-item-action-bottom"
                                >
                                  <div
                                    v-if="
                                      termNotes.filter(
                                        w => w.value && item.showText.replaceAll('\n', '').indexOf(w.value) > -1
                                      ).length
                                    "
                                  >
                                    <div style="font-weight: bold; padding-bottom: 8px">表记：</div>
                                    <div
                                      v-for="(word, wordIdx) in termNotes.filter(
                                        w => w.value && item.showText.replaceAll('\n', '').indexOf(w.value) > -1
                                      )"
                                      :key="wordIdx"
                                      style="padding-bottom: 8px"
                                    >
                                      <div style="justify-content: space-between;display:flex">
                                        <span
                                          >{{ word.value + '→' + word.target
                                          }}{{ word.isProduction ? '（作品）' : '' }}</span
                                        >
                                        <span>
                                          <a
                                            v-if="changeAll && getIsAddTableNote(item, word)"
                                            @mousedown.stop="addTableNote(item, word)"
                                            >替换<!-- {{ getIsAddTableNote(item, word) ? '撤销' : '替换' }} --></a
                                          >
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-if="
                                      terms.filter(
                                        w =>
                                          (item.originalText &&
                                            item.originalText.length > 0 &&
                                            w.valueX &&
                                            (item.originalText.replaceAll('\n', '').indexOf(w.valueX) > -1 ||
                                              w.valueX.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                          (item.originalText &&
                                            item.originalText.length > 0 &&
                                            w.valueM &&
                                            (item.originalText.replaceAll('\n', '').indexOf(w.valueM) > -1 ||
                                              w.valueM.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                          (item.showText &&
                                            item.showText.length > 0 &&
                                            w.targetX &&
                                            (item.showText.replaceAll('\n', '').indexOf(w.targetX) > -1 ||
                                              w.targetX.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                                          (item.showText &&
                                            item.showText.length > 0 &&
                                            w.targetM &&
                                            (item.showText.replaceAll('\n', '').indexOf(w.targetM) > -1 ||
                                              w.targetM.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                                          (item.showText &&
                                            item.showText.length > 0 &&
                                            w.target &&
                                            (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                                              w.target.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                                          (item.originalText &&
                                            item.originalText.length > 0 &&
                                            w.value &&
                                            (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                              w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1))
                                      ).length
                                    "
                                  >
                                    <div style="color: green; font-weight: bold; padding-bottom: 8px">术语：</div>
                                    <div
                                      class="term-item"
                                      v-for="(word, wordIdx) in terms.filter(
                                        w =>
                                          (item.originalText &&
                                            item.originalText.length > 0 &&
                                            w.valueX &&
                                            (item.originalText.replaceAll('\n', '').indexOf(w.valueX) > -1 ||
                                              w.valueX.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                          (item.originalText &&
                                            item.originalText.length > 0 &&
                                            w.valueM &&
                                            (item.originalText.replaceAll('\n', '').indexOf(w.valueM) > -1 ||
                                              w.valueM.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                          (item.showText &&
                                            item.showText.length > 0 &&
                                            w.targetX &&
                                            (item.showText.replaceAll('\n', '').indexOf(w.targetX) > -1 ||
                                              w.targetX.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                                          (item.showText &&
                                            item.showText.length > 0 &&
                                            w.targetM &&
                                            (item.showText.replaceAll('\n', '').indexOf(w.targetM) > -1 ||
                                              w.targetM.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                                          (item.showText &&
                                            item.showText.length > 0 &&
                                            w.target &&
                                            (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                                              w.target.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                                          (item.originalText &&
                                            item.originalText.length > 0 &&
                                            w.value &&
                                            (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                              w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1))
                                      )"
                                      :key="wordIdx"
                                      style="padding-bottom: 8px"
                                    >
                                      <span>
                                        {{
                                          word.value +
                                            '→' +
                                            word.target +
                                            (termsPhoneticsAll.findIndex(
                                              tp =>
                                                (tp.processedFamilyName || '') + (tp.processedGivenName || '') ==
                                                  word.target || tp.processedName == word.target
                                            ) > -1 &&
                                            termsPhoneticsAll.find(
                                              tp =>
                                                (tp.processedFamilyName || '') + (tp.processedGivenName || '') ==
                                                  word.target || tp.processedName == word.target
                                            ).phoneticText
                                              ? '(' +
                                                termsPhoneticsAll.find(
                                                  tp =>
                                                    (tp.processedFamilyName || '') + (tp.processedGivenName || '') ==
                                                      word.target || tp.processedName == word.target
                                                ).phoneticText +
                                                ')'
                                              : '')
                                        }}
                                        <span style="font-weight:700;margin-left:10px">{{
                                          word.status == '已确认' ? `` : '未确认'
                                        }}</span>
                                      </span>
                                      <span style="display:flex;min-width:55px;justify-content: end;"
                                        ><a
                                          v-if="
                                            termsPhoneticsAll.findIndex(
                                              tp =>
                                                (tp.processedFamilyName || '') + (tp.processedGivenName || '') ==
                                                  word.target || tp.processedName == word.target
                                            ) > -1 &&
                                              termsPhoneticsAll.find(
                                                tp =>
                                                  (tp.processedFamilyName || '') + (tp.processedGivenName || '') ==
                                                    word.target || tp.processedName == word.target
                                              ).phoneticText
                                          "
                                          @click.stop="
                                            addPhoneticAll(
                                              item,
                                              termsPhoneticsAll.find(
                                                tp =>
                                                  (tp.processedFamilyName || '') + (tp.processedGivenName || '') ==
                                                    word.target || tp.processedName == word.target
                                              )
                                            )
                                          "
                                          style="margin-right:5px"
                                        >
                                          {{
                                            getIsAddPhoneticTextAll(
                                              item,
                                              termsPhoneticsAll.find(
                                                tp =>
                                                  (tp.processedFamilyName || '') + (tp.processedGivenName || '') ==
                                                    word.target || tp.processedName == word.target
                                              )
                                            )
                                              ? '撤销'
                                              : '注音'
                                          }}
                                        </a>
                                        <a @mousedown.stop="openTerm(word)">查看</a>
                                      </span>
                                    </div>
                                  </div>
                                  <!--                                   <div v-if="termsPhonetics.length && checkPermission('translate:auto:phonetic')">
                                    <div style="font-weight: bold; padding-bottom: 8px">自动注音情况：</div>
                                    <div
                                      v-for="(word, wordIdx) in termsPhonetics.filter(word => word.phoneticText)"
                                      :key="wordIdx"
                                      style="padding-bottom: 8px;justify-content: space-between;display:flex"
                                    >
                                      <span>
                                        {{
                                          word.autoPhoneticTrigger == 0
                                            ? '作品中每次：'
                                            : word.autoPhoneticTrigger == 1
                                            ? '作品中首次：'
                                            : word.autoPhoneticTrigger == 2
                                            ? '每话首次：'
                                            : word.autoPhoneticTrigger == 3
                                            ? '每页首次：'
                                            : word.autoPhoneticTrigger == 4
                                            ? '人员校正：'
                                            : ''
                                        }}{{ `${word.processedName || '无译文名'}（${word.phoneticText}）` }}
                                      </span>
                                      <span style="display:inline-block;min-width:25px">
                                        <a v-if="changeAll" @click.stop="addPhonetic(item, word)">{{
                                          getIsAddPhoneticText(item, word) ? '撤销' : '注音'
                                        }}</a>
                                      </span>
                                    </div>
                                  </div> -->
                                  <div v-if="checkPhoneticList.length">
                                    <div style="display:flex;justify-content: space-between; padding-bottom: 8px">
                                      <span style="color: green;font-weight: bold;">需注音：</span>
                                      <a @click.stop="addAllYahooPhotic(item)">{{
                                        checkHasAllPhonetic(item) ? '全部注音' : '全部撤销'
                                      }}</a>
                                    </div>
                                    <div
                                      v-for="(word, wordIdx) in checkPhoneticList"
                                      style="padding-bottom: 8px;display:flex;justify-content: space-between;"
                                      :key="wordIdx"
                                    >
                                      <span> {{ word.surface }}（{{ word.furigana }}） </span>
                                      <span style="display:inline-block;min-width:25px">
                                        <a @click.stop="addYahooPhotic(item, word)">{{
                                          checkHasPhonetic(item, word) ? '注音' : '撤销'
                                        }}</a>
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    v-if="
                                      phoneticBlackList.filter(
                                        w =>
                                          item.showText &&
                                          item.showText.length > 0 &&
                                          (item.showText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                            w.value.indexOf(item.showText.replaceAll('\n', '')) > -1)
                                      ).length
                                    "
                                  >
                                    <div style="color: red; font-weight: bold; padding-bottom: 8px">不注音：</div>
                                    <div
                                      v-for="(word, wordIdx) in phoneticBlackList.filter(
                                        w =>
                                          item.showText &&
                                          item.showText.length > 0 &&
                                          (item.showText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                            w.value.indexOf(item.showText.replaceAll('\n', '')) > -1)
                                      )"
                                      style="padding-bottom: 8px"
                                      :key="wordIdx"
                                    >
                                      {{ word.value }}
                                    </div>
                                  </div>
                                  <div
                                    v-if="
                                      glossaryTags.filter(
                                        w =>
                                          (item.originalText &&
                                            (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                              w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                          (item.showText &&
                                            (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                                              w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
                                      ).length
                                    "
                                  >
                                    <div style="color: green; font-weight: bold; padding-bottom: 8px">专有词汇：</div>
                                    <div
                                      v-for="(word, wordIdx) in glossaryTags.filter(
                                        w =>
                                          (item.originalText &&
                                            (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                              w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                          (item.showText &&
                                            (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                                              w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
                                      )"
                                      style="padding-bottom: 8px"
                                      :key="wordIdx"
                                    >
                                      {{ word.value + '→' + word.target }}
                                    </div>
                                  </div>
                                  <div
                                    v-if="
                                      termBacks.filter(
                                        w =>
                                          (item.originalText &&
                                            w.value &&
                                            item.originalText.length > 0 &&
                                            (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                              w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                          (item.showText &&
                                            w.target &&
                                            item.showText.length > 0 &&
                                            (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                                              w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
                                      ).length
                                    "
                                  >
                                    <div style="color: green; font-weight: bold; padding-bottom: 8px">背景册：</div>
                                    <div
                                      v-for="(word, wordIdx) in termBacks.filter(
                                        w =>
                                          (item.originalText &&
                                            w.value &&
                                            item.originalText.length > 0 &&
                                            (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                                              w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                                          (item.showText &&
                                            w.target &&
                                            item.showText.length > 0 &&
                                            (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                                              w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
                                      )"
                                      :key="wordIdx"
                                      style="padding-bottom: 8px;display: flex;justify-content: space-between;"
                                    >
                                      <span>{{ word.value + '→' + word.target }}</span>
                                      <span
                                        @mousedown.stop="openTermBack(word)"
                                        style="display:inline-block;min-width:25px"
                                      >
                                        <a>查看</a>
                                      </span>
                                    </div>
                                  </div>
                                  <div v-if="blacklist.length">
                                    <template v-for="(words, wordsIdx) in blacklist">
                                      <div :key="wordsIdx" v-if="words.classId != 12 && getBlackWords(item, words)">
                                        <div style="color: red; font-weight: bold">{{ words.className }}：</div>
                                        <div>{{ getBlackWords(item, words) }}</div>
                                      </div>
                                      <div :key="wordsIdx" v-else-if="getBlackWordsTwo(item, words).length">
                                        <div style="color: red; font-weight: bold">{{ words.className }}：</div>
                                        <div v-for="(b, bIdx) in getBlackWordsTwo(item, words)" :key="bIdx">
                                          <div>{{ b.title }}</div>
                                          <div>{{ b.remark }}</div>
                                        </div>
                                      </div>
                                    </template>
                                  </div>
                                  <div
                                    v-if="
                                      yahooOrKdxList.length &&
                                        (yahooOrKdxList.some(words => getBlackWordsTwo(item, words).length) ||
                                          yahooOrKdxList.some(
                                            words => words.classId != 12 && getBlackWords(item, words)
                                          ))
                                    "
                                    style="padding-bottom:8px"
                                  >
                                    <div style="color: red; font-weight: bold; padding-bottom: 8px; padding-top: 8px">
                                      第三方提示参考：
                                    </div>
                                    <template v-for="(words, wordsIdx) in yahooOrKdxList">
                                      <div :key="wordsIdx" v-if="words.classId != 12 && getBlackWords(item, words)">
                                        <div>{{ words.className }}：{{ getBlackWords(item, words) }}</div>
                                      </div>
                                      <div
                                        :key="wordsIdx"
                                        v-else-if="getBlackWordsTwo(item, words).length"
                                        style="display: flex; padding-bottom: 8px"
                                      >
                                        <div>{{ words.className }}：</div>
                                        <div v-for="(b, bIdx) in getBlackWordsTwo(item, words)" :key="bIdx">
                                          <div>{{ b.title }}</div>
                                          <div>{{ b.remark }}</div>
                                        </div>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div @click.stop @mousemove.stop class="ocr-show-item-title">
                              <div v-if="item.textAreaRegion.selected">{{ item.sequenceNo }}</div>
                              <div style="background-color:red" v-if="item.isProofreadInjury">校</div>
                              <div style="background-color:red" v-if="item.isFinalCheckInjury">审</div>
                              <div v-if="item.textAreaRegion.selected">
                                <a-icon type="close" @click.stop="deleteTextArea(page, item)" />
                              </div>
                            </div>
                          </div>
                        </vue-draggable-resizable>
                      </template>
                      <!--                       @activated="onActivated(item, 'left', page)"
                      @deactivated="onDeactivated(item, 'left', page)"
                      @resizing="
                        (left, top, width, height) => {
                          onResizing(left, top, width, height, item, page, showImgLeftListPostions[idx])
                        }
                      "
                      @resizestop="onResizstop(item, 'left')"
                      @dragstop="onDragstop(item, 'left')" -->
                      <template
                        v-if="
                          leftAnnotationList.length &&
                            leftImgShow &&
                            leftAnnotationList[leftAnnotationList.findIndex(a => a.pageId == page.id)]
                        "
                      >
                        <!-- 陈立说去掉修改批注大小功能 -->
                        <vue-draggable-resizable
                          class="ocr-show-page-text"
                          :style="{ position: 'absolute', border: 'none' }"
                          :draggable="
                            checkPermission('addAnnotation:edit') && changeAll && isLocalDrag && item.selected
                              ? true
                              : false
                          "
                          :resizable="false"
                          :parent="true"
                          @activated="onActivatedAnnotation(item)"
                          @deactivated="onDeactivatedAnnotation(item)"
                          @resizestop="
                            (left, top, width, height) => {
                              onResizstopAnnotation(
                                left,
                                top,
                                width,
                                height,
                                item,
                                page,
                                showImgLeftListPostions[leftAnnotationList.findIndex(a => a.pageId == page.id)],
                                'left'
                              )
                            }
                          "
                          @dragstop="
                            (left, top) => {
                              onDragstopAnnotation(
                                left,
                                top,
                                item,
                                page,
                                showImgLeftListPostions[leftAnnotationList.findIndex(a => a.pageId == page.id)],
                                'left'
                              )
                            }
                          "
                          v-for="(item, aIdx) in leftAnnotationList[
                            leftAnnotationList.findIndex(a => a.pageId == page.id)
                          ].annotationList"
                          :x="
                            getAnnotationStyle(
                              item,
                              showImgLeftListPostions[leftAnnotationList.findIndex(a => a.pageId == page.id)],
                              page,
                              'left'
                            ).x
                          "
                          :y="
                            getAnnotationStyle(
                              item,
                              showImgLeftListPostions[leftAnnotationList.findIndex(a => a.pageId == page.id)],
                              page,
                              'left'
                            ).y
                          "
                          :w="
                            getAnnotationStyle(
                              item,
                              showImgLeftListPostions[leftAnnotationList.findIndex(a => a.pageId == page.id)],
                              page,
                              'left'
                            ).w
                          "
                          :h="
                            getAnnotationStyle(
                              item,
                              showImgLeftListPostions[leftAnnotationList.findIndex(a => a.pageId == page.id)],
                              page,
                              'left'
                            ).h
                          "
                          :z="item.selected ? 98 : 97"
                          :key="aIdx"
                        >
                          <div
                            :style="getActionStyle(showImgLeftListPostions[idx], page)"
                            :id="item.id ? 'local-' + item.id : 'newLocal-' + aIdx"
                          >
                            <local-two
                              @mouseover="isLocalDrag = true"
                              theme="filled"
                              :size="item.size"
                              :fill="item.selected ? color : 'orange'"
                              :strokeWidth="3"
                            />
                          </div>
                          <div
                            @mouseover="isLocalDrag = false"
                            @mouseleave="isLocalDrag = true"
                            :style="getLocalActionStyle(showImgLeftListPostions[idx], page, 'left', item)"
                            class="local-content"
                            v-if="item.selected"
                          >
                            <div class="local-content-list" v-if="item.commentData.length">
                              <div
                                class="local-content-item"
                                style="padding-left: 5px;"
                                v-for="(i, cidx) in item.commentData"
                                :key="cidx"
                              >
                                <div class="local-content-item-top" style="padding-top:3px;">
                                  <div class="local-content-item-top-left">
                                    <img :src="i.avatar" alt="" />
                                  </div>
                                  <div class="local-content-item-top-right">
                                    <div>
                                      {{ i.userName }}
                                    </div>
                                    <div>
                                      {{ i.createTime }}
                                    </div>
                                  </div>
                                </div>
                                <div class="local-content-item-bottom" style="font-size:12px;" v-html="i.content"></div>
                              </div>
                            </div>
                            <div
                              class="local-content-input"
                              v-if="
                                (checkPermission('addAnnotation:edit') || item.commentData[0].userId == userInfo.id) &&
                                  !item.commentData[0]
                              "
                            >
                              <!--                               <a-textarea
                                v-model="localLeftValue"
                                placeholder="ALT+回车换行，回车发送"
                                @focus="inputFocus"
                                @blur="inputBlur"
                                @keydown.enter="
                                  e => {
                                    localEnter(e, item, 'left')
                                  }
                                "
                                :auto-size="{ minRows: 1, maxRows: 10 }"
                              /> -->
                              <j-editor
                                ref="localLeftInput"
                                @mouseover.stop="isLocalDrag = false"
                                @postValue="localEnter(null, item, 'left')"
                                :placeholder="'ALT+回车换行，回车发送'"
                                v-model="localLeftValue"
                                :maxWidth="'50px'"
                                :toolbar="[]"
                              ></j-editor>
                            </div>
                          </div>
                          <div
                            @mouseover="isLocalDrag = false"
                            @mouseleave="isLocalDrag = true"
                            v-if="
                              (item.selected && checkPermission('addAnnotation:dele')) ||
                                (item.commentData &&
                                  item.commentData[0] &&
                                  item.commentData[0].userId == userInfo.id) ||
                                (item.toStage == '制作环节' &&
                                  (taskDetail.stageName == '终审' ||
                                    taskDetail.stageName == '翻译' ||
                                    taskDetail.stageName == '校对'))
                            "
                            class="ocr-show-item-title-local"
                            :style="getActionCloseStyle(showImgLeftListPostions[idx], page)"
                          >
                            <span></span>
                            <div
                              v-if="
                                (checkPermission('addAnnotation:dele') ||
                                  (item.commentData &&
                                    item.commentData[0] &&
                                    item.commentData[0].userId == userInfo.id) ||
                                  (item.toStage == '制作环节' &&
                                    (taskDetail.stageName == '终审' ||
                                      taskDetail.stageName == '翻译' ||
                                      taskDetail.stageName == '校对'))) &&
                                  item.selected
                              "
                            >
                              <a-icon
                                type="close"
                                @click="
                                  deleteLocal(
                                    leftAnnotationList[leftAnnotationList.findIndex(a => a.pageId == page.id)],
                                    aIdx
                                  )
                                "
                              />
                            </div>
                          </div>
                        </vue-draggable-resizable>
                      </template>
                    </div>
                    <!--                 <div
                  class="ocr-show-item"
                  v-for="(item, idx) in textAreaRegions"
                  :style="getItemStyle(item)"
                  :key="idx"
                ></div> -->
                  </div>
                </template>
                <template v-if="showOptions.leftOptions.length > 1 && showOptions.leftOptions[1].checked">
                  <img
                    v-for="(item, idx) in jpgListData"
                    :ref="item.id + 'showLeftImg'"
                    @dragstart.prevent
                    :key="item.id"
                    :src="item.filePath"
                    class="left-show-img"
                    :style="
                      'width:100%;height:auto;border-bottom:2px dashed ' +
                        (idx == jpgListData.length - 1 ? 'rgba(0,0,0,0)' : 'orange')
                    "
                    alt=""
                  />
                </template>
              </div>
              <div v-else class="no-permission">
                <span>无权限</span>
              </div>
            </div>
            <div
              v-if="rightImgShow"
              class="translate-bottom-left-show-icon"
              :style="`top:calc(50% - ${64 - scrollTop}px)`"
              @click="changeLeftImgShow"
            >
              <a-icon v-if="leftImgShow" type="left" />
              <a-icon v-else type="right" />
            </div>
          </div>
          <a-menu slot="overlay">
            <a-menu-item @click="rightClick('black')" key="1"> 黑色 </a-menu-item>
            <a-menu-item @click="rightClick('#fff')" key="2"> 白色 </a-menu-item>
            <a-menu-item @click="rightClick('rgb(223,220,221)')" key="3"> 灰色 </a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-dropdown :trigger="['contextmenu']">
          <div class="show-img-bottom-right">
            <div :style="{ minHeight: '100%', backgroundColor }">
              <div
                v-if="checkPermission('showImgRight:view')"
                @contextmenu.stop
                :style="{ minHeight: '100%', width: showImgListWidth }"
              >
                <img
                  v-for="(item, idx) in pageListData"
                  :ref="item.id + 'showRightImg'"
                  @dragstart.prevent
                  :key="item.id"
                  :src="item.filePath"
                  :class="
                    imgShow(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0])
                      ? 'right-show-img'
                      : 'right-show-img right-show-img-blur'
                  "
                  :style="
                    'width:100%;height:auto;border-bottom:2px dashed ' +
                      (idx == pageListData.length - 1 ? 'rgba(0,0,0,0)' : 'orange')
                  "
                  alt=""
                />
                <!-- <div style="height:500px;width:100%"></div> -->
                <div
                  class="ocr-show ocr-show-right"
                  ref="dragLineBox"
                  @click="ocrShowClick"
                  @mousedown="dragBoxmouseLinedown"
                  v-if="pageListData.length && showImgLeftListPostions.length"
                >
                  <div class="line_mask" v-if="is_show_line_mask" :style="getLineMaskStyle()">
                    <a-icon
                      :style="getArrowStyle(start_x, end_x, end_y, start_y, mask_line_width, mask_line_height)"
                      type="caret-right"
                    />
                  </div>
                  <div
                    class="ocr-show-page ocr-show-page-right"
                    @mousemove="e => disMovePage(e, page)"
                    @mouseup="e => textMouseup(e, page)"
                    @mousedown.self="clearSelect"
                    v-for="(page, idx) in pageListData"
                    :key="page.id"
                    :id="page.id + '-right-' + idx"
                    :style="{
                      height: showImgLeftListPostions[idx] && showImgLeftListPostions[idx].height + 'px',
                      overflow: rightImgShow ? 'initial' : 'hidden'
                    }"
                  >
                    <div
                      class="delete-border"
                      @mousedown.self="clearSelect"
                      v-if="selectTextAreas.length > 1 && getDeleteBorder.idx === idx"
                      :style="getDeleteBorder"
                    >
                      <div><a-icon type="close" @click.stop="batchDeleteTextArea" /></div>
                    </div>
                    <template v-for="item in page.textAreas">
                      <vue-draggable-resizable
                        class="ocr-show-page-text"
                        v-if="
                          (textShow(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page) ||
                            idx == pageListData.length - 1) &&
                            (item.id + '').indexOf('new') == -1 &&
                            rightImgShow &&
                            !item.imgUrl
                        "
                        :style="
                          `position: absolute; border: none;min-width:${
                            showImgLeftListPostions[idx] ? (showImgLeftListPostions[idx].width / page.width) * 80 : '0'
                          }px;min-height:${
                            showImgLeftListPostions[idx] ? (showImgLeftListPostions[idx].width / page.width) * 60 : '0'
                          }px`
                        "
                        :v-model:active="item.textAreaRegion.selected ? true : false"
                        @activated="onActivated(item, 'right', page)"
                        @deactivated="onDeactivated(item, 'right', page)"
                        :parent="true"
                        @resizestop="onResizstop(item, 'right')"
                        @dragstop="
                          (left, top) => onDragstop(left, top, item, page, showImgLeftListPostions[idx], 'right')
                        "
                        :resizable="false"
                        :draggable="
                          item.translateTextAreaRegion.editble || isDelete
                            ? false
                            : checkPermission('addTextArea:edit') && changeAll && item.textAreaRegion.selected
                            ? true
                            : false
                        "
                        :x="
                          getItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page, 'right')
                            .x
                        "
                        :y="
                          getItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page, 'right')
                            .y
                        "
                        :w="
                          getItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page, 'right')
                            .w
                        "
                        :h="
                          getItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page, 'right')
                            .h
                        "
                        :z="item.textAreaRegion.selected ? 99 : 97"
                        :key="item.id"
                      >
                        <div
                          @click.stop="
                            e => {
                              textAreaClick(e, item)
                            }
                          "
                          @dblclick="
                            e => {
                              textAreaDbclick(e, item, page.id + '-' + item.id + '-' + 'right')
                            }
                          "
                          @mousedown="textAreaMousedown"
                          @mousemove="e => rightMove(e, item)"
                          @mouseleave="e => leftOut(e, item)"
                          @mouseup="e => textMouseup(e, item, page)"
                          :id="page.id + '-' + item.id + '-' + 'right'"
                          :key="page.id + '-' + item.id + '-' + 'right'"
                          :style="`background-color:${item.backgroundColor || '#fff'};-webkit-text-orientation:revert`"
                          :class="
                            item.textAreaRegion.selected
                              ? 'ocr-show-item-selected ocr-show-item-right'
                              : 'ocr-show-item-right'
                          "
                        >
                          <div
                            @keyup="e => textChange(e, item)"
                            @keydown="e => textDownChange(e, item)"
                            @click="textClick(item, 'right', page)"
                            @input="e => textInput(e, item, page)"
                            @mousemove="e => disMove(e, item)"
                            @blur="textBlur(item, 'right', page)"
                            @paste="e => handlePaste(e, item)"
                            @copy="e => handleCopy(e, item)"
                            role="textarea"
                            :contenteditable="item.translateTextAreaRegion.editble ? true : false"
                            :style="{
                              minHeight:
                                item.characterSentences.length == 1 && item.characterSentences[0].characterText == ''
                                  ? item.characterSentences[0].fontSize + 30 + 'px'
                                  : '',
                              minWidth:
                                item.characterSentences.length == 1 && item.characterSentences[0].characterText == ''
                                  ? item.characterSentences[0].fontSize + 30 + 'px'
                                  : '',
                              writingMode: item.textLayout == 2 ? 'vertical-rl' : 'horizontal-tb',
                              textAlign: item.characterSentences[0]
                                ? item.characterSentences[0].textAlign
                                : oldTextBatchStyle.textAlign,
                              lineHeight: item.characterSentences[0]
                                ? item.characterSentences[0].lineSpacing.toFixed(2)
                                : oldTextBatchStyle.lineSpacing.toFixed(2),
                              letterSpacing:
                                item.textLayout == 2
                                  ? oldTextBatchStyle.textHeight > 1
                                    ? (10).toFixed(0) + 'px'
                                    : (1).toFixed(0) + 'px'
                                  : oldTextBatchStyle.textWidth > 1
                                  ? (10).toFixed(0) + 'px'
                                  : (1).toFixed(0) + 'px',
                              fontSize:
                                item.characterSentences.length == 1 &&
                                (item.characterSentences[0].characterText == '' ||
                                  item.characterSentences[0].characterText == ' ')
                                  ? item.characterSentences[0].fontSize + 'px'
                                  : '',
                              color:
                                item.characterSentences.length == 1 &&
                                (item.characterSentences[0].characterText == '' ||
                                  item.characterSentences[0].characterText == ' ')
                                  ? item.characterSentences[0].fontColor
                                  : '',
                              backgroundColor: item.backgroundColor || '#fff',
                              padding: getPadding(item),
                              position: 'relative',
                              left: getInputStyle(item, page, idx, showImgLeftListPostions[idx])
                            }"
                            :ref="page.id + '-' + item.id + '-' + 'editDiv'"
                            :id="page.id + '-' + item.id + '-' + 'editDiv'"
                            :class="
                              item.translateTextAreaRegion.editble || item.textAreaRegion.selected
                                ? 'right-text-input right-text-input-edit'
                                : 'right-text-input '
                            "
                          >
                            <p
                              v-for="p in item.showTexts"
                              :key="page.id + '-' + item.id + '-' + p.pId"
                              :id="page.id + '-' + item.id + '-' + p.pId"
                              style="margin:0;"
                            >
                              <span
                                v-for="(text, textIdx) in p.texts"
                                :key="text.characterSentenceId"
                                :id="page.id + '-' + item.id + '-' + text.characterSentenceId"
                                ><span
                                  :class="
                                    text.hasMark && item.textLayout == 1
                                      ? 'stroke-span stroke-span-hasMark'
                                      : 'stroke-span'
                                  "
                                  :data-text="text.phonetic && text.phonetic.phoneticText"
                                  @mousedown="e => characterClickBefore(e, item)"
                                  @mousemove="e => characterClick(e, item)"
                                  :style="
                                    getTextStyle(
                                      text,
                                      showImgLeftListPostions[idx] || showImgLeftListPostions[0],
                                      page,
                                      item
                                    )
                                  "
                                  :id="text.characterSentenceId"
                                  ><span
                                    class="stroke-span-child"
                                    :contenteditable="item.translateTextAreaRegion.editble ? true : false"
                                    :id="text.characterSentenceId + '-' + 'text'"
                                    :data-text="text.strokeWidth && text.characterText != '' ? text.characterText : ''"
                                    :style="
                                      'white-space: pre-wrap;' +
                                        `transform:scale(${text.textWidth},${text.textHeight});` +
                                        `${
                                          item.textLayout == 2 && text.characterText == ' '
                                            ? `height:${text.fontSize / 3}px;`
                                            : ''
                                        }` +
                                        `${
                                          text.characterText == ' ' &&
                                          !textIdx &&
                                          (!inputStart || !item.translateTextAreaRegion.editble)
                                            ? 'display:inline-block;width:0;height:0;'
                                            : ''
                                        }` +
                                        `-webkit-text-stroke: ${text.strokeWidth * 2}px ${
                                          text.strokeColor
                                        };user-select:none;background-color:${
                                          item.textAreaRegion.selected && text.isBlack
                                            ? item.backgroundColor == '#ffff3a'
                                              ? '#fff'
                                              : 'yellow'
                                            : ''
                                        };color: ${item.textAreaRegion.selected && text.isYahoo ? 'red' : ''};`
                                    "
                                    >{{ text.characterText != '' ? text.characterText : '' }}</span
                                  >
                                </span>
                              </span>
                            </p>
                          </div>
                          <div
                            @mousemove="stopMouseMove"
                            v-if="item.textAreaRegion.selected"
                            style="opacity:0.4"
                            class="ocr-show-item-title"
                          >
                            <div>{{ item.sequenceNo }}</div>
                            <div><a-icon type="close" @click="deleteTextArea(page, item)" /></div>
                          </div>
                        </div>
                      </vue-draggable-resizable>
                      <vue-draggable-resizable
                        class="ocr-show-page-text"
                        v-if="
                          (textShow(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page) ||
                            idx == pageListData.length - 1) &&
                            rightImgShow &&
                            item.imgUrl
                        "
                        :style="
                          `position: absolute; border: none;height:max-content;min-height:10px;`
                        "
                        :v-model:active="item.textAreaRegion.selected ? true : false"
                        @activated="onActivated(item, 'right', page)"
                        @deactivated="onDeactivated(item, 'right', page)"
                        :parent="true"
                        @resizestop="onResizstop(item, 'right')"
                        @dragstop="
                          (left, top) => onDragstop(left, top, item, page, showImgLeftListPostions[idx], 'left')
                        "
                        @resizing="
                          (left, top, width, height) => {
                            onImgResizing(left, top, width, height, item, page, showImgLeftListPostions[idx])
                          }
                        "
                        :resizable="checkPermission('addTextArea:edit') && taskStatus == '可开始' ? true : false"
                        :draggable="
                          isDelete
                            ? false
                            : checkPermission('addTextArea:edit') && changeAll && item.textAreaRegion.selected
                            ? true
                            : false
                        "
                        :x="
                          getItemImgStyle(
                            item,
                            showImgLeftListPostions[idx] || showImgLeftListPostions[0],
                            page,
                            'right'
                          ).x
                        "
                        :y="
                          getItemImgStyle(
                            item,
                            showImgLeftListPostions[idx] || showImgLeftListPostions[0],
                            page,
                            'right'
                          ).y
                        "
                        :w="
                          getItemImgStyle(
                            item,
                            showImgLeftListPostions[idx] || showImgLeftListPostions[0],
                            page,
                            'right'
                          ).w
                        "
                        :h="
                          getItemImgStyle(
                            item,
                            showImgLeftListPostions[idx] || showImgLeftListPostions[0],
                            page,
                            'right'
                          ).h
                        "
                        :z="item.textAreaRegion.selected ? 99 : 97"
                        :key="item.id"
                      >
                        <div
                          @click.stop="
                            e => {
                              textAreaClick(e, item)
                            }
                          "
                          @mousedown="textAreaMousedown"
                          @mousemove="e => rightMove(e, item)"
                          @mouseleave="e => leftOut(e, item)"
                          @mouseup="e => textMouseup(e, item, page)"
                          :id="page.id + '-' + item.id + '-' + 'right'"
                          :key="page.id + '-' + item.id + '-' + 'right'"
                          :style="
                            `width:100%;height:100%;background-color:${item.backgroundColor ||
                              '#fff'};-webkit-text-orientation:revert`
                          "
                          :class="
                            item.textAreaRegion.selected
                              ? 'ocr-show-item-selected ocr-show-item-right'
                              : 'ocr-show-item-right'
                          "
                        >
                          <div
                            @keyup="e => textChange(e, item)"
                            @keydown="e => textDownChange(e, item)"
                            @click="textClick(item, 'right', page)"
                            @input="e => textInput(e, item, page)"
                            @mousemove="e => disMove(e, item)"
                            @blur="textBlur(item, 'right', page)"
                            @paste="e => handlePaste(e, item)"
                            @copy="e => handleCopy(e, item)"
                            :style="{
                              width: '100%',
                              height: '100%',
                              backgroundColor: item.backgroundColor || 'rgba(0,0,0,0)',
                              position: 'relative',
                              left: '0'
                            }"
                            :ref="page.id + '-' + item.id + '-' + 'editDiv'"
                            :id="page.id + '-' + item.id + '-' + 'editDiv'"
                          >
                            <img :src="item.imgUrl" style="width:100%;height:auto" />
                          </div>
                          <div
                            @mousemove="stopMouseMove"
                            v-if="item.textAreaRegion.selected"
                            style="opacity:0.4"
                            class="ocr-show-item-title"
                          >
                            <div>{{ item.sequenceNo }}</div>
                            <div><a-icon type="close" @click="deleteTextArea(page, item)" /></div>
                          </div>
                        </div>
                      </vue-draggable-resizable>
                    </template>

                    <template
                      v-if="
                        rightAnnotationList.length &&
                          rightImgShow &&
                          rightAnnotationList[rightAnnotationList.findIndex(a => a.pageId == page.id)]
                      "
                    >
                      <vue-draggable-resizable
                        class="ocr-show-page-text"
                        :style="{ position: 'absolute', border: 'none' }"
                        :draggable="
                          checkPermission('addAnnotation:edit') && changeAll && isLocalDrag && item.selected
                            ? true
                            : false
                        "
                        :resizable="false"
                        :parent="true"
                        @click.stop
                        @activated="onActivatedAnnotation(item)"
                        @deactivated="onDeactivatedAnnotation(item)"
                        @resizestop="
                          (left, top, width, height) => {
                            onResizstopAnnotation(
                              left,
                              top,
                              width,
                              height,
                              item,
                              page,
                              showImgLeftListPostions[rightAnnotationList.findIndex(a => a.pageId == page.id)],
                              'right'
                            )
                          }
                        "
                        @dragging="
                          (left, top) => {
                            onDraggingAnnotation(
                              left,
                              top,
                              item,
                              page,
                              showImgLeftListPostions[rightAnnotationList.findIndex(a => a.pageId == page.id)]
                            )
                          }
                        "
                        @dragstop="
                          (left, top) => {
                            onDragstopAnnotation(
                              left,
                              top,
                              item,
                              page,
                              showImgLeftListPostions[rightAnnotationList.findIndex(a => a.pageId == page.id)],
                              'right'
                            )
                          }
                        "
                        v-for="(item, aIdx) in rightAnnotationList[
                          rightAnnotationList.findIndex(a => a.pageId == page.id)
                        ].annotationList"
                        :x="
                          getAnnotationStyle(
                            item,
                            showImgLeftListPostions[rightAnnotationList.findIndex(a => a.pageId == page.id)],
                            page,
                            'right'
                          ).x
                        "
                        :y="
                          getAnnotationStyle(
                            item,
                            showImgLeftListPostions[rightAnnotationList.findIndex(a => a.pageId == page.id)],
                            page,
                            'right'
                          ).y
                        "
                        :w="
                          getAnnotationStyle(
                            item,
                            showImgLeftListPostions[rightAnnotationList.findIndex(a => a.pageId == page.id)],
                            page,
                            'right'
                          ).w
                        "
                        :h="
                          getAnnotationStyle(
                            item,
                            showImgLeftListPostions[rightAnnotationList.findIndex(a => a.pageId == page.id)],
                            page,
                            'right'
                          ).h
                        "
                        :z="item.selected ? 98 : 97"
                        :key="aIdx + 'imgLocal'"
                      >
                        <div
                          @click.stop
                          :style="getActionStyle(showImgLeftListPostions[idx], page)"
                          :id="item.id ? 'local-' + item.id : 'newLocal-' + aIdx"
                        >
                          <local-two
                            @mouseover="isLocalDrag = true"
                            theme="filled"
                            :size="item.size"
                            :fill="item.selected ? color : 'orange'"
                            :strokeWidth="3"
                          />
                        </div>
                        <div
                          @click.stop
                          :style="getLocalActionStyle(showImgLeftListPostions[idx], page, 'right', item)"
                          class="local-content"
                          @mouseover.stop="isLocalDrag = false"
                          @mouseleave.stop="isLocalDrag = true"
                          v-if="item.selected"
                        >
                          <div class="local-content-to-stage">
                            <div style="font-size:16px;height:100%">
                              To:
                            </div>
                            <div style="margin-left:10px;">
                              <a-radio-group v-model="item.toStage" @change="toStageChange(item)">
                                <a-radio value="翻译环节">
                                  翻译环节
                                </a-radio>
                                <a-radio value="制作环节">
                                  制作环节
                                </a-radio>
                              </a-radio-group>
                            </div>
                          </div>
                          <div class="local-content-list" v-if="item.commentData.length">
                            <div class="local-content-item" v-for="(i, cidx) in item.commentData" :key="cidx">
                              <div class="local-content-item-top">
                                <div class="local-content-item-top-left">
                                  <img :src="i.avatar" alt="" />
                                </div>
                                <div class="local-content-item-top-right">
                                  <div>
                                    {{ i.userName }}
                                  </div>
                                  <div>
                                    {{ i.createTime }}
                                  </div>
                                </div>
                              </div>
                              <div class="local-content-item-bottom" v-html="i.content"></div>
                            </div>
                          </div>
                          <div
                            class="local-content-input"
                            v-if="
                              (checkPermission('addAnnotation:edit') ||
                                (item.commentData &&
                                  item.commentData[0] &&
                                  item.commentData[0].userId == userInfo.id)) &&
                                !item.commentData[0]
                            "
                          >
                            <j-editor
                              ref="localRightInput"
                              @mouseover.stop="isLocalDrag = false"
                              @postValue="localEnter(null, item, 'right')"
                              :placeholder="'ALT+回车换行，回车发送'"
                              v-model="localRightValue"
                              :maxWidth="'50px'"
                              :toolbar="[]"
                            ></j-editor>
                          </div>
                          <div class="local-content-input" v-if="item.commentData[0]">
                            <j-editor
                              ref="localRightInput"
                              @mouseover.stop="isLocalDrag = false"
                              @postValue="pushLoacal(item)"
                              :placeholder="'ALT+回车换行，回车发送'"
                              v-model="postValue"
                              :maxWidth="'50px'"
                              :toolbar="[]"
                            ></j-editor>
                          </div>
                        </div>
                        <div
                          v-if="
                            (item.selected && checkPermission('addAnnotation:dele')) ||
                              (item.commentData && item.commentData[0] && item.commentData[0].userId == userInfo.id) ||
                              (item.toStage == '制作环节' &&
                                (taskDetail.stageName == '终审' ||
                                  taskDetail.stageName == '翻译' ||
                                  taskDetail.stageName == '校对'))
                          "
                          @click.stop
                          @mouseover.stop="isLocalDrag = false"
                          @mouseleave.stop="isLocalDrag = true"
                          class="ocr-show-item-title-local"
                          :style="getActionCloseStyle(showImgLeftListPostions[idx], page)"
                        >
                          <span></span>
                          <div
                            v-if="
                              (checkPermission('addAnnotation:dele') ||
                                (item.commentData &&
                                  item.commentData[0] &&
                                  item.commentData[0].userId == userInfo.id) ||
                                (item.toStage == '制作环节' &&
                                  (taskDetail.stageName == '终审' ||
                                    taskDetail.stageName == '翻译' ||
                                    taskDetail.stageName == '校对'))) &&
                                item.selected
                            "
                          >
                            <a-icon
                              type="close"
                              @click="
                                deleteLocal(
                                  rightAnnotationList[rightAnnotationList.findIndex(a => a.pageId == page.id)],
                                  aIdx
                                )
                              "
                            />
                          </div>
                        </div>
                      </vue-draggable-resizable>
                    </template>
                    <template v-for="item in page.lines">
                      <SvgLine
                        v-if="
                          (lineShow(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page) ||
                            idx == pageListData.length - 1) &&
                            (item.id + '').indexOf('new') == -1 &&
                            rightImgShow
                        "
                        :key="item.id"
                        :ref="'page-line-' + item.id"
                        :startX="item.startPosition.x"
                        :startY="item.startPosition.y"
                        :endX="item.endPosition.x"
                        :endY="item.endPosition.y"
                        :width="
                          getLineItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page).w
                        "
                        :height="
                          getLineItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page).h
                        "
                        @lineMouseDwon="e => lineMouseDwon(e, item, page)"
                        @lineStartMousedown="e => lineStartMousedown(e, item, page)"
                        @lineEndMousedown="e => lineEndMousedown(e, item, page)"
                        @activated="onLineActivated(item, page)"
                        @deactivated="onLineDeactivated(item, page)"
                        :x="getLineItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page).x"
                        :y="getLineItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page).y"
                      />
                      <!--                       <vue-draggable-resizable
                        :class="
                          getPageLineClass(
                            item.startPosition.x,
                            item.endPosition.x,
                            item.startPosition.y,
                            item.endPosition.y
                          )
                        "
                        v-if="
                          (lineShow(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page) ||
                            idx == pageListData.length - 1) &&
                            (item.id + '').indexOf('new') == -1 &&
                            rightImgShow
                        "
                        :style="`position: absolute;`"
                        :v-model:active="item.selected ? true : false"
                        @activated="onLineActivated(item, page)"
                        @deactivated="onLineDeactivated(item, page)"
                        :parent="true"
                        @resizing="
                          (left, top, width, height) => {
                            onLineResizing(left, top, width, height, item, page, showImgLeftListPostions[idx])
                          }
                        "
                        @resizestop="onLineResizstop(item, page)"
                        @dragstop="(left, top) => onDragLinestop(left, top, item, page, showImgLeftListPostions[idx])"
                        :draggable="
                          isDelete
                            ? false
                            : checkPermission('addTextArea:edit') && changeAll && item.selected
                            ? true
                            : false
                        "
                        :x="getLineItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page).x"
                        :y="getLineItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page).y"
                        :w="getLineItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page).w"
                        :h="getLineItemStyle(item, showImgLeftListPostions[idx] || showImgLeftListPostions[0], page).h"
                        :z="item.selected ? 99 : 97"
                        :key="item.id"
                      >
                        <div
                          :style="
                            getLineViewStyle(
                              item.startPosition.x,
                              item.endPosition.x,
                              item.startPosition.y,
                              item.endPosition.y
                            )
                          "
                        >
                          <a-icon
                            :style="
                              getArrowStyle(
                                item.startPosition.x,
                                item.endPosition.x,
                                item.endPosition.y,
                                item.startPosition.y,
                                item.width,
                                item.height
                              )
                            "
                            type="caret-right"
                          />
                        </div>
                      </vue-draggable-resizable> -->
                    </template>
                  </div>
                  <!--                 <div
                  class="ocr-show-item"
                  v-for="(item, idx) in textAreaRegions"
                  :style="getItemStyle(item)"
                  :key="idx"
                ></div> -->
                </div>
              </div>
              <div v-else class="no-permission">
                <span>无权限</span>
              </div>
            </div>
            <div
              v-if="leftImgShow"
              class="translate-bottom-right-show-icon"
              :style="`top:calc(50% - ${64 - scrollTop}px)`"
              @click="changeRightImgShow"
            >
              <a-icon v-if="rightImgShow" type="right" />
              <a-icon v-else type="left" />
            </div>
          </div>
          <a-menu slot="overlay">
            <a-menu-item @click="rightClick('black')" key="1"> 黑色 </a-menu-item>
            <a-menu-item @click="rightClick('#fff')" key="2"> 白色 </a-menu-item>
            <a-menu-item @click="rightClick('rgb(223,220,221)')" key="3"> 灰色 </a-menu-item>
          </a-menu>
        </a-dropdown>
        <div
          v-if="!isAddLine && !isAddText && !isCheckText && !isAddAnnotation"
          class="copy-img-div"
          @mouseover="focusDiv"
          @mouseout="blurDiv"
          @keydown.stop="imgRightKeyDown"
          @paste="imgRightPaste"
          :contenteditable="true"
          :style="{ top: scrollTop + 'px' }"
        ></div>
      </div>
    </div>
    <errorModal
      ref="errorModal"
      @clearSelectErrorType="clearSelectErrorType"
      @changeErrorValue="changeErrorValue"
      @errorChange="errorChange"
      :dictList="dictList"
      @topCenterMouseover="topCenterMouseover"
      @changeIsDele="changeIsDele"
      @postRejectValue="postRejectValue"
      :selectErrorType="selectErrorType"
    />
  </div>
</template>

<script>
import { LocalTwo } from '@icon-park/vue'
import { checkPermission } from './../utils/hasPermission'
import { simpleDebounce } from '@/utils/util'
import errorModal from './errorModal'
import JEditor from './JEditor'
import SvgLine from './SvgLine.vue'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import { getAction, postAction, v1postAction, v1DeleteAction, deleteAction, putAction } from '../../../api/manage'
export default {
  mixins: [UploadFileToOssMixins],
  props: {
    taskStatus: {
      type: String,
      default: '等待'
    },
    authList: {
      type: Array,
      default: () => []
    },
    departName: {
      type: String,
      default: ''
    },
    pageListData: {
      type: Array,
      default: () => []
    },
    phoneticBlackList: {
      type: Array,
      default: () => []
    },
    jpgListData: {
      type: Array,
      default: () => []
    },
    showImgLeftListPostions: {
      type: Array,
      default: () => []
    },
    leftAnnotationList: {
      type: Array,
      default: () => []
    },
    rightAnnotationList: {
      type: Array,
      default: () => []
    },
    textAreaRegions: {
      type: Array,
      default: () => []
    },
    selectTextAreas: {
      type: Array,
      default: () => []
    },
    selectTextAreaData: {
      type: Array,
      default: () => []
    },
    select_list: {
      type: Array,
      default: () => []
    },
    errorDictList: {
      type: Array,
      default: () => []
    },
    blacklist: {
      type: Array,
      default: () => []
    },
    filtrationList: {
      type: Array,
      default: () => []
    },
    terms: {
      type: Array,
      default: () => []
    },
    termsAll: {
      type: Array,
      default: () => []
    },
    glossaryTags: {
      type: Array,
      default: () => []
    },
    termBacks: {
      type: Array,
      default: () => []
    },
    termNotes: {
      type: Array,
      default: () => []
    },
    allTermBacks: {
      type: Array,
      default: () => []
    },
    textTypes: {
      type: Array,
      default: () => []
    },
    showOptions: {
      type: Object,
      default: () => ({})
    },
    taskDetail: {
      type: Object,
      default: () => ({})
    },
    oldTextBatchStyle: {
      type: Object,
      default: () => ({})
    },
    textBatchStyle: {
      type: Object,
      default: () => ({})
    },
    selectIdx: {
      type: Number,
      default: 0
    },
    blackListMode: {
      type: Number,
      default: 1
    },
    changeScrollHeihgt: {
      type: Number,
      default: 0
    },
    showImgListWidth: {
      type: String,
      default: '100%'
    },
    color: {
      type: String,
      default: '#4a90e2'
    },
    defualtTextTypeNo: {
      type: String,
      default: '-1'
    },
    is_show_mask: {
      type: Boolean,
      default: false
    },
    isCenterClick: {
      type: Boolean,
      default: false
    },
    isOpenPhoneticModal: {
      type: Boolean,
      default: false
    },
    isRejectStatus: {
      type: Boolean,
      default: false
    },
    isAddText: {
      type: Boolean,
      default: false
    },
    isAddLine: {
      type: Boolean,
      default: false
    },
    changeAll: {
      type: Boolean,
      default: false
    },
    isWindows: {
      type: Boolean,
      default: true
    },
    ocrLoading: {
      type: Boolean,
      default: true
    },
    isDownLoad: {
      type: Boolean,
      default: false
    },
    isSaved: {
      type: Boolean,
      default: false
    },
    ischangeScroll: {
      type: Boolean,
      default: false
    },
    isAddAnnotation: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LocalTwo,
    errorModal,
    SvgLine,
    JEditor
  },
  data() {
    return {
      userInfo:{},
      reSzieType: '',
      reSizeLine: null,
      reSizeLinePage: null,
      reSizeLineOffsetX: 0,
      reSizeLineOffsetY: 0,
      moveLine: null,
      moveLinePage: null,
      addLinePageIndex: 0,
      moveLineOffsetX: 0,
      moveLineOffsetY: 0,
      rightShow: true,
      leftShow: true,
      copyDown: false,
      isCenter: false,
      dragTextArea: false,
      backgroundColor: 'rgb(223,220,221)',
      phoneticStyle: {
        fontColor: '#000000',
        fontSize: 17,
        lineHeight: 1.15,
        lineSpacing: 1.15,
        scaleX: 1,
        scaleY: 1,
        textAlign: 'left'
      },
      inputDirection: 0,
      localLeftValue: '',
      isLocalDrag: true,
      selectionString: '',
      is_show_text_mask: false,
      is_show_line_mask: false,
      localRightValue: '',
      start_x: 0,
      start_y: 0,
      end_x: 0,
      end_y: 0,
      box_screen_left: 0,
      box_screen_top: 153,
      box_screen_line_left: 0,
      scrollTop: 0,
      leftWidth: 0,
      selectErrorType: 0,
      errorValue: undefined,
      errorValueBottom: undefined,
      isRejected: false,
      isDelete: false,
      leftShow: true,
      leftImgShow: true,
      rightImgShow: true,
      dictList: [],
      yahooOrKdxList: [],
      kdxfMap: new Map([
        ['black_list', '黑名单纠错'],
        ['pol', '政治术语纠错'],
        ['char', '别字纠错'],
        ['word', '别词纠错'],
        ['redund', '语法纠错-冗余'],
        ['miss', '语法纠错-缺失'],
        ['order', '语法纠错-乱序'],
        ['lx_word', '词级别乱序'],
        ['lx_char', '字级别乱序'],
        ['dapei', '搭配'],
        ['punc', '标点纠错'],
        ['idm', '成语纠错'],
        ['org', '机构名纠错'],
        ['org_R', '机构名字词冗余'],
        ['org_M', '机构名字词缺失'],
        ['org_S', '机构名字词错误'],
        ['org_N', '机构名称变更'],
        ['org_P', '机构名字词乱序'],
        ['leader', '领导人职称纠错'],
        ['number', '数字纠错'],
        ['time', '时间纠错'],
        ['date-m', '日期纠错（月份）'],
        ['date-d', '日期纠错（日）'],
        ['addr', '地名纠错'],
        ['addr_R', '地名字词冗余'],
        ['addr_M', '地名字词缺失'],
        ['addr_S', '地名字词错误'],
        ['name', '全文人名纠错'],
        ['grammar_pc', '句式杂糅&语义重复']
      ]),
      inputStart: false,
      isEnter: false,
      isSelection: false,
      isChangeFontSize: false,
      selectionTexts: [],
      inputText: '',
      copyValue: '',
      inputValue: '',
      changeX: 0,
      startIdx: -1,
      endIdx: -1,
      oldScrollTop: 0,
      selectErrorTypeBottom: 0,
      startDom: null,
      endDom: null,
      selectTextArea: null,
      selectRevisitionId: null,
      dragging: false,
      isCopy: false,
      isCheckText: false,
      termsPhonetics: [],
      topFeedbacks: [],
      topRejectComments: [],
      topInjuryType: 0,
      topInjuryRemark: '',
      bottomFeedbacks: [],
      bottomRejectComments: [],
      bottomInjuryType: 0,
      bottomInjuryRemark: '',
      selectErrorTypes: [],
      selectBottomErrorTypes: [],
      isOpenModal: false,
      copyContent: '',
      inputBackspace: false,
      addText: '',
      topShow: true,
      bottomShow: true,
      checkPhoneticList: [],
      termsPhoneticsAll: [],
      isLocalNoDrag: true,
      postValue: ''
    }
  },
  watch: {
    leftAnnotationList: {
      deep: true,
      handler(newVal) {
        this.leftShow = false
        this.$nextTick(() => {
          this.leftShow = true
        })
      }
    },
    localRightValue(newval) {
      this.localRightValue = newval
      if (this.$refs.localRightInput && this.$refs.localRightInput[0]) {
        this.$refs.localRightInput[0].EditorOnsize()
      }
    },
    localLeftValue(newval) {
      this.localLeftValue = newval
      if (this.$refs.localLeftInput && this.$refs.localLeftInput[0]) {
        this.$refs.localLeftInput[0].EditorOnsize()
      }
    }
  },
  computed: {
    getTextStyle() {
      return (text, showPostion, page, item) => {
        let pNums = []
        let brIndexs = [0, ...item.brIndexs]
        let pIndexs = [...item.pIndexs]
        let textIdx = -1

        if (this.departName.indexOf('日语部') > -1) {
          textIdx = item.characterSentences.findIndex(t => t.characterSentenceId == text.characterSentenceId)
          /*           item.characterSentences.forEach((t, index) => {
            if (t.characterText == '<br>') brIndexs.push(index)
            if (
              item.characterSentences[index + 1] &&
              item.characterSentences[index + 1].phonetic &&
              item.characterSentences[index + 1].phonetic.phoneticText
            ) {
              pIndexs.push(index)
            }
          }) */
          if (
            item.characterSentences[0] &&
            item.characterSentences[0].phonetic &&
            item.characterSentences[0].phonetic.phoneticText
          ) {
            pIndexs.unshift(0)
          }
          pIndexs.forEach(pIndex => {
            brIndexs.forEach((brIndex, idx) => {
              if (
                brIndexs[idx + 1] &&
                pIndex >= brIndex &&
                pIndex < brIndexs[idx + 1] &&
                pNums.every(p => p.starIdx != brIndex && p.endIdx != brIndexs[idx + 1])
              ) {
                pNums.push({ starIdx: brIndex, endIdx: brIndexs[idx + 1] })
              }
              if (
                !brIndexs[idx + 1] &&
                pIndex >= brIndex &&
                pNums.every(p => p.starIdx != brIndex && p.endIdx != textIdx)
              ) {
                pNums.push({ starIdx: brIndex, endIdx: textIdx })
              }
            })
          })
        }
        let pNum = 0
        pNums.forEach(p => {
          if (textIdx >= p.starIdx) pNum++
        })
        let num = showPostion.width / page.width - 0
        let lineSpacing = text.lineSpacing || 1.15
        let pTotal = pNum > 1 ? 5 : 0
        let textMove =
          ((pNum * ((text.fontSize - 1) / 2 + 1) * lineSpacing).toFixed(0) - pTotal) * num +
          (num > 1.7 ? 10 : 0) +
          'px;'
        if (text.phonetic) {
          textMove =
            ((pNum * text.phonetic.fontSize * lineSpacing).toFixed(0) - pTotal) * num + (num > 1.7 ? 10 : -2) + 'px;'
        } else {
          if (
            item.characterSentences.findIndex(
              t => t.phonetic && t.phonetic.targetCharacterIds?.some(targetId => targetId == text.characterSentenceId)
            ) > -1
          ) {
            let fs = item.characterSentences.find(
              t => t.phonetic && t.phonetic.targetCharacterIds?.some(targetId => targetId == text.characterSentenceId)
            ).phonetic.fontSize
            textMove = ((pNum * fs * lineSpacing).toFixed(0) - pTotal) * num + (num > 1.7 ? 10 : -2) + 'px;'
          }
        }
        if (item.textLayout == 2) {
          textMove = 'left:-' + textMove
        } else {
          textMove = 'top:' + textMove
        }
        textMove = textMove.replaceAll('--', '-')
        let style =
          `font-size:${(text.fontSize * num).toFixed(0) - 0}px;` +
          `color:${text.fontColor};` +
          `font-family:${text.fontFamily ? 'fontCssView' + text.fontFamily : ''};` +
          textMove +
          `font-weight:${text.isBold ? 'bold' : ''};` +
          `font-style:${text.isIncline ? 'italic' : ''};` +
          `transform:scale(${(text.strokeWidth * 2 * num).toFixed(0) - 0}px,${text.textHeight}px);`

        if (text.phonetic && text.phonetic.phoneticText) {
          let top = 0
          let left = 0
          if (item.textLayout == 2) {
            let changeLeft = (text.fontSize - text.phonetic.fontSize) * num
            if (!changeLeft) changeLeft = 6
            /*             left =
              (
                (pNum ? 1 : 0) * text.fontSize * num * text.lineSpacing -
                ((text.fontSize * num * text.lineSpacing - text.phonetic.fontSize * num) / 2) * num -
                changeLeft
              ).toFixed(0) -
              0 +
              (num > 1.7 ? 10 : 0)+'px;' */
            left = `calc(50% - ${(text.fontSize * num).toFixed(0)}px);`
            //微调竖向文本框注音间距
          } else {
            let changeTop = ((text.fontSize * num - text.phonetic.fontSize * num) / 3).toFixed(0) - 0
            top =
              '-' +
              (
                (pNum ? 1 : 0) * text.fontSize * num * text.lineSpacing -
                (text.fontSize * num - text.phonetic.fontSize * num) / 2
              ).toFixed(0) -
              0 +
              changeTop +
              'px;'
          }
          let lineHeightW = 'calc(100% - 2px);'
          let lineHeightH = 'calc(100% - 2px);'
          if (item.textLayout == 2) {
            lineHeightH = `${text.phonetic.phoneticText.length * text.phonetic.fontSize * num}px;`
            if (text.phonetic.targetCharacterIds) {
              top =
                (
                  (text.fontSize * num * text.phonetic.targetCharacterIds.length -
                    text.phonetic.phoneticText.length * text.phonetic.fontSize * num) /
                  2
                ).toFixed(0) + 'px;'
            }
          } else {
            lineHeightW = `${text.phonetic.phoneticText.length * text.phonetic.fontSize * num}px;`
            /*           if (text.phonetic.lineSpacing && text.phonetic.lineSpacing * 100 - 80 > 0) {
            top = `-${(text.phonetic.lineSpacing * 100 - 80).toFixed(0)}%;`
          } else {
            top = `${(80 - text.phonetic.lineSpacing * 100).toFixed(0)}%;`
          } */
            if (text.phonetic.targetCharacterIds) {
              left =
                (
                  (text.fontSize * num * text.phonetic.targetCharacterIds.length -
                    text.phonetic.phoneticText.length * text.phonetic.fontSize * num) /
                  2
                ).toFixed(0) -
                (2 / num).toFixed(0) +
                'px;'
            }
          }
          let pStyle =
            `--pFontSize: ${(text.phonetic.fontSize * num).toFixed(0) - 0}px;` +
            `--pLineHeightW:${lineHeightW}` +
            `--pLineHeightH:${lineHeightH}` +
            `--transform:scale(${text.phonetic.scaleX || 1},${text.phonetic.scaleY || 1});` +
            `--letterSpacing:-${text.phonetic.letterSpacing}px;` +
            `--bold:${text.isBold ? 'bold;' : ''};` +
            `--isIncline:${text.isIncline ? 'italic;' : ''};` +
            `--stroke:${(text.strokeWidth * 2 * num).toFixed(0) - 0}px ${text.strokeColor};` +
            `--pBack:${text.strokeColor};` +
            `--pTop:${top}` +
            `--pLeft:${left}`
          style = style + pStyle
        }
        if (text.hasMark) {
          var reg = /^[a-zA-Z0-9]+$/
          let left = (text.fontSize - (text.fontSize / 8) * num) / 2
          if (reg.test(text.characterText)) {
            if (
              text.characterText == 'f' ||
              text.characterText == 'i' ||
              text.characterText == 'j' ||
              text.characterText == 'l' ||
              text.characterText == 't'
            ) {
              left = left / 2
            }
            if (text.characterText == '1') {
              left = left * 1.5
            }
            left = left / 2
          }
          left = (left * num).toFixed(0) + 'px;'
          let bottom = ((text.fontSize / 20) * num).toFixed(0) + 'px;'
          let mStyle =
            `--mFontSize: ${(((text.fontSize - 1) / 8) * num).toFixed(0) - 0}px;` +
            `--mBack:${text.fontColor};` +
            `--mLeft:${left}` +
            `--mBorderRadius:${text.fontFamily == 'FZHei-B01S' ? 'none' : '50%'};` +
            `--mBottom:-${bottom}`
          style = style + mStyle
        }
        return style
      }
    },
    getDeleteBorder() {
      let style = {
        idx: null
      }
      if (this.showImgLeftListPostions.length && this.pageListData.length) {
        let arr1 = JSON.parse(JSON.stringify(this.selectTextAreas))
        let arr2 = JSON.parse(JSON.stringify(this.selectTextAreas))
        let arrMax1 = JSON.parse(JSON.stringify(arr1)).map(s => {
          return {
            ...s,
            x: s.x - 0 + s.width
          }
        })
        let arrMax2 = JSON.parse(JSON.stringify(arr2)).map(s => {
          return {
            ...s,
            y: s.y - 0 + s.height
          }
        })
        let arrMaxX = arrMax1.sort((a, b) => a.x - b.x)
        let arrMaxY = arrMax2.sort((a, b) => a.y - b.y)
        let arrX = arr1.sort((a, b) => a.x - b.x)
        let arrY = arr2.sort((a, b) => a.y - b.y)
        style = {
          top: arrY[0].y + 'px',
          left: arrX[0].x + 'px',
          width: arrMaxX[arrMax1.length - 1].x - 0 - arrX[0].x + 'px',
          height: arrMaxY[arrMax2.length - 1].y - 0 - arrY[0].y + 'px',
          idx: this.pageListData.findIndex(item => item.id == arrY[0].pageId)
        }
      }
      return style
    },
    mask_width() {
      return `${Math.abs(this.end_x - this.start_x)}`
    },
    mask_top() {
      return `${Math.min(this.start_y, this.end_y) - this.box_screen_top + this.scrollTop}`
    },
    mask_left() {
      return `${
        Math.min(this.start_x, this.end_x) - this.box_screen_left > 0
          ? Math.min(this.start_x, this.end_x) - this.box_screen_left
          : 0
      }`
    },
    mask_height() {
      return `${Math.abs(this.end_y - this.start_y)}`
    },
    mask_line_width() {
      return `${Math.abs(this.end_x - this.start_x)}`
    },
    mask_line_top() {
      return `${Math.min(this.start_y, this.end_y) - this.box_screen_top + this.scrollTop}`
    },
    mask_line_left() {
      return `${
        Math.min(this.start_x, this.end_x) - this.box_screen_line_left > 0
          ? Math.min(this.start_x, this.end_x) - this.box_screen_line_left
          : 0
      }`
    },
    mask_line_height() {
      return Math.abs(this.end_y - this.start_y)
    },
    textShow() {
      return (item, showPostion, page) => {
        let num = showPostion.width / page.width - 0
        const dom = document.getElementsByClassName('show-img-bottom')[0]
        let top = (item.translateTextAreaRegion.y * num).toFixed(0) - 0 + (showPostion.top - 0)
        let show = false
        if (top > dom.scrollTop - 2500 && top < dom.scrollTop + dom.clientHeight + 2500) {
          show = true
        }
        if (this.isDownLoad) show = true
        return show
      }
    },
    lineShow() {
      return (item, showPostion, page) => {
        let num = showPostion.width / page.width - 0
        const dom = document.getElementsByClassName('show-img-bottom')[0]
        let top = (item.y * num).toFixed(0) - 0 + (showPostion.top - 0)
        let show = false
        if (top > dom.scrollTop - 2500 && top < dom.scrollTop + dom.clientHeight + 2500) {
          show = true
        }
        if (this.isDownLoad) show = false
        return show
      }
    },
    imgShow() {
      return (page, showPostion) => {
        let show = false
        if (!showPostion) return show
        const dom = document.getElementsByClassName('show-img-bottom')[0]
        let top = showPostion.top - this.showImgLeftListPostions[0].top
        if (top + (showPostion.height - 0) > dom.scrollTop && dom.scrollTop >= top) {
          show = true
        }
        if (this.isDownLoad) show = true
        return show
      }
    }
  },
  mounted() {},
  methods: {
    lineEndMousedown(event, item, p) {
      this.reSzieType = 'end'
      this.reSizeLine = item
      this.reSizeLinePage = p
      this.reSizeLineOffsetX = event.offsetX
      this.reSizeLineOffsetY = event.offsetX
      this.addLinePageIndex = 0
      this.pageListData.forEach((page, index) => {
        if (page.id == p.id) {
          this.addLinePageIndex = index
        }
      })
      document.body.addEventListener('mousemove', this.handleReSzieLineMouseMove)
      document.body.addEventListener('mouseup', this.handleReSzieLineMouseUp)
    },
    lineStartMousedown(event, item, p) {
      this.reSzieType = 'start'
      this.reSizeLine = item
      this.reSizeLinePage = p
      this.reSizeLineOffsetX = event.offsetX
      this.reSizeLineOffsetY = event.offsetX
      this.addLinePageIndex = 0
      this.pageListData.forEach((page, index) => {
        if (page.id == p.id) {
          this.addLinePageIndex = index
        }
      })
      document.body.addEventListener('mousemove', this.handleReSzieLineMouseMove)
      document.body.addEventListener('mouseup', this.handleReSzieLineMouseUp)
    },
    handleReSzieLineMouseMove(event) {
      console.log(123)
      if (!this.reSizeLine || !this.reSzieType) {
        return
      }
      let num = this.showImgLeftListPostions[0].width / this.pageListData[0].width
      if (!num) {
        num = 1
      }
      /*       let event = {
        clientX:(e.clientX*num).toFixed(0)-0,
        clientY:(e.clientY*num).toFixed(0)-0
      } */
      if (this.reSzieType == 'start') {
        let oldStartX = this.reSizeLine.startPosition.x
        let oldStartY = this.reSizeLine.startPosition.y
        let changWidth = 0 - (event.movementX / num).toFixed(0)
        let changHeight = 0 - (event.movementY / num).toFixed(0)
        this.reSizeLine.startPosition.x = this.reSizeLine.startPosition.x + ((event.movementX / num).toFixed(0) - 0)
        this.reSizeLine.startPosition.y = this.reSizeLine.startPosition.y + ((event.movementY / num).toFixed(0) - 0)
        if (
          this.reSizeLine.endPosition.x / num > oldStartX / num &&
          oldStartY / num > this.reSizeLine.endPosition.y / num
        ) {
          //左下到右上
          let oldWidth = this.reSizeLine.width
          this.reSizeLine.width = this.reSizeLine.width + changWidth
          this.reSizeLine.height = this.reSizeLine.height - changHeight
          if (this.reSizeLine.width >= 0) {
            this.reSizeLine.x = this.reSizeLine.x - changWidth
          } else {
            this.reSizeLine.width = 0
            this.reSizeLine.x = this.reSizeLine.x + oldWidth
          }
        } else if (
          oldStartX / num > this.reSizeLine.endPosition.x / num &&
          this.reSizeLine.endPosition.y / num > oldStartY / num
        ) {
          //右上到左下
          let oldHeight = this.reSizeLine.height
          this.reSizeLine.width = this.reSizeLine.width - changWidth
          this.reSizeLine.height = this.reSizeLine.height + changHeight
          if (this.reSizeLine.height >= 0) {
            this.reSizeLine.y = this.reSizeLine.y - changHeight
          } else {
            this.reSizeLine.height = 0
            this.reSizeLine.y = this.reSizeLine.y + oldHeight
          }
        } else if (
          oldStartX / num >= this.reSizeLine.endPosition.x / num &&
          oldStartY / num >= this.reSizeLine.endPosition.y / num
        ) {
          //右下到左上
          this.reSizeLine.width = this.reSizeLine.width - changWidth
          this.reSizeLine.height = this.reSizeLine.height - changHeight
        } else if (
          this.reSizeLine.endPosition.x / num > oldStartX / num &&
          this.reSizeLine.endPosition.y / num > oldStartY / num
        ) {
          //左上到右下
          let oldWidth = this.reSizeLine.width
          let oldHeight = this.reSizeLine.height
          this.reSizeLine.width = this.reSizeLine.width + changWidth
          this.reSizeLine.height = this.reSizeLine.height + changHeight
          if (this.reSizeLine.height >= 0) {
            this.reSizeLine.y = this.reSizeLine.y - changHeight
          } else {
            this.reSizeLine.height = 0
            this.reSizeLine.y = this.reSizeLine.y + oldHeight
          }
          if (this.reSizeLine.width >= 0) {
            this.reSizeLine.x = this.reSizeLine.x - changWidth
          } else {
            this.reSizeLine.width = 0
            this.reSizeLine.x = this.reSizeLine.x + oldWidth
          }
        }
      } else {
        let oldEndX = this.reSizeLine.endPosition.x
        let oldEndY = this.reSizeLine.endPosition.y
        let oldStartX = this.reSizeLine.startPosition.x
        let oldStartY = this.reSizeLine.startPosition.y
        let changWidth = 0 - (event.movementX / num).toFixed(0)
        let changHeight = 0 - (event.movementY / num).toFixed(0)
        this.reSizeLine.endPosition.x = this.reSizeLine.endPosition.x + ((event.movementX / num).toFixed(0) - 0)
        this.reSizeLine.endPosition.y = this.reSizeLine.endPosition.y + ((event.movementY / num).toFixed(0) - 0)
        if (oldEndX / num > oldStartX / num && oldStartY / num > oldEndY / num) {
          //左下到右上
          let oldHeight = this.reSizeLine.height
          this.reSizeLine.width = this.reSizeLine.width - changWidth
          this.reSizeLine.height = this.reSizeLine.height + changHeight
          if (this.reSizeLine.height >= 0) {
            this.reSizeLine.y = this.reSizeLine.y - changHeight
          } else {
            this.reSizeLine.height = 0
            this.reSizeLine.y = this.reSizeLine.y + oldHeight
          }
        } else if (oldStartX / num > oldEndX / num && oldEndY / num > oldStartY / num) {
          //右上到左下
          let oldWidth = this.reSizeLine.width
          this.reSizeLine.width = this.reSizeLine.width + changWidth
          this.reSizeLine.height = this.reSizeLine.height - changHeight
          if (this.reSizeLine.width >= 0) {
            this.reSizeLine.x = this.reSizeLine.x - changWidth
          } else {
            this.reSizeLine.width = 0
            this.reSizeLine.x = this.reSizeLine.x + oldWidth
          }
        } else if (oldStartX / num >= oldEndX / num && oldStartY / num >= oldEndY / num) {
          //右下到左上
          let oldWidth = this.reSizeLine.width
          let oldHeight = this.reSizeLine.height
          this.reSizeLine.width = this.reSizeLine.width + changWidth
          this.reSizeLine.height = this.reSizeLine.height + changHeight
          if (this.reSizeLine.height >= 0) {
            this.reSizeLine.y = this.reSizeLine.y - changHeight
          } else {
            this.reSizeLine.height = 0
            this.reSizeLine.y = this.reSizeLine.y + oldHeight
          }
          if (this.reSizeLine.width >= 0) {
            this.reSizeLine.x = this.reSizeLine.x - changWidth
          } else {
            this.reSizeLine.width = 0
            this.reSizeLine.x = this.reSizeLine.x + oldWidth
          }
        } else if (oldEndX / num > oldStartX / num && oldEndY / num > oldStartY / num) {
          //左上到右下
          this.reSizeLine.width = this.reSizeLine.width - changWidth
          this.reSizeLine.height = this.reSizeLine.height - changHeight
        }
      }

      if (this.reSizeLine.width <= 0) {
        this.reSizeLine.width = 0
      }
      if (this.reSizeLine.height <= 0) {
        this.reSizeLine.height = 0
      }
      if (this.reSizeLine.x <= 0) {
        let changeW = 0 - this.reSizeLine.x
        this.reSizeLine.width = this.reSizeLine.width - changeW
        this.reSizeLine.x = 0
      }
    },
    handleReSzieLineMouseUp(event) {
      document.body.removeEventListener('mousemove', this.handleReSzieLineMouseMove)
      document.body.removeEventListener('mouseup', this.handleReSzieLineMouseUp)
      if (
        this.reSizeLine.startPosition.x > this.reSizeLine.endPosition.x &&
        this.reSizeLine.endPosition.y > this.reSizeLine.startPosition.y
      ) {
        this.reSizeLine.startPosition.x = this.reSizeLine.width
        this.reSizeLine.startPosition.y = 0
        this.reSizeLine.endPosition.x = 0
        this.reSizeLine.endPosition.y = this.reSizeLine.height
      } else if (
        this.reSizeLine.startPosition.x >= this.reSizeLine.endPosition.x &&
        this.reSizeLine.startPosition.y >= this.reSizeLine.endPosition.y
      ) {
        this.reSizeLine.startPosition.x = this.reSizeLine.width
        this.reSizeLine.startPosition.y = this.reSizeLine.height
        this.reSizeLine.endPosition.x = 0
        this.reSizeLine.endPosition.y = 0
      } else if (
        this.reSizeLine.endPosition.x > this.reSizeLine.startPosition.x &&
        this.reSizeLine.startPosition.y > this.reSizeLine.endPosition.y
      ) {
        this.reSizeLine.startPosition.x = 0
        this.reSizeLine.startPosition.y = this.reSizeLine.height
        this.reSizeLine.endPosition.x = this.reSizeLine.width
        this.reSizeLine.endPosition.y = 0
      } else if (
        this.reSizeLine.endPosition.x >= this.reSizeLine.startPosition.x &&
        this.reSizeLine.endPosition.y >= this.reSizeLine.startPosition.y
      ) {
        this.reSizeLine.startPosition.x = 0
        this.reSizeLine.startPosition.y = 0
        this.reSizeLine.endPosition.x = this.reSizeLine.width
        this.reSizeLine.endPosition.y = this.reSizeLine.height
      }
      let item = JSON.parse(JSON.stringify(this.reSizeLine))
      let page = JSON.parse(JSON.stringify(this.reSizeLinePage))
      this.$emit('savePageLine', { item, page })
      this.reSizeLine = null
      this.reSzieType = ''
      this.reSizeLinePage = null
      this.reSizeLineOffsetX = 0
      this.reSizeLineOffsetY = 0
      this.addLinePageIndex = 0
    },
    lineMouseDwon(event, item, page) {
      this.moveLineOffsetX = event.offsetX
      this.moveLineOffsetY = event.offsetY
      this.moveLine = item
      this.moveLinePage = page
      this.addLinePageIndex = 0
      this.pageListData.forEach((page, index) => {
        if (page.id == this.moveLinePage.id) {
          this.addLinePageIndex = index
        }
      })
      document.body.addEventListener('mousemove', this.handleLineMouseMove)
      document.body.addEventListener('mouseup', this.handleLineMouseUp)
      /*       item.selected = 1
      if (this.$refs['page-line-' + item.id] && this.$refs['page-line-' + item.id][0]) {
        this.$refs['page-line-' + item.id][0].selectLine = item
        this.$emit('changeSelectLine', { item, page })
      } */
    },
    handleLineMouseMove(event) {
      if (!this.moveLine) {
        return
      }
      let num = this.showImgLeftListPostions[0].width / this.pageListData[0].width
      let changeY = this.showImgLeftListPostions[this.addLinePageIndex].top - this.showImgLeftListPostions[0].top
      let changeX = document.getElementsByClassName('ocr-show-right')[0].getClientRects()[0].x
      let oldX = this.moveLine.x
      let oldY = this.moveLine.y
      this.moveLine.x = ((event.clientX - changeX - this.moveLineOffsetX + 10) / num).toFixed(0) - 0
      this.moveLine.y =
        (
          (event.clientY - this.moveLineOffsetY - this.showImgLeftListPostions[0].top + this.scrollTop - changeY + 10) /
          num
        ).toFixed(0) - 0
      let changePositionX = this.moveLine.x - oldX
      let changePositionY = this.moveLine.y - oldY
      this.moveLine.startPosition.x = this.moveLine.startPosition.x + changePositionX
      this.moveLine.startPosition.y = this.moveLine.startPosition.y + changePositionY
      this.moveLine.endPosition.x = this.moveLine.endPosition.x + changePositionX
      this.moveLine.endPosition.y = this.moveLine.endPosition.y + changePositionY
    },
    handleLineMouseUp() {
      document.body.removeEventListener('mousemove', this.handleLineMouseMove)
      document.body.removeEventListener('mouseup', this.handleLineMouseUp)
      let item = JSON.parse(JSON.stringify(this.moveLine))
      let page = JSON.parse(JSON.stringify(this.moveLinePage))
      this.$emit('savePageLine', { item, page })
      this.moveLine = null
      this.moveLinePage = null
      this.moveLineOffsetX = 0
      this.moveLineOffsetY = 0
      this.addLinePageIndex = 0
    },
    getArrowStyle(start_x, end_x, end_y, start_y, mask_line_width, mask_line_height) {
      let style = 'position: absolute;color:red;font-size:20px;'
      if (start_x > end_x && end_y > start_y) {
        style =
          style +
          `transform: rotate(${this.calculateAngle(0, 0, 0 - mask_line_width, mask_line_height).toFixed(2)}deg);`
        style = style + 'left:-10px;bottom:-10px;'
      } else if (start_x > end_x && start_y > end_y) {
        style =
          style +
          `transform: rotate(${(this.calculateAngle(0, 0, mask_line_width, mask_line_height) + 180).toFixed(2)}deg);`
        style = style + 'left:-10px;top:-10px;'
      } else if (end_x > start_x && start_y > end_y) {
        style =
          style +
          `transform: rotate(${this.calculateAngle(0, 0, mask_line_width, 0 - mask_line_height).toFixed(2)}deg);`
        style = style + 'right:-10px;top:-10px;'
      } else if (end_x > start_x && end_y > start_y) {
        style =
          style + `transform: rotate(${this.calculateAngle(0, 0, mask_line_width, mask_line_height).toFixed(2)}deg);`
        style = style + 'right:-10px;bottom:-10px;'
      } else if (end_x == start_x && start_y > end_y) {
        style =
          style +
          `transform: rotate(${this.calculateAngle(0, 0, mask_line_width, 0 - mask_line_height).toFixed(2)}deg);`
        style = style + 'right:-10px;top:-10px;'
      } else if (start_x > end_x && start_y == end_y) {
        style =
          style +
          `transform: rotate(${(this.calculateAngle(0, 0, mask_line_width, mask_line_height) + 180).toFixed(2)}deg);`
        style = style + 'left:-10px;top:-10px;'
      } else {
        style =
          style + `transform: rotate(${this.calculateAngle(0, 0, mask_line_width, mask_line_height).toFixed(2)}deg);`
        style = style + 'right:-10px;bottom:-10px;'
      }
      return style
    },
    calculateAngle(x1, y1, x2, y2) {
      // 计算两点之间的相对位置
      const deltaX = x2 - x1
      const deltaY = y2 - y1

      // 使用 Math.atan2 计算角度（弧度）
      const angleInRadians = Math.atan2(deltaY, deltaX)

      // 将弧度转换为角度
      const angleInDegrees = angleInRadians * (180 / Math.PI)

      return angleInDegrees
    },
    getInputStyle(item, page, idx, showPostion) {
      let left = '0'
      const dom = document.getElementById(page.id + '-' + item.id + '-' + 'editDiv')
      if (dom && !item.translateTextAreaRegion.editble) {
        let num = showPostion.width / page.width - 0
        let x = item.translateTextAreaRegion.x * num
        let width = item.textAreaRegion.width * num
        let domWidth = dom.getClientRects()[0].width
        if (domWidth - width > 0 && (dom.clientWidth - width) / 2 > x) {
          left = (domWidth - width) / 2 - x + 'px !important'
        }
        if (domWidth - width > 0 && x + width + (domWidth - width) / 2 > showPostion.width) {
          left = showPostion.width - (x + width + (domWidth - width) / 2) - 5 + 'px !important'
        }
      }
      return left
    },
    editorOnsize() {
      const timeOut = setTimeout(() => {
        if (this.$refs.localRightInput && this.$refs.localRightInput[0]) {
          this.$refs.localRightInput[0].EditorOnsize()
        }
        clearTimeout(timeOut)
      }, 300)
    },
    pushLoacal(item) {
      item.postValue = this.postValue
      this.postValue = ''
      this.$emit('pushLoacal', item)
    },
    toStageChange(item) {
      if (!item.commentData || !item.commentData.length) return
      this.$emit('toStageChange', item)
    },
    ocr(textArea) {
      this.$emit('ocr', textArea)
    },
    changeTranslate(textArea) {
      this.$emit('changeTranslate', textArea)
    },
    changeIsDele(value) {
      this.isDelete = value
    },
    changeLeftImgShow() {
      if (this.leftImgShow) {
        document.getElementsByClassName('show-img-bottom-left')[0].style.flex = '0'
        this.rightImgShow = false
      } else {
        document.getElementsByClassName('show-img-bottom-left')[0].style.flex = '1'
      }
      setTimeout(() => {
        this.leftImgShow = !this.leftImgShow
        this.rightImgShow = true
        this.$emit('changeLeftImgShow')
      }, this.pageListData.length * 100)
    },
    changeRightImgShow() {
      if (this.rightImgShow) {
        document.getElementsByClassName('show-img-bottom-right')[0].style.flex = '0'
        this.leftImgShow = false
      } else {
        document.getElementsByClassName('show-img-bottom-right')[0].style.flex = '1'
      }
      setTimeout(() => {
        this.rightImgShow = !this.rightImgShow
        this.leftImgShow = true
        this.$emit('changeRightImgShow')
      }, this.pageListData.length * 100)
    },
    deletFeedback(item, textArea) {
      let that = this
      this.$confirm({
        title: '确认删除?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        async onOk() {
          const res = await v1DeleteAction('/textarea/feedback/delete/' + item.id)
          if (res.code == 200) {
            that.$message.success('删除成功')
            that.$emit('getRevisionList')
            that.getRevisionList(textArea)
            that.$forceUpdate()
          } else {
            that.$message.error(res.msg || res.errorMsg)
          }
        },
        onCancel() {
          /* console.log('Cancel') */
        }
      })
    },
    deletReject(item, textArea) {
      let that = this
      this.$confirm({
        title: '确认删除?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        async onOk() {
          const res = await v1DeleteAction('/textarea/reject_msg/delete/' + item.id)
          if (res.code == 200) {
            that.$message.success('删除成功')
            that.$emit('getRevisionList')
            that.getRevisionList(textArea)
            that.$forceUpdate()
          } else {
            that.$message.error(res.msg || res.errorMsg)
          }
        },
        onCancel() {
          /* console.log('Cancel') */
        }
      })
    },
    openTerm(word) {
      let record = this.termsAll.find(t => t.term.id == word.id)
      this.isOpenModal = true
      this.$emit('openEditTermModal', record)
      this.$emit('changeIsAddTerm', false)
      this.$emit('topCenterMouseover')
      this.$emit('copyData')
      this.$emit('savePageList')
      this.$nextTick(() => {
        this.$emit('changeAnchorNodeBefore')
      })
    },
    checkPermission(permission) {
      return checkPermission(permission, this.authList)
    },
    getBlackWords(item, words) {
      if (!item.textAreaRegion.selected) return
      let t = ''
      let blackWords = []
      words.words?.forEach(each => {
        if (
          each &&
          each.value &&
          item.showText.indexOf(each?.value) >= 0 &&
          this.filtrationList.every(filtration => each.value.indexOf(filtration.value) == -1)
        ) {
          if (each.target && each.value) {
            blackWords.push(each?.value + '→' + each.target)
          } else if (each.value && !each.target) {
            blackWords.push(each?.value)
          }
        }
      })
      if (blackWords.length) {
        blackWords = [...new Set(blackWords)]
        t = blackWords.join('，')
      }
      return t
    },
    topCenterMouseover() {
      this.$emit('topCenterMouseover')
    },
    getIsAddPhoneticTextAll(item, word) {
      let indexs = this.getAllTextIndex(item.showText, word.processedName)
      if (!indexs.length && word.processedFamilyName) {
        indexs = this.getAllTextIndex(item.showText, word.processedFamilyName)
      }
      if (!indexs.length && word.processedGivenName) {
        indexs = this.getAllTextIndex(item.showText, word.processedGivenName)
      }
      let isAdd = false
      indexs.forEach(idx => {
        if (item.characterSentences[idx]) {
          if (
            (item.characterSentences[idx].phonetic &&
              item.characterSentences[idx].phonetic.phoneticText == word.phoneticText) ||
            (word.phoneticFamilyNameTextList.length &&
              item.characterSentences[idx].phonetic &&
              item.characterSentences[idx].phonetic.phoneticText == word.phoneticFamilyNameTextList[0].value) ||
            (word.phoneticGivenNameTextList.length &&
              item.characterSentences[idx].phonetic &&
              item.characterSentences[idx].phonetic.phoneticText == word.phoneticGivenNameTextList[0].value)
          ) {
            isAdd = true
          }
        }
        if (word.phoneticTextList.length && word.phoneticTextList.some(w => w.type == 'single')) {
          let originLength = word.processedName.length
          let phoneticText = ''
          if (item.characterSentences[idx]) {
            for (let i = 0; i < originLength; i++) {
              if (item.characterSentences[idx + i] && item.characterSentences[idx + i].phonetic) {
                phoneticText = phoneticText + item.characterSentences[idx + i].phonetic.phoneticText
              }
            }
            if (phoneticText == word.phoneticText) {
              isAdd = true
            }
          }
        }
      })
      return isAdd
    },
    getIsAddPhoneticText(item, word) {
      let indexs = this.getAllTextIndex(item.showText, word.processedName)
      let isAdd = false
      indexs.forEach(idx => {
        if (item.characterSentences[idx]) {
          if (
            item.characterSentences[idx].phonetic &&
            item.characterSentences[idx].phonetic.phoneticText == word.phoneticText
          ) {
            isAdd = true
          }
        }
      })
      return isAdd
    },
    getIsAddPhonetic(item, word) {
      let indexs = this.getAllTextIndex(item.showText, word.processedName)
      let isAdd = false
      indexs.forEach(idx => {
        if (item.characterSentences[idx]) {
          if (
            item.characterSentences[idx].phonetic &&
            item.characterSentences[idx].phonetic.phoneticText == word.phoneticText
          ) {
            let targetCharacterIds = []
            for (let i = 0; i < word.processedName.length; i++) {
              if (item.characterSentences[idx + i]) {
                targetCharacterIds.push(item.characterSentences[idx + i].characterSentenceId)
              }
            }
            if (
              targetCharacterIds
                .filter((target, tIdx) => tIdx)
                .some(
                  targetId =>
                    item.characterSentences.find(findT => findT.characterSentenceId == targetId).phonetic &&
                    item.characterSentences.find(findT => findT.characterSentenceId == targetId).phonetic.phoneticText
                )
            ) {
              return
            }
            isAdd = true
          }
        }
      })
      return isAdd
    },
    getBlackWordsTwo(item, words) {
      if (!item.textAreaRegion.selected) return
      let blackWords = []
      words.words?.forEach(each => {
        if (
          each &&
          each.values &&
          each.values.some(value => item.showText.replaceAll('\n', '').indexOf(value) > -1) &&
          this.filtrationList.every(filtration => each.values.some(value => value.indexOf(filtration.value) == -1))
        ) {
          let emj = '→'
          if (words.className == '混淆词') emj = '&'
          blackWords.push({ title: each.values.join(emj), remark: each.remark })
        }
      })
      return blackWords
    },
    clearSelectErrorType() {
      this.selectErrorType = 0
    },
    deleRevision(textArea) {
      this.pageListData.forEach(page => {
        page.textAreas.forEach(item => {
          if (item.id == textArea.id) {
            item.isReject = false
          }
        })
      })
    },
    async getRevisionList(textArea) {
      this.selectErrorType = 0
      /*       const res = await getAction('/original/textarea/' + textArea.id)
      if (res.code == 200) {
        this.topFeedbacks = res.data.proofreadFeedbacks || []
        this.topRejectComments = res.data.proofreadRejectComments || []
        this.topInjuryType = res.data.proofreadInjuryType || 0
        this.topInjuryRemark = res.data.proofreadInjuryRemark || ''
        this.bottomFeedbacks = res.data.finalCheckFeedbacks || []
        this.bottomRejectComments = res.data.finalCheckRejectComments || []
        this.bottomInjuryType = res.data.finalCheckInjuryType || 0
        this.bottomInjuryRemark = res.data.finalCheckInjuryRemark || ''
        this.topRejectComments?.forEach(item => (item.editble = false))
        this.topFeedbacks?.forEach(item => (item.editble = false))
        this.bottomRejectComments?.forEach(item => (item.editble = false))
        this.bottomFeedbacks?.forEach(item => (item.editble = false))
        this.selectErrorTypes = []
        this.selectBottomErrorTypes = []
        if (this.topFeedbacks.length) this.selectErrorTypes.push(0)
        if (this.topInjuryType) {
          this.selectErrorTypes.push(this.topInjuryType)
          this.changeInjuryType(this.topInjuryType)
        }
        if (this.topRejectComments.length) this.selectErrorTypes.push(4)
        if (this.selectErrorTypes.length) {
          this.selectErrorType = this.selectErrorTypes[0]
        } else {
          this.selectErrorType = 0
          this.topShow = false
        }
        if (this.bottomFeedbacks.length) this.selectBottomErrorTypes.push(0)
        if (this.bottomInjuryType) {
          this.selectBottomErrorTypes.push(this.bottomInjuryType)
          this.changeInjuryTypeBottom(this.bottomInjuryType)
        }
        if (this.bottomRejectComments.length) this.selectBottomErrorTypes.push(4)
        if (this.selectBottomErrorTypes.length) {
          this.selectErrorTypeBottom = this.selectBottomErrorTypes[0]
        } else {
          this.selectErrorTypeBottom = 0
          this.bottomShow = false
        }
        this.$forceUpdate()
      } */
    },
    inputFocus() {
      this.$emit('changeCardInput', true)
    },
    inputBlur() {
      this.$emit('changeCardInput', false)
    },
    async getYahooOrKdxf(words, pageId, textAreaId, item) {
      this.getYahooPhoneticsSim(words, pageId, textAreaId, item)
      let yahooOrKdxList = []
      if (this.taskDetail.targetLang == 'JAPANESE' && words) {
        const res = await postAction('/qualityInspection/requestYahoo', { words })
        if (res.code == 200) {
          res.data.forEach(d => {
            d.result.result.suggestions?.forEach((item, idx) => {
              if (yahooOrKdxList.every(i => i.className != item.rule)) {
                yahooOrKdxList.push({ classId: d.result.id + '-' + idx, className: item.rule, words: [] })
              }
            })
            d.result.result.suggestions?.forEach((item, idx) => {
              yahooOrKdxList.forEach(i => {
                if (i.className == item.rule) {
                  i.words.push({ value: item.word, target: item.suggestion })
                }
              })
            })
          })
        }
      } else if (this.taskDetail.targetLang == 'SIMPLIFIED_CHINESE' && words) {
        const res = await postAction('/qualityInspection/requestKdxf', {
          words,
          uid: this.userInfo.userId
        })
        if (res.code == 200) {
          res.data.forEach(d => {
            for (let key in d.result) {
              d.result[key].forEach(text => {
                if (yahooOrKdxList.every(i => i.className != text[3])) {
                  yahooOrKdxList.push({
                    classId: d.idx + '-' + key + '-' + text[0],
                    className: text[3] /* this.kdxfMap.get(text[3]) 讯飞更新 */,
                    words: []
                  })
                }
              })
            }
            for (let key in d.result) {
              d.result[key]?.forEach(text => {
                yahooOrKdxList.forEach(i => {
                  if (i.className == text[3]) {
                    i.words.push({ value: text[1], target: text[2] })
                  }
                })
              })
            }
          })
        }
      }
      this.yahooOrKdxList = yahooOrKdxList.sort(function compareFunction(a, b) {
        return a.className.localeCompare(b.className)
      })
      this.blacklist.forEach(words => {
        if (words.classId != 12) {
          words.words?.forEach(each => {
            if (
              each &&
              each.value &&
              item.showText.indexOf(each?.value) >= 0 &&
              this.filtrationList.every(filtration => each.value.indexOf(filtration.value) == -1)
            ) {
              let length = each.value.length
              item.characterSentences.forEach((t, tIdx) => {
                let text = ''
                for (let i = 0; i < length; i++) {
                  if (item.characterSentences[tIdx + i] && item.characterSentences[tIdx + i].characterText) {
                    text = text + item.characterSentences[tIdx + i].characterText
                  }
                }
                if (text == each.value) {
                  for (let i = 0; i < length; i++) {
                    if (item.characterSentences[tIdx + i] && item.characterSentences[tIdx + i].characterText) {
                      item.characterSentences[tIdx + i].isBlack = true
                    }
                  }
                }
              })
            }
          })
        } else {
          words.words?.forEach(each => {
            if (
              each &&
              each.values &&
              each.values.some(value => item.showText.replaceAll('\n', '').indexOf(value) > -1) &&
              this.filtrationList.every(filtration => each.values.some(value => value.indexOf(filtration.value) == -1))
            ) {
              /* let value = each.values.find(value => item.showText.replaceAll('\n', '').indexOf(value) > -1) */
              each.values?.forEach(value => {
                if (!value) return
                if (item.showText.replaceAll('\n', '').indexOf(value) > -1) {
                  let length = value.length
                  item.characterSentences.forEach((t, tIdx) => {
                    let text = ''
                    for (let i = 0; i < length; i++) {
                      if (item.characterSentences[tIdx + i] && item.characterSentences[tIdx + i].characterText) {
                        if (
                          item.characterSentences[tIdx + i].characterText == '<br>' &&
                          item.characterSentences[tIdx + i + 1]
                        ) {
                          text = text + item.characterSentences[tIdx + i + 1].characterText
                        } else {
                          text = text + item.characterSentences[tIdx + i].characterText
                        }
                      }
                    }
                    if (text == value) {
                      for (let i = 0; i < length; i++) {
                        if (item.characterSentences[tIdx + i] && item.characterSentences[tIdx + i].characterText) {
                          if (
                            item.characterSentences[tIdx + i].characterText == '<br>' &&
                            item.characterSentences[tIdx + i + 1]
                          ) {
                            item.characterSentences[tIdx + i + 1].isBlack = true
                          } else {
                            item.characterSentences[tIdx + i].isBlack = true
                          }
                        }
                      }
                    }
                  })
                }
              })
            }
          })
        }
      })
      this.yahooOrKdxList.forEach(words => {
        if (words.classId != 12) {
          words.words?.forEach(each => {
            if (
              each &&
              each.value &&
              item.showText.indexOf(each?.value) >= 0 &&
              this.filtrationList.every(filtration => each.value.indexOf(filtration.value) == -1)
            ) {
              let length = each.value.length
              item.characterSentences.forEach((t, tIdx) => {
                let text = ''
                for (let i = 0; i < length; i++) {
                  if (item.characterSentences[tIdx + i] && item.characterSentences[tIdx + i].characterText) {
                    text = text + item.characterSentences[tIdx + i].characterText
                  }
                }
                if (text == each.value) {
                  for (let i = 0; i < length; i++) {
                    if (item.characterSentences[tIdx + i] && item.characterSentences[tIdx + i].characterText) {
                      item.characterSentences[tIdx + i].isYahoo = true
                    }
                  }
                }
              })
            }
          })
        } else {
          words.words?.forEach(each => {
            if (
              each &&
              each.values &&
              each.values.some(value => item.showText.replaceAll('\n', '').indexOf(value) > -1) &&
              this.filtrationList.every(filtration => each.values.some(value => value.indexOf(filtration.value) == -1))
            ) {
              let value = each.values.find(value => item.showText.replaceAll('\n', '').indexOf(value) > -1)
              if (value) {
                let length = value.length
                item.characterSentences.forEach((t, tIdx) => {
                  let text = ''
                  for (let i = 0; i < length; i++) {
                    if (item.characterSentences[tIdx + i] && item.characterSentences[tIdx + i].characterText) {
                      text = text + item.characterSentences[tIdx + i].characterText
                    }
                  }
                  if (text == value) {
                    for (let i = 0; i < length; i++) {
                      if (item.characterSentences[tIdx + i] && item.characterSentences[tIdx + i].characterText) {
                        item.characterSentences[tIdx + i].isYahoo = true
                      }
                    }
                  }
                })
              }
            }
          })
        }
      })
    },
    addPhoneticAll(item, i) {
      let arr = []
      if (i.phoneticFamilyNameTextList.length) {
        if (i.phoneticFamilyNameTextList[0].type == 'single') {
          i.phoneticFamilyNameTextList.forEach(p => {
            arr.push({
              processedName: p.key,
              phoneticText: p.value,
              autoPhoneticTrigger: i.autoPhoneticTrigger
            })
          })
        } else {
          arr.push({
            processedName: i.processedFamilyName,
            phoneticText: i.phoneticFamilyNameTextList.map(p => p.value).join(''),
            autoPhoneticTrigger: i.autoPhoneticTrigger
          })
        }
      }
      if (i.phoneticGivenNameTextList.length) {
        if (i.phoneticGivenNameTextList[0].type == 'single') {
          i.phoneticGivenNameTextList.forEach(p => {
            arr.push({
              processedName: p.key,
              phoneticText: p.value,
              autoPhoneticTrigger: i.autoPhoneticTrigger
            })
          })
        } else {
          arr.push({
            processedName: i.processedGivenName,
            phoneticText: i.phoneticGivenNameTextList.map(p => p.value).join(''),
            autoPhoneticTrigger: i.autoPhoneticTrigger,
            type: i.phoneticGivenNameTextList[0].type
          })
        }
      }
      if (i.phoneticNickNameTextList.length) {
        if (i.phoneticNickNameTextList[0].type == 'single') {
          i.phoneticNickNameTextList.forEach(p => {
            arr.push({
              processedName: p.key,
              phoneticText: p.value,
              autoPhoneticTrigger: i.autoPhoneticTrigger
            })
          })
        } else {
          arr.push({
            processedName: i.originNickname,
            phoneticText: i.phoneticNickNameTextList.map(p => p.value).join(''),
            autoPhoneticTrigger: i.autoPhoneticTrigger
          })
        }
      }
      if (i.phoneticTextList.length && !i.phoneticFamilyNameTextList.length && !i.phoneticGivenNameTextList.length) {
        if (i.phoneticTextList[0].type == 'single') {
          i.phoneticTextList.forEach(p => {
            arr.push({
              processedName: p.key,
              phoneticText: p.value,
              autoPhoneticTrigger: i.autoPhoneticTrigger
            })
          })
        } else {
          arr.push({
            processedName: i.processedName,
            phoneticText: i.phoneticTextList.map(p => p.value).join(''),
            autoPhoneticTrigger: i.autoPhoneticTrigger,
            type: i.phoneticTextList[0].type
          })
        }
      }
      if (this.getIsAddPhoneticTextAll(item, i)) {
        arr.forEach(word => {
          let indexs = this.getAllTextIndex(item.showText, word.processedName)
          indexs.forEach(idx => {
            if (
              item.characterSentences[idx] &&
              item.characterSentences[idx].phonetic &&
              item.characterSentences[idx].phonetic.phoneticText == word.phoneticText
            ) {
              item.characterSentences[idx].phonetic = null
            }
          })
        })
      } else {
        arr.forEach(word => {
          if (!word.phoneticText) return
          let pushArr = []
          let textIds = []
          if (word.autoPhoneticTrigger > 0 && word.autoPhoneticTrigger < 4) {
            if (item.showText.indexOf(word.processedName) > -1) {
              let textLength = word.processedName.length
              let targetCharacterIds = []
              let text = item.characterSentences[item.showText.indexOf(word.processedName)]
              for (let i = 0; i < textLength; i++) {
                if (item.characterSentences[item.showText.indexOf(word.processedName) + i]) {
                  targetCharacterIds.push(
                    item.characterSentences[item.showText.indexOf(word.processedName) + i].characterSentenceId
                  )
                }
                if (i) {
                  item.characterSentences[item.showText.indexOf(word.processedName) + i].phonetic = null
                }
              }
              text.phonetic = {
                fontSize: (text.fontSize - 1) / 2 + 1,
                letterSpacing: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0,
                scaleY: this.phoneticStyle.scaleY,
                scaleX: this.phoneticStyle.scaleX,
                lineSpacing: this.phoneticStyle.lineSpacing || 1.15,
                textAlign: this.phoneticStyle.textAlign,
                fontColor: this.phoneticStyle.fontColor,
                phoneticText: word.phoneticText,
                targetCharacterIds: targetCharacterIds,
                letterSpacingOption: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0
              }
              if (item.textLayout == 2) {
                if (word.phoneticText.length < 3) {
                  text.phonetic.scaleY = 1
                  text.phonetic.letterSpacing = 0
                } else if (word.phoneticText.length == 3) {
                  text.phonetic.scaleY = 0.7
                } else {
                  text.phonetic.scaleY = 0.6
                  text.phonetic.letterSpacing = 0
                }
              } else {
                if (word.phoneticText.length < 3) {
                  text.phonetic.scaleX = 1
                  text.phonetic.letterSpacing = 0
                } else if (word.phoneticText.length == 3) {
                  text.phonetic.scaleX = 0.7
                } else {
                  text.phonetic.scaleX = 0.6
                  text.phonetic.letterSpacing = 0
                }
              }
            }
          } else if (word.autoPhoneticTrigger == 0 || word.autoPhoneticTrigger == 4) {
            if (this.getAllTextIndex(item.showText, word.processedName).length) {
              this.getAllTextIndex(item.showText, word.processedName).forEach(idx => {
                if (pushArr.indexOf(idx) > -1) return
                let textLength = word.processedName.length
                let targetCharacterIds = []
                let text = item.characterSentences[idx]
                item.characterSentences.forEach(t => {
                  if (t.phonetic && t.phonetic.targetCharacterIds.indexOf(text.characterSentenceId) > -1) {
                    t.phonetic = null
                  }
                })
                if (text.characterSentenceId && textIds.indexOf(text.characterSentenceId) > -1) {
                  text.phonetic = null
                }
                for (let i = 0; i < textLength; i++) {
                  if (item.characterSentences[idx + i]) {
                    targetCharacterIds.push(item.characterSentences[idx + i].characterSentenceId)
                  }
                  if (i) {
                    if (item.characterSentences[idx + i]) {
                      item.characterSentences[idx + i].phonetic = null
                    }
                  }
                }
                text.phonetic = {
                  fontSize: (text.fontSize - 1) / 2 + 1,
                  letterSpacing: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0,
                  scaleY: this.phoneticStyle.scaleY,
                  scaleX: this.phoneticStyle.scaleX,
                  lineSpacing: this.phoneticStyle.lineSpacing || 1.15,
                  textAlign: this.phoneticStyle.textAlign,
                  fontColor: this.phoneticStyle.fontColor,
                  phoneticText: word.phoneticText,
                  targetCharacterIds: targetCharacterIds,
                  letterSpacingOption: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0
                }
                if (item.textLayout == 2) {
                  if (word.phoneticText.length < 3) {
                    text.phonetic.scaleY = 1
                    text.phonetic.letterSpacing = 0
                  } else if (word.phoneticText.length == 3) {
                    text.phonetic.scaleY = 0.7
                  } else {
                    text.phonetic.scaleY = 0.6
                    text.phonetic.letterSpacing = 0
                  }
                } else {
                  if (word.phoneticText.length < 3) {
                    text.phonetic.scaleX = 1
                    text.phonetic.letterSpacing = 0
                  } else if (word.phoneticText.length == 3) {
                    text.phonetic.scaleX = 0.7
                  } else {
                    text.phonetic.scaleX = 0.6
                    text.phonetic.letterSpacing = 0
                  }
                }
                textIds = [...textIds, ...targetCharacterIds]
                pushArr.push(idx)
              })
            }
          }
        })
      }

      item.pIndexs = []
      item.brIndexs = []
      item.characterSentences.forEach((t, pushTtextIndex) => {
        if (
          item.characterSentences[pushTtextIndex + 1] &&
          item.characterSentences[pushTtextIndex + 1].phonetic &&
          item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
        ) {
          item.pIndexs.push(pushTtextIndex)
        }
      })
      item.showTexts = []
      this.$nextTick(() => {
        this.changeShowText(item)
      })
      this.$emit('copyData')
      this.$emit('savePageList')
    },
    addPhonetic(item, word) {
      if (this.getIsAddPhonetic(item, word)) {
        let indexs = this.getAllTextIndex(item.showText, word.processedName)
        indexs.forEach(idx => {
          if (
            item.characterSentences[idx] &&
            item.characterSentences[idx].phonetic &&
            item.characterSentences[idx].phonetic.phoneticText == word.phoneticText
          ) {
            item.characterSentences[idx].phonetic = null
          }
        })
      } else {
        if (!word.phoneticText) return
        let pushArr = []
        let textIds = []
        if (word.autoPhoneticTrigger > 0 && word.autoPhoneticTrigger < 4) {
          if (item.showText.indexOf(word.processedName) > -1) {
            let textLength = word.processedName.length
            let targetCharacterIds = []
            let text = item.characterSentences[item.showText.indexOf(word.processedName)]
            for (let i = 0; i < textLength; i++) {
              if (item.characterSentences[item.showText.indexOf(word.processedName) + i]) {
                targetCharacterIds.push(
                  item.characterSentences[item.showText.indexOf(word.processedName) + i].characterSentenceId
                )
              }
              if (i) {
                item.characterSentences[item.showText.indexOf(word.processedName) + i].phonetic = null
              }
            }
            text.phonetic = {
              fontSize: (text.fontSize - 1) / 2 + 1,
              letterSpacing: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0,
              scaleY: this.phoneticStyle.scaleY,
              scaleX: this.phoneticStyle.scaleX,
              lineSpacing: this.phoneticStyle.lineSpacing || 1.15,
              textAlign: this.phoneticStyle.textAlign,
              fontColor: this.phoneticStyle.fontColor,
              phoneticText: word.phoneticText,
              targetCharacterIds: targetCharacterIds,
              letterSpacingOption: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0
            }
            if (item.textLayout == 2) {
              if (word.phoneticText.length < 3) {
                text.phonetic.scaleY = 1
                text.phonetic.letterSpacing = 0
              } else if (word.phoneticText.length == 3) {
                text.phonetic.scaleY = 0.7
              } else {
                text.phonetic.scaleY = 0.6
                text.phonetic.letterSpacing = 0
              }
            } else {
              if (word.phoneticText.length < 3) {
                text.phonetic.scaleX = 1
                text.phonetic.letterSpacing = 0
              } else if (word.phoneticText.length == 3) {
                text.phonetic.scaleX = 0.7
              } else {
                text.phonetic.scaleX = 0.6
                text.phonetic.letterSpacing = 0
              }
            }
          }
        } else if (word.autoPhoneticTrigger == 0 || word.autoPhoneticTrigger == 4) {
          if (this.getAllTextIndex(item.showText, word.processedName).length) {
            this.getAllTextIndex(item.showText, word.processedName).forEach(idx => {
              if (pushArr.indexOf(idx) > -1) return
              let textLength = word.processedName.length
              let targetCharacterIds = []
              let text = item.characterSentences[idx]
              item.characterSentences.forEach(t => {
                if (t.phonetic && t.phonetic.targetCharacterIds.indexOf(text.characterSentenceId) > -1) {
                  t.phonetic = null
                }
              })
              if (text.characterSentenceId && textIds.indexOf(text.characterSentenceId) > -1) {
                text.phonetic = null
              }
              for (let i = 0; i < textLength; i++) {
                if (item.characterSentences[idx + i]) {
                  targetCharacterIds.push(item.characterSentences[idx + i].characterSentenceId)
                }
                if (i) {
                  if (item.characterSentences[idx + i]) {
                    item.characterSentences[idx + i].phonetic = null
                  }
                }
              }
              text.phonetic = {
                fontSize: (text.fontSize - 1) / 2 + 1,
                letterSpacing: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0,
                scaleY: this.phoneticStyle.scaleY,
                scaleX: this.phoneticStyle.scaleX,
                lineSpacing: this.phoneticStyle.lineSpacing || 1.15,
                textAlign: this.phoneticStyle.textAlign,
                fontColor: this.phoneticStyle.fontColor,
                phoneticText: word.phoneticText,
                targetCharacterIds: targetCharacterIds,
                letterSpacingOption: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0
              }
              if (item.textLayout == 2) {
                if (word.phoneticText.length < 3) {
                  text.phonetic.scaleY = 1
                  text.phonetic.letterSpacing = 0
                } else if (word.phoneticText.length == 3) {
                  text.phonetic.scaleY = 0.7
                } else {
                  text.phonetic.scaleY = 0.6
                  text.phonetic.letterSpacing = 0
                }
              } else {
                if (word.phoneticText.length < 3) {
                  text.phonetic.scaleX = 1
                  text.phonetic.letterSpacing = 0
                } else if (word.phoneticText.length == 3) {
                  text.phonetic.scaleX = 0.7
                } else {
                  text.phonetic.scaleX = 0.6
                  text.phonetic.letterSpacing = 0
                }
              }
              textIds = [...textIds, ...targetCharacterIds]
              pushArr.push(idx)
            })
          }
        }
      }
      item.pIndexs = []
      item.brIndexs = []
      item.characterSentences.forEach((t, pushTtextIndex) => {
        if (
          item.characterSentences[pushTtextIndex + 1] &&
          item.characterSentences[pushTtextIndex + 1].phonetic &&
          item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
        ) {
          item.pIndexs.push(pushTtextIndex)
        }
      })
      item.showTexts = []
      this.$nextTick(() => {
        this.changeShowText(item)
      })
      this.$emit('copyData')
      this.$emit('savePageList')
    },
    async checkPhonetic(item) {
      const res = await getAction('/qualityInspection/questPhonetic', { sentence: item.showText, type: 'multiple' })
      if (res.code == 200) {
        //陈立说每个字注音都相同，不考虑多音字情况
        this.checkPhoneticList = []
        if (res.data[0]) {
          if (res.data[0].result) {
            res.data[0].result.word?.forEach(w => {
              if (w.furigana && this.checkPhoneticList.every(c => c.surface != w.surface)) {
                this.checkPhoneticList.push(w)
              }
            })
          } else if (res.data[0]?.word) {
            res.data[0]?.word?.forEach(w => {
              if (w.furigana && this.checkPhoneticList.every(c => c.surface != w.surface)) {
                this.checkPhoneticList.push(w)
              }
            })
          }
        }
        if (!this.checkPhoneticList.length) {
          this.$message.warning('无注音数据')
        } else {
          this.checkPhoneticList.sort(function compareFunction(a, b) {
            return a.surface.localeCompare(b.surface)
          })
        }
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    getYahooPhonetics: simpleDebounce(async function(words, pageId, textAreaId, item) {
      this.getYahooPhoneticsSim(words, pageId, textAreaId, item)
    }, 1000),
    async getYahooPhoneticsSim(words, pageId, textAreaId, item) {
      if (!this.changeAll) return
      if (this.departName == '日语部') {
        const res = await postAction('/textarea/checkAutoPhonetic', {
          productionId: this.taskDetail.productionId,
          chapterId: this.taskDetail.chapterId,
          pagedId: pageId,
          showText: words,
          textAreaId: typeof textAreaId != 'number' ? null : textAreaId
        })
        if (res.code == 200) {
          /*           this.termsPhonetics = res.data?.map(i => {
            return {
              processedName: i.processedName,
              phoneticText: i.phoneticText,
              autoPhoneticTrigger: i.autoPhoneticTrigger
            }
          }) */
          this.termsPhonetics = []
          this.termsPhoneticsAll = res.data || []
          res.data?.forEach(i => {
            if (i.phoneticFamilyNameTextList.length) {
              if (i.phoneticFamilyNameTextList[0].type == 'single') {
                i.phoneticFamilyNameTextList.forEach(p => {
                  this.termsPhonetics.push({
                    processedName: p.key,
                    phoneticText: p.value,
                    autoPhoneticTrigger: i.autoPhoneticTrigger
                  })
                })
              } else {
                this.termsPhonetics.push({
                  processedName: i.processedFamilyName,
                  phoneticText: i.phoneticFamilyNameTextList.map(p => p.value).join(''),
                  autoPhoneticTrigger: i.autoPhoneticTrigger
                })
              }
            }
            if (i.phoneticGivenNameTextList.length) {
              if (i.phoneticGivenNameTextList[0].type == 'single') {
                i.phoneticGivenNameTextList.forEach(p => {
                  this.termsPhonetics.push({
                    processedName: p.key,
                    phoneticText: p.value,
                    autoPhoneticTrigger: i.autoPhoneticTrigger
                  })
                })
              } else {
                this.termsPhonetics.push({
                  processedName: i.processedGivenName,
                  phoneticText: i.phoneticGivenNameTextList.map(p => p.value).join(''),
                  autoPhoneticTrigger: i.autoPhoneticTrigger,
                  type: i.phoneticGivenNameTextList[0].type
                })
              }
            }
            if (i.phoneticNickNameTextList.length) {
              if (i.phoneticNickNameTextList[0].type == 'single') {
                i.phoneticNickNameTextList.forEach(p => {
                  this.termsPhonetics.push({
                    processedName: p.key,
                    phoneticText: p.value,
                    autoPhoneticTrigger: i.autoPhoneticTrigger
                  })
                })
              } else {
                this.termsPhonetics.push({
                  processedName: i.originNickname,
                  phoneticText: i.phoneticNickNameTextList.map(p => p.value).join(''),
                  autoPhoneticTrigger: i.autoPhoneticTrigger
                })
              }
            }
            if (i.phoneticTextList.length) {
              if (i.phoneticTextList[0].type == 'single') {
                i.phoneticTextList.forEach(p => {
                  this.termsPhonetics.push({
                    processedName: p.key,
                    phoneticText: p.value,
                    autoPhoneticTrigger: i.autoPhoneticTrigger
                  })
                })
              } else {
                this.termsPhonetics.push({
                  processedName: i.processedName,
                  phoneticText: i.phoneticTextList.map(p => p.value).join(''),
                  autoPhoneticTrigger: i.autoPhoneticTrigger,
                  type: i.phoneticTextList[0].type
                })
              }
            }
          })
          this.termsPhoneticsAll.forEach(i => {
            if (i.phoneticFamilyNameTextList.length && !i.phoneticTextList.length) {
              i.phoneticTextList = [...i.phoneticFamilyNameTextList]
              if (i.phoneticGivenNameTextList.length) {
                i.phoneticTextList = [...i.phoneticTextList, ...i.phoneticGivenNameTextList]
              }
            }
            if (i.phoneticGivenNameTextList.length && !i.phoneticTextList.length) {
              i.phoneticTextList = [...i.phoneticGivenNameTextList]
            }
            i.phoneticText = i.phoneticTextList.map(ip => ip.value).join('')
          })
          if (!this.termsPhonetics) this.termsPhonetics = []
          this.$emit('changeTermsPhonetics')
          let pushArr = []
          let textIds = []
          /*           this.termsPhonetics?.forEach(i => {
            if (!i.phoneticText) return
            if (i.autoPhoneticTrigger > 0 && i.autoPhoneticTrigger < 4) {
              if (item.showText.indexOf(i.processedName) > -1) {
                let textLength = i.processedName.length
                let targetCharacterIds = []
                let text = item.characterSentences[item.showText.indexOf(i.processedName)]
                for (let a = 0; a < textLength; a++) {
                  if (item.characterSentences[item.showText.indexOf(i.processedName) + a]) {
                    targetCharacterIds.push(
                      item.characterSentences[item.showText.indexOf(i.processedName) + a].characterSentenceId
                    )
                  }
                  if (
                    targetCharacterIds.some(
                      targetId =>
                        item.characterSentences.find(findT => findT.characterSentenceId == targetId).phonetic &&
                        item.characterSentences.find(findT => findT.characterSentenceId == targetId).phonetic
                          .phoneticText
                    )
                  ) {
                    return
                  }
                  if (a) {
                    item.characterSentences[item.showText.indexOf(i.processedName) + a].phonetic = null
                  }
                }
                text.phonetic = {
                  fontSize: (text.fontSize - 1) / 2 + 1,
                  letterSpacing: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0,
                  scaleY: this.phoneticStyle.scaleY,
                  scaleX: this.phoneticStyle.scaleX,
                  lineSpacing: this.phoneticStyle.lineSpacing || 1.15,
                  textAlign: this.phoneticStyle.textAlign,
                  fontColor: this.phoneticStyle.fontColor,
                  phoneticText: i.phoneticText,
                  targetCharacterIds: targetCharacterIds,
                  letterSpacingOption: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0
                }
                if (item.textLayout == 2) {
                  if (i.phoneticText.length < 3) {
                    text.phonetic.scaleY = 1
                    text.phonetic.letterSpacing = 0
                  } else if (i.phoneticText.length == 3) {
                    text.phonetic.scaleY = 0.7
                  } else {
                    text.phonetic.scaleY = 0.6
                    text.phonetic.letterSpacing = 0
                  }
                } else {
                  if (i.phoneticText.length < 3) {
                    text.phonetic.scaleX = 1
                    text.phonetic.letterSpacing = 0
                  } else if (i.phoneticText.length == 3) {
                    text.phonetic.scaleX = 0.7
                  } else {
                    text.phonetic.scaleX = 0.6
                    text.phonetic.letterSpacing = 0
                  }
                }
              }
            } else if (i.autoPhoneticTrigger == 0) {
              if (this.getAllTextIndex(item.showText, i.processedName).length) {
                this.getAllTextIndex(item.showText, i.processedName).forEach(idx => {
                  if (pushArr.indexOf(idx) > -1) return
                  let textLength = i.processedName.length
                  let targetCharacterIds = []
                  let text = item.characterSentences[idx]
                  if (text.characterSentenceId && textIds.indexOf(text.characterSentenceId) > -1) return
                  for (let a = 0; a < textLength; a++) {
                    if (item.characterSentences[idx + a]) {
                      targetCharacterIds.push(item.characterSentences[idx + a].characterSentenceId)
                    }
                    if (
                      targetCharacterIds.some(
                        targetId =>
                          item.characterSentences.find(findT => findT.characterSentenceId == targetId).phonetic &&
                          item.characterSentences.find(findT => findT.characterSentenceId == targetId).phonetic
                            .phoneticText
                      )
                    ) {
                      return
                    }
                    if (a) {
                      if (item.characterSentences[idx + a]) {
                        item.characterSentences[idx + a].phonetic = null
                      }
                    }
                  }
                  text.phonetic = {
                    fontSize: (text.fontSize - 1) / 2 + 1,
                    letterSpacing: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0,
                    scaleY: this.phoneticStyle.scaleY,
                    scaleX: this.phoneticStyle.scaleX,
                    lineSpacing: this.phoneticStyle.lineSpacing || 1.15,
                    textAlign: this.phoneticStyle.textAlign,
                    fontColor: this.phoneticStyle.fontColor,
                    phoneticText: i.phoneticText,
                    targetCharacterIds: targetCharacterIds,
                    letterSpacingOption: this.phoneticStyle.scaleX
                      ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0
                      : 0
                  }
                  if (item.textLayout == 2) {
                    if (i.phoneticText.length < 3) {
                      text.phonetic.scaleY = 1
                      text.phonetic.letterSpacing = 0
                    } else if (i.phoneticText.length == 3) {
                      text.phonetic.scaleY = 0.7
                    } else {
                      text.phonetic.scaleY = 0.6
                      text.phonetic.letterSpacing = 0
                    }
                  } else {
                    if (i.phoneticText.length < 3) {
                      text.phonetic.scaleX = 1
                      text.phonetic.letterSpacing = 0
                    } else if (i.phoneticText.length == 3) {
                      text.phonetic.scaleX = 0.7
                    } else {
                      text.phonetic.scaleX = 0.6
                      text.phonetic.letterSpacing = 0
                    }
                  }
                  textIds = [...textIds, ...targetCharacterIds]
                  pushArr.push(idx)
                })
              }
            }
          }) */
          this.$forceUpdate()
        } else {
          this.$message.error(res.msg || res.errorMsg)
        }
      }
    },
    addYahooPhotic(item, word) {
      if (this.checkHasPhonetic(item, word)) {
        item.characterSentences.forEach((text, tIdx) => {
          if (text.characterText == word.surface[0]) {
            let targetCharacterIds = [text.characterSentenceId]
            let checkTargetId = false
            if (word.surface.length > 1) {
              word.surface.split('').forEach((w, wIdx) => {
                if (
                  wIdx &&
                  item.characterSentences[tIdx + wIdx] &&
                  item.characterSentences[tIdx + wIdx].characterText == w
                ) {
                  item.characterSentences[tIdx + wIdx].phonetic = null
                  targetCharacterIds.push(item.characterSentences[tIdx + wIdx].characterSentenceId)
                }
              })
            } else {
              item.characterSentences.forEach(t => {
                if (
                  t.phonetic &&
                  t.phonetic.targetCharacterIds.some(targetId => targetId == text.characterSentenceId)
                ) {
                  if (t.phonetic.targetCharacterIds.findIndex(id => id == text.characterSentenceId) > 0) {
                    checkTargetId = true
                  }
                }
              })
            }
            if (checkTargetId) {
              this.$message.error('检测到当前被注音文本已有注音，请注意检查')
              return
            }
            if (targetCharacterIds.length != word.surface.length) return
            text.phonetic = {
              fontSize: (text.fontSize - 1) / 2 + 1,
              letterSpacing: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0,
              scaleY: this.phoneticStyle.scaleY,
              scaleX: this.phoneticStyle.scaleX,
              lineSpacing: this.phoneticStyle.lineSpacing || 1.15,
              textAlign: this.phoneticStyle.textAlign,
              fontColor: this.phoneticStyle.fontColor,
              phoneticText: word.furigana,
              targetCharacterIds: targetCharacterIds,
              letterSpacingOption: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0
            }
            if (item.textLayout == 2) {
              if (word.furigana.length < 3) {
                text.phonetic.scaleY = 1
                text.phonetic.letterSpacing = 0
              } else if (word.furigana.length == 3) {
                text.phonetic.scaleY = 0.7
              } else {
                text.phonetic.scaleY = 0.6
                text.phonetic.letterSpacing = 0
              }
            } else {
              if (word.furigana.length < 3) {
                text.phonetic.scaleX = 1
                text.phonetic.letterSpacing = 0
              } else if (word.furigana.length == 3) {
                text.phonetic.scaleX = 0.7
              } else {
                text.phonetic.scaleX = 0.6
                text.phonetic.letterSpacing = 0
              }
            }
          }
        })
      } else {
        item.characterSentences.forEach((text, tIdx) => {
          if (text.characterText == word.surface[0] && text.phonetic && text.phonetic.phoneticText == word.furigana) {
            text.phonetic = null
          }
        })
      }
      item.pIndexs = []
      item.characterSentences.forEach((t, pushTtextIndex) => {
        if (
          item.characterSentences[pushTtextIndex + 1] &&
          item.characterSentences[pushTtextIndex + 1].phonetic &&
          item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
        ) {
          item.pIndexs.push(pushTtextIndex)
        }
      })
      item.showTexts = []
      this.$nextTick(() => {
        this.changeShowText(item)
      })
      this.$emit('copyData')
      this.$emit('savePageList')
    },
    addAllYahooPhotic(item) {
      if (this.checkHasAllPhonetic(item)) {
        this.checkPhoneticList.forEach(word => {
          item.characterSentences.forEach((text, tIdx) => {
            if (text.characterText == word.surface[0]) {
              let checkTargetId = false
              let targetCharacterIds = [text.characterSentenceId]
              if (word.surface.length > 1) {
                word.surface.split('').forEach((w, wIdx) => {
                  if (
                    wIdx &&
                    item.characterSentences[tIdx + wIdx] &&
                    item.characterSentences[tIdx + wIdx].characterText == w
                  ) {
                    item.characterSentences[tIdx + wIdx].phonetic = null
                    targetCharacterIds.push(item.characterSentences[tIdx + wIdx].characterSentenceId)
                  }
                })
              } else {
                item.characterSentences.forEach(t => {
                  if (t.phonetic && t.phonetic.targetCharacterIds.some(id => id == text.characterSentenceId)) {
                    if (t.phonetic.targetCharacterIds.findIndex(id => id == text.characterSentenceId) > 0) {
                      checkTargetId = true
                    }
                  }
                })
              }
              if (checkTargetId) {
                console.log(targetCharacterIds, text, item.characterSentences)
                this.$message.error('检测到当前被注音文本已有注音，请注意检查')
                return
              }
              if (targetCharacterIds.length != word.surface.length) return
              text.phonetic = {
                fontSize: (text.fontSize - 1) / 2 + 1,
                letterSpacing: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0,
                scaleY: this.phoneticStyle.scaleY,
                scaleX: this.phoneticStyle.scaleX,
                lineSpacing: this.phoneticStyle.lineSpacing || 1.15,
                textAlign: this.phoneticStyle.textAlign,
                fontColor: this.phoneticStyle.fontColor,
                phoneticText: word.furigana,
                targetCharacterIds: targetCharacterIds,
                letterSpacingOption: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0
              }
              if (item.textLayout == 2) {
                if (word.furigana.length < 3) {
                  text.phonetic.scaleY = 1
                  text.phonetic.letterSpacing = 0
                } else if (word.furigana.length == 3) {
                  text.phonetic.scaleY = 0.7
                } else {
                  text.phonetic.scaleY = 0.6
                  text.phonetic.letterSpacing = 0
                }
              } else {
                if (word.furigana.length < 3) {
                  text.phonetic.scaleX = 1
                  text.phonetic.letterSpacing = 0
                } else if (word.furigana.length == 3) {
                  text.phonetic.scaleX = 0.7
                } else {
                  text.phonetic.scaleX = 0.6
                  text.phonetic.letterSpacing = 0
                }
              }
            }
          })
        })
      } else {
        this.checkPhoneticList.forEach(word => {
          item.characterSentences.forEach((text, tIdx) => {
            if (text.characterText == word.surface[0] && text.phonetic && text.phonetic.phoneticText == word.furigana) {
              text.phonetic = null
            }
          })
        })
      }
      item.pIndexs = []
      item.brIndexs = []
      item.characterSentences.forEach((t, pushTtextIndex) => {
        if (
          item.characterSentences[pushTtextIndex + 1] &&
          item.characterSentences[pushTtextIndex + 1].phonetic &&
          item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
        ) {
          item.pIndexs.push(pushTtextIndex)
        }
      })
      item.showTexts = []
      this.$nextTick(() => {
        this.changeShowText(item)
      })
      this.$emit('copyData')
      this.$emit('savePageList')
    },
    checkHasAllPhonetic(item) {
      let isAdd = true
      this.checkPhoneticList.forEach(word => {
        if (
          item.showText.indexOf(word.surface) > -1 &&
          item.characterSentences[item.showText.indexOf(word.surface)] &&
          item.characterSentences[item.showText.indexOf(word.surface)].characterText == word.surface[0] &&
          item.characterSentences[item.showText.indexOf(word.surface)].phonetic &&
          item.characterSentences[item.showText.indexOf(word.surface)].phonetic.phoneticText &&
          item.characterSentences[item.showText.indexOf(word.surface)].phonetic.phoneticText == word.furigana
        ) {
          isAdd = false
        }
      })
      return isAdd
    },
    checkHasPhonetic(item, word) {
      if (
        item.showText.indexOf(word.surface) > -1 &&
        item.characterSentences[item.showText.indexOf(word.surface)] &&
        item.characterSentences[item.showText.indexOf(word.surface)].characterText == word.surface[0] &&
        item.characterSentences[item.showText.indexOf(word.surface)].phonetic &&
        item.characterSentences[item.showText.indexOf(word.surface)].phonetic.phoneticText &&
        item.characterSentences[item.showText.indexOf(word.surface)].phonetic.phoneticText == word.furigana
      ) {
        return false
      } else {
        return true
      }
    },
    addTableNote(item, word) {
      if (this.getAllTextIndex(item.showText, word.value).length) {
        let addNum = 0
        this.getAllTextIndex(item.showText, word.value).forEach(idx => {
          word.target.split('').forEach((w, wIdx) => {
            if (item.characterSentences[idx + wIdx + addNum]) {
              if (item.characterSentences[idx + wIdx + addNum].characterText == word.value[wIdx]) {
                item.characterSentences[idx + wIdx + addNum].characterText = w
              } else {
                item.characterSentences.splice(idx + wIdx + addNum, 0, {
                  ...this.oldTextBatchStyle,
                  textAlign: this.textBatchStyle.textAlign,
                  fontFamily: this.textBatchStyle.fontFamily,
                  characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                  characterText: w
                })
                addNum++
              }
            } else {
              if (idx + wIdx + addNum == item.characterSentences.length) {
                item.characterSentences.push({
                  ...this.oldTextBatchStyle,
                  textAlign: this.textBatchStyle.textAlign,
                  fontFamily: this.textBatchStyle.fontFamily,
                  characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                  characterText: w
                })
                addNum++
              }
            }
          })
        })
        if (word.target.length < word.value.length) {
          let num = word.value.length - word.target.length
          let arr = this.getAllTextIndex(item.showText, word.value)
          for (let i = 0; i < num; i++) {
            if (item.characterSentences[arr[arr.length - 1] + word.target.length]) {
              item.characterSentences.splice(arr[arr.length - 1] + word.target.length, 1)
            }
          }
        }
        item.showTexts = []
        this.changeShowText(item)
        this.isCheckText = false
        this.$emit('topCenterMouseover')
        this.$emit('copyData')
        this.$emit('savePageList')
        this.$nextTick(() => {
          this.$emit('changeAnchorNodeBefore')
        })
      }
    },
    getLineMaskStyle() {
      let style =
        'width:' +
        this.mask_line_width +
        'px;left:' +
        this.mask_line_left +
        'px;height:' +
        this.mask_line_height +
        'px;top:' +
        this.mask_line_top +
        'px;'
      if (this.start_x > this.end_x && this.end_y > this.start_y) {
        style =
          style +
          'background:linear-gradient(to bottom right,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) calc(50% - 4px),red 50%,rgba(0, 0, 0, 0) calc(50% + 4px),rgba(0, 0, 0, 0) 100%);'
      } else if (this.start_x > this.end_x && this.start_y > this.end_y) {
        style =
          style +
          'background:linear-gradient(to top right,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) calc(50% - 4px),red 50%,rgba(0, 0, 0, 0) calc(50% + 4px),rgba(0, 0, 0, 0) 100%);'
      } else if (this.end_x > this.start_x && this.start_y > this.end_y) {
        style =
          style +
          'background:linear-gradient(to top left,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) calc(50% - 4px),red 50%,rgba(0, 0, 0, 0) calc(50% + 4px),rgba(0, 0, 0, 0) 100%);'
      } else if (this.end_x > this.start_x && this.end_y > this.start_y) {
        style =
          style +
          'background:linear-gradient(to bottom left,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) calc(50% - 4px),red 50%,rgba(0, 0, 0, 0) calc(50% + 4px),rgba(0, 0, 0, 0) 100%);'
      }
      if (Math.abs(this.end_x - this.start_x).toFixed(0) > -2 && Math.abs(this.end_x - this.start_x).toFixed(0) < 2) {
        style = style + 'border:1px solid red'
      }
      if (Math.abs(this.end_y - this.start_y).toFixed(0) > -2 && Math.abs(this.end_y - this.start_y).toFixed(0) < 2) {
        style = style + 'border:1px solid red'
      }
      return style
    },
    getLineViewStyle(start_x, end_x, start_y, end_y) {
      let style = 'width:100%;height:100%;'
      if (start_x > end_x && end_y > start_y) {
        style =
          style +
          'background:linear-gradient(to bottom right,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) calc(50% - 2px),red 50%,rgba(0, 0, 0, 0) calc(50% + 2px),rgba(0, 0, 0, 0) 100%);'
      } else if (start_x > end_x && start_y > end_y) {
        style =
          style +
          'background:linear-gradient(to top right,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) calc(50% - 2px),red 50%,rgba(0, 0, 0, 0) calc(50% + 2px),rgba(0, 0, 0, 0) 100%);'
      } else if (end_x > start_x && start_y > end_y) {
        style =
          style +
          'background:linear-gradient(to top left,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) calc(50% - 2px),red 50%,rgba(0, 0, 0, 0) calc(50% + 2px),rgba(0, 0, 0, 0) 100%);'
      } else if (end_x > start_x && end_y > start_y) {
        style =
          style +
          'background:linear-gradient(to bottom left,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) calc(50% - 2px),red 50%,rgba(0, 0, 0, 0) calc(50% + 2px),rgba(0, 0, 0, 0) 100%);'
      }
      if (Math.abs(end_x - start_x).toFixed(0) > -2 && Math.abs(end_x - start_x).toFixed(0) < 2) {
        style = style + 'border:1px solid red'
      }
      if (Math.abs(end_y - start_y).toFixed(0) > -2 && Math.abs(end_y - start_y).toFixed(0) < 2) {
        style = style + 'border:1px solid red'
      }
      return style
    },
    getPageLineClass(start_x, end_x, start_y, end_y) {
      let className = 'ocr-show-page-line'
      if (start_x > end_x && end_y > start_y) {
        className = 'ocr-show-page-line-show-bl ocr-show-page-line-show-tr'
      } else if (start_x > end_x && start_y > end_y) {
        className = 'ocr-show-page-line-show-tl ocr-show-page-line-show-br'
      } else if (end_x > start_x && start_y > end_y) {
        className = 'ocr-show-page-line-show-br ocr-show-page-line-show-tl'
      } else if (end_x > start_x && end_y > start_y) {
        className = 'ocr-show-page-line-show-br ocr-show-page-line-show-tl'
      }
      return className
    },
    getIsAddTableNote(item, word) {
      let indexs = this.getAllTextIndex(item.showText, word.value)
      let isAdd = false
      if (indexs.length) {
        isAdd = true
      }
      /*       indexs.forEach(idx => {
        if (item.characterSentences[idx]) {
          if (
            item.characterSentences[idx].showText
          ) {

          }
        }
      }) */
      return isAdd
    },
    getCursortPosition(node) {
      var range = window.getSelection().getRangeAt(0),
        preCaretRange = range.cloneRange(),
        innerText = '',
        tmp = document.createElement('div')

      preCaretRange.selectNodeContents(node)

      preCaretRange.setEnd(range.endContainer, range.endOffset)

      tmp.appendChild(preCaretRange.cloneContents())
      tmp.childNodes.forEach((p, pIdx) => {
        if (p.innerText == ' ') {
          p.innerText = ''
        }
        if (pIdx) {
          innerText = '\n' + innerText
        }
        innerText = innerText + p.innerText
      })
      tmp = null
      return innerText
    },
    imgRightKeyDown(e) {
      if ((e.ctrlKey || e.metaKey) && (e.key == 'v' || e.key == 'V')) {
      } else {
        e.target.innerText = ''
      }
    },
    focusDiv() {
      if (this.selectionTexts.length) return
      document.getElementsByClassName('copy-img-div')[0].focus()
    },
    blurDiv() {
      if (this.selectionTexts.length) return
      document.getElementsByClassName('copy-img-div')[0].blur()
    },
    imgRightPaste(event) {
      event.preventDefault()
      if (this.isSaved) {
        return
      }
      const clipboardData = event.clipboardData || window.clipboardData
      if (clipboardData.types.includes('Files')) {
        const files = Array.from(clipboardData.files)
        if (files && files.length) {
          if (files.some(file => (file.size / 1024 / 1024).toFixed(2) > 3)) {
            this.$message.error('无法粘贴大于3M文件')
          } else {
            files.forEach(file => {
              this.uploadCopyImg(file)
            })
          }
        }
      }
    },
    uploadCopyImg(file) {
      this.$emit('startCopyImg')
      this.tokenAxios({
        url: '/oss',
        method: 'get'
      }).then(async res => {
        let policy = {
          policy: res.data.policy,
          signature: res.data.signature,
          ossaccessKeyId: res.data.accessid,
          dir: res.data.dir,
          host: res.data.host,
          key: `${res.data.dir}glxt/scyg/psd/${this.userInfo.realname}/translate/${file.name
            .replaceAll('+', '加')
            .replaceAll(' ', '')}/${this.getGuid()}`
        }
        this.ossUploadData = policy
        this.ossFilePolicy[file.name] = policy
        let formData = new FormData()
        for (let key in this.ossUploadData) {
          formData.append(key, this.ossUploadData[key])
        }
        formData.append('file', file)
        let that = this
        postAction(policy.host, formData).then(res => {
          console.log('成功OSS', policy.host, formData)
          Object.assign(file, { status: 'done' })
          let params = this.getSaveFileParams(file)
          var img = new Image()
          img.src = params.filePath
          img.onload = async function() {
            that.$emit('addCopyImg', { path: params.filePath, imgWidth: this.width, imgHeight: this.height })
          }
        })
      })
    },
    getCaretPosition(node) {
      var range = window.getSelection().getRangeAt(0),
        preCaretRange = range.cloneRange(),
        caretPosition,
        tmp = document.createElement('div')

      preCaretRange.selectNodeContents(node)

      preCaretRange.setEnd(range.endContainer, range.endOffset)

      tmp.appendChild(preCaretRange.cloneContents())
      tmp.childNodes.forEach((p, pIdx) => {
        if (p.innerText == ' ' && pIdx != tmp.childNodes.length - 1) {
          p.innerText = ''
        }
      })
      caretPosition = tmp.innerText.length + tmp.childNodes.length - 2
      if (tmp.childNodes[tmp.childNodes.length - 1] && tmp.childNodes[tmp.childNodes.length - 1].innerText == ' ') {
        caretPosition--
      }
      return caretPosition
    },
    handleCopy(e, item) {
      this.copyContent = window.getSelection().toString()
      this.copyContent = this.copyContent.replaceAll('\n\n', '\n')
      this.copyContent = this.copyContent.replaceAll('\n ', '\n')
      e.clipboardData.setData('text/plain', this.copyContent)
      e.preventDefault()
    },
    handlePaste(e, item) {
      this.inputValue = e.clipboardData.getData('text')
      this.inputValue = this.inputValue.replaceAll('\r\n\r\n', '\n')
      this.inputValue = this.inputValue.replaceAll('\r\n', '\n')
      this.inputValue = this.inputValue.replaceAll('\n ', '\n')
      if (!this.inputValue) return
      e.preventDefault()
      if (this.selectionTexts.length) {
        let addText = this.inputValue
        this.textChangeSim(e, item, this.startIdx, this.endIdx, addText)
      } else {
        let startIdx = this.getCaretPosition(e.currentTarget)
        if (startIdx == -2) {
          startIdx = -1
        }
        let endIdx = startIdx
        let addText = this.inputValue
        this.textChangeSim(e, item, startIdx, endIdx, addText)
      }
      this.inputValue = ''
    },
    getAllTextIndex(str, searchStr) {
      let matches = str.matchAll(new RegExp(searchStr, 'g'))
      let indices = []

      for (let match of matches) {
        indices.push(match.index)
      }
      return indices
    },
    batchDeleteTextArea() {
      let that = this
      /*       if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (!this.changeAll) return
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && this.taskDetail.rejectTaskId) return
      if (!this.checkPermission('addTextArea:dele')) return
      this.$confirm({
        title: '确认删除?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        onOk() {
          that.$emit('batchDeleteTextArea')
          /*           that.isDelete = true
          page.textAreas.splice(
            page.textAreas.findIndex(i => i.id == item.id),
            1
          ) */
          /* that.$emit('delePageList', [item.id]) */
        },
        onCancel() {
          /* console.log('Cancel') */
        }
      })
    },
    async rejectEnter(e, rejectItem, item) {
      //禁止回车的默认换行
      if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea) //获取光标位置
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          rejectItem.comment = textarea.value
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          rejectItem.comment = textarea.value
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
        //增加换行符
      } else if (e.keyCode == 13) {
        e.preventDefault()
        if (this.taskDetail.stageName != '校对' && this.taskDetail.stageName != '终审') {
          this.$message.error('当前阶段无法驳回')
          return
        }
        if (!rejectItem.comment) {
          this.$message.error('内容不能为空')
          return
        }
        let params = {
          comment: rejectItem.comment,
          stageName: this.taskDetail.stageName,
          textAreaRejectId: rejectItem.textAreaRejectId,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar,
          id: rejectItem.id
        }
        const res = await v1postAction('/textarea/reject_msg/edit', params)
        if (res.code == 200) {
          this.$message.success('编辑成功')
          this.getRevisionList(item)
          this.$emit('getRevisionList')
        } else {
          this.$message.error(res.msg || res.errorMsg)
        }
      }
    },
    deleteLocal(page, aIdx) {
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      let that = this
      const doms = Array.from(document.getElementsByClassName('local-content-list'))
      let imgs = null
      if (doms && doms.length) {
        let imgDoms = []
        doms.forEach(dom => {
          imgDoms = [...imgDoms, ...Array.from(dom.getElementsByTagName('IMG'))]
        })
        if (imgDoms.length) {
          imgs = imgDoms.map(e => e.src)
        }
      }
      this.$confirm({
        title: '确认删除?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        async onOk() {
          if (page.annotationList[aIdx] && page.annotationList[aIdx].id) {
            let id = page.annotationList[aIdx].id
            that.isDelete = true
            page.annotationList.splice(aIdx, 1)
            const res = await deleteAction('/annotation/delete/' + id)
            if (res.code == 200) {
              that.$message.success('删除成功')
              that.$emit('getLocalList')
              that.$emit('closeLocal', imgs)
            } else {
              that.$message.error(res.msg || res.errorMsg)
            }
          } else {
            that.isDelete = true
            page.annotationList.splice(aIdx, 1)
            that.$message.success('删除成功')
            that.$emit('closeLocal', imgs)
          }
        },
        onCancel() {
          /* console.log('Cancel') */
        }
      })
    },
    editLocalContent(i) {
      i.editble = 1
      this.$forceUpdate()
    },
    deleteTextArea(page, item) {
      if (item.id && typeof item.id != 'number' && item.id.indexOf('new') > -1) {
        this.$message.error('未获取到文本框ID，请重试')
        return
      }
      if (!this.changeAll) return
      /*       if (this.isRejectStatus && !item.isReject) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (!this.checkPermission('addTextArea:dele')) return
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
      let that = this
      this.$confirm({
        title: '确认删除?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        onOk() {
          that.isDelete = true
          page.textAreas.splice(
            page.textAreas.findIndex(i => i.id == item.id),
            1
          )
          that.$emit('delePageList', [item.id])
        },
        onCancel() {
          /* console.log('Cancel') */
        }
      })
    },
    textBlur(item, ciickType, page) {
      /*       this.errorValue = undefined
      this.selectErrorType = 0
      this.errorValueBottom = undefined
      this.selectErrorTypeBottom = 0
      window.getSelection().removeAllRanges() */
      item.translateTextAreaRegion.editble = 0
      this.isChangeFontSize = false
      this.$emit('clearAnchorNode')
      if (this.isCenterClick) return
      this.$emit('copyData')
      this.$emit('savePageList')
    },
    getDragTextBox() {
      this.$nextTick(() => {
        const dom_box = this.$refs.dragTextBox
        if (dom_box) {
          this.box_screen_left = dom_box.getBoundingClientRect().left
          this.box_screen_top = 153
          this.leftWidth = dom_box.getClientRects()[0].x + dom_box.getClientRects()[0].width
        }
        const dom_line_box = this.$refs.dragLineBox
        if (dom_box) {
          this.box_screen_line_left = dom_line_box.getBoundingClientRect().left
          this.box_screen_top = 153
        }
      })
    },
    /*     getCursortPosition(element) {
      var caretOffset = 0
      var doc = element.ownerDocument || element.document
      var win = doc.defaultView || doc.parentWindow
      var sel
      if (typeof win.getSelection != 'undefined') {
        //谷歌、火狐
        sel = win.getSelection()
        if (sel.rangeCount > 0) {
          //选中的区域
          var range = win.getSelection().getRangeAt(0)
          var preCaretRange = range.cloneRange() //克隆一个选中区域
          preCaretRange.selectNodeContents(element) //设置选中区域的节点内容为当前节点
          preCaretRange.setEnd(range.endContainer, range.endOffset) //重置选中区域的结束位置
          caretOffset = preCaretRange.toString()
        }
      } else if ((sel = doc.selection) && sel.type != 'Control') {
        //IE
        var textRange = sel.createRange()
        var preCaretTextRange = doc.body.createTextRange()
        preCaretTextRange.moveToElementText(element)
        preCaretTextRange.setEndPoint('EndToEnd', textRange)
        caretOffset = preCaretTextRange.text.length
      }
      return caretOffset
    }, */
    getTextIndex(allText, beforeText, afterText, index) {
      if (allText.indexOf(afterText) > -1 && index < beforeText.length) {
        let text = allText.substring(allText.indexOf(afterText), allText.length)
        index = index + (allText.indexOf(afterText) || 1)
        return this.getTextIndex(text, beforeText, afterText, index)
      } else {
        return index
      }
    },
    async textChange(e, item) {
      if (this.isWindows) {
        if (e.ctrlKey) return
      } else {
        if (e.metaKey) return
      }
      if (e.key == 'Backspace' || e.key == 'Delete') {
        /*         if (item.characterSentences.length == 1) {
          e.target.innerText = ''
        } */
        if (this.inputStart) return
        if (item.characterSentences.length == 1 && window.getSelection().anchorNode.tagName == 'P') {
          if (
            !window.getSelection().anchorNode.childNodes.length ||
            window.getSelection().anchorNode.childNodes[0].tagName == 'BR'
          ) {
            let arr = JSON.parse(JSON.stringify(item.characterSentences))
            item.characterSentences = [
              {
                ...this.oldTextBatchStyle,
                textAlign: this.textBatchStyle.textAlign,
                fontFamily: this.textBatchStyle.fontFamily,
                isBold: this.textBatchStyle.isBold ? 1 : 0,
                characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                characterText: ''
              }
            ]
            item.showTexts = []
            e.target.innerText = ''
            this.$nextTick(() => {
              this.changeShowText(item)
              this.getYahooOrKdxf(item.showText, item.pageId, item.id, item)
            })
            return
          }
        }
        let textIds = []
        e.target.childNodes.forEach((p, idx) => {
          if (idx && p.id) {
            textIds.push(p.id.split('-')[2])
          }
          p.childNodes.forEach(child => {
            let id = child.id.split('-')[2]
            let char = item.characterSentences.find(c => c.characterSentenceId == id)
            if (!child.innerText && child.tagName != 'BR') return
            if ((char && !char.phonetic && !char.hasMark) || child.tagName == 'BR') {
              if (id) textIds.push(id)
            } else {
              if (char && char.characterText && child.innerText.indexOf(char.characterText) > -1) {
                if (id) textIds.push(id)
              }
            }
            if (child.innerText && !child.id && child.className) {
              let id = window.getSelection().anchorNode.parentNode.id.split('-')[0]
              let index = item.characterSentences.findIndex(c => c.characterSentenceId == id)
              if (item.characterSentences[index + 2]) {
                textIds.push(item.characterSentences[index + 2].characterSentenceId)
              }
            }
          })
        })
        if (
          e.target.childNodes.length == 1 &&
          e.target.childNodes[0].childNodes.length == 1 &&
          e.target.childNodes[0].childNodes[0].tagName == 'SPAN' &&
          !e.target.childNodes[0].childNodes[0].id
        ) {
          textIds = [item.characterSentences[item.characterSentences.length - 1].characterSentenceId]
        }
        let backSelectNode = window.getSelection().anchorNode.parentNode
        let childNodes =
          backSelectNode.parentNode.parentNode.parentNode.tagName == 'P'
            ? backSelectNode.parentNode.parentNode.parentNode.childNodes
              ? Array.from(backSelectNode.parentNode.parentNode.parentNode.childNodes)
              : []
            : []

        if (
          backSelectNode.tagName == 'SPAN' &&
          backSelectNode.id.indexOf('text') > -1 &&
          (backSelectNode.innerText == '' ||
            backSelectNode.innerText == ' ' ||
            !childNodes[childNodes.length - 1].id) &&
          item.characterSentences.every(t => t.characterSentenceId != backSelectNode.id)
        ) {
          item.showTexts.forEach(p => {
            if (
              textIds.every(
                tId =>
                  ((p.pId + '').split('-').length > 1 || (p.pId + '').indexOf('newP') == -1) &&
                  tId != (p.pId + '').split('-')[0]
              )
            ) {
              textIds = [...textIds, p.texts[p.texts.length - 1].characterSentenceId]
            }
          })
        }
        /* if (textIds.length && (textIds[textIds.length - 1] + '').indexOf('newP') > -1) {
          let texts = item.showTexts.find(p => (p.pId + '').indexOf(textIds[textIds.length - 1]) > -1).texts
          textIds = [...textIds, texts[texts.length - 1].characterSentenceId]
        } */
        let deleArr = []
        let spaceNum = 0
        if (
          item.characterSentences.length &&
          item.characterSentences.length > 1 &&
          item.characterSentences[0].characterText == '<br>' &&
          item.characterSentences[1] &&
          item.characterSentences[1].characterText == ' '
        ) {
          spaceNum = 1
        }
        item.characterSentences.forEach((c, cIdx) => {
          if (textIds.every(tId => tId != c.characterSentenceId)) {
            deleArr.push(cIdx)
          }
        })
        if (deleArr.length) {
          if (item.characterSentences.length > 1) {
            /* console.log(item.characterSentences[deleArr[0]], item.characterSentences[deleArr[0] - 1])
            debugger */
            deleArr.forEach((deleIdx, index) => {
              item.characterSentences.forEach(text => {
                if (text.phonetic && text.phonetic.targetCharacterIds && text.phonetic.targetCharacterIds.length > 1) {
                  if (
                    text.phonetic.targetCharacterIds.some(
                      targetId =>
                        targetId == item.characterSentences[deleIdx] &&
                        item.characterSentences[deleIdx].characterSentenceId
                    )
                  ) {
                    text.phonetic = null
                  }
                }
              })
              if (
                item.characterSentences[deleIdx] &&
                item.characterSentences[deleIdx].characterText == '<br>' &&
                item.characterSentences[deleIdx + 1] &&
                item.characterSentences[deleIdx + 1].characterText == ' '
              ) {
                item.characterSentences.splice(deleIdx - index + 1, 1)
              }
              let num = 0
              if (
                item.characterSentences[deleIdx] &&
                item.characterSentences[deleIdx].characterText == ' ' &&
                item.characterSentences[deleIdx - 1] &&
                item.characterSentences[deleIdx - 1].characterText == '<br>'
              ) {
                num = 1
                item.characterSentences.splice(deleIdx - index - 1, 1)
              }
              item.characterSentences.splice(deleIdx - index - num, 1)
            })
          } else {
            item.characterSentences[0].characterText = ''
          }
        } else {
          if (
            item.characterSentences.length &&
            item.characterSentences[item.characterSentences.length - 1] &&
            item.characterSentences[item.characterSentences.length - 1].characterText == '<br>'
          ) {
            item.characterSentences.push({
              ...this.oldTextBatchStyle,
              textAlign: this.textBatchStyle.textAlign,
              fontFamily: this.textBatchStyle.fontFamily,
              isBold: this.textBatchStyle.isBold ? 1 : 0,
              characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
              characterText: ' '
            })
            item.characterSentences.forEach((c, cIdx) => {
              if (c.characterText == '') {
                item.characterSentences.splice(cIdx, 1)
              }
            })
            item.showText = item.characterSentences
              .map(c => c.characterText)
              .join('')
              .replaceAll('<br>', '\n')
            let arr = JSON.parse(JSON.stringify(item.characterSentences))
            item.characterSentences = []
            item.showTexts = []
            e.target.innerText = ''
            this.$nextTick(() => {
              item.characterSentences = arr
              let pArr = []
              let brIds = []
              item.brIndexs = []
              item.pIndexs = []
              item.characterSentences?.forEach((t, pushTtextIndex) => {
                if (
                  item.characterSentences[pushTtextIndex + 1] &&
                  item.characterSentences[pushTtextIndex + 1].phonetic &&
                  item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                ) {
                  item.pIndexs.push(pushTtextIndex)
                }
                if (t.characterText != '<br>' && t.characterText != '\n') {
                  pArr.push(t)
                } else {
                  brIds.push(t.characterSentenceId)
                  item.brIndexs.push(pushTtextIndex)
                  if (pArr.length > 1) {
                    /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                  }
                  if (!pArr.length) {
                    pArr.push({
                      ...this.oldTextBatchStyle,
                      textAlign: this.textBatchStyle.textAlign,
                      fontFamily: this.textBatchStyle.fontFamily,
                      isBold: this.textBatchStyle.isBold ? 1 : 0,
                      characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                      characterText: ' '
                    })
                  }
                  item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  pArr = []
                }
                if (pushTtextIndex == item.characterSentences.length - 1) {
                  if (!pArr.length) {
                    pArr.push({
                      ...this.oldTextBatchStyle,
                      textAlign: this.textBatchStyle.textAlign,
                      fontFamily: this.textBatchStyle.fontFamily,
                      isBold: this.textBatchStyle.isBold ? 1 : 0,
                      characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                      characterText: ' '
                    })
                  }
                  item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                }
              })
              brIds.forEach((brId, brPushIdx) => {
                if (item.showTexts[brPushIdx + 1]) {
                  item.showTexts[brPushIdx + 1].pId =
                    brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                }
              })
              item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
              let selectIdx = -1
              if (this.startIdx >= 0 && this.endIdx >= 0) {
                if (this.startIdx > this.endIdx) {
                  selectIdx = this.endIdx
                } else {
                  selectIdx = this.startIdx
                }
              }
              if (this.startIdx == -1 && deleArr.length) {
                selectIdx = deleArr[0] + 1
              }
              this.$nextTick(() => {
                let nodes = []
                e.target.childNodes.forEach(p => {
                  p.childNodes.forEach(d => {
                    nodes.push(d)
                  })
                })
                if (selectIdx < nodes.length) {
                  window
                    .getSelection()
                    .getRangeAt(0)
                    .setStart(nodes[selectIdx - 1 >= 0 ? selectIdx - 1 : 0], selectIdx - 1 >= 0 ? selectIdx - 1 : 0)
                } else {
                  window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                  window.getSelection().collapseToEnd()
                }
              })
            })
          }
          return
        }
        if (
          item.characterSentences.length &&
          item.characterSentences[item.characterSentences.length - 1] &&
          item.characterSentences[item.characterSentences.length - 1].characterText == '<br>'
        ) {
          item.characterSentences.push({
            ...this.oldTextBatchStyle,
            textAlign: this.textBatchStyle.textAlign,
            fontFamily: this.textBatchStyle.fontFamily,
            isBold: this.textBatchStyle.isBold ? 1 : 0,
            characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
            characterText: ' '
          })
          item.characterSentences.forEach((c, cIdx) => {
            if (c.characterText == '') {
              item.characterSentences.splice(cIdx, 1)
            }
          })
          item.showText = item.characterSentences
            .map(c => c.characterText)
            .join('')
            .replaceAll('<br>', '\n')
          let arr = JSON.parse(JSON.stringify(item.characterSentences))
          item.characterSentences = []
          item.showTexts = []
          e.target.innerText = ''
          this.$nextTick(() => {
            item.characterSentences = arr
            let pArr = []
            let brIds = []
            item.brIndexs = []
            item.pIndexs = []
            item.characterSentences?.forEach((t, pushTtextIndex) => {
              if (
                item.characterSentences[pushTtextIndex + 1] &&
                item.characterSentences[pushTtextIndex + 1].phonetic &&
                item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
              ) {
                item.pIndexs.push(pushTtextIndex)
              }
              if (t.characterText != '<br>' && t.characterText != '\n') {
                pArr.push(t)
              } else {
                brIds.push(t.characterSentenceId)
                item.brIndexs.push(pushTtextIndex)
                if (pArr.length > 1) {
                  /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                }
                if (!pArr.length) {
                  pArr.push({
                    ...this.oldTextBatchStyle,
                    textAlign: this.textBatchStyle.textAlign,
                    fontFamily: this.textBatchStyle.fontFamily,
                    isBold: this.textBatchStyle.isBold ? 1 : 0,
                    characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    characterText: ' '
                  })
                }
                item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                pArr = []
              }
              if (pushTtextIndex == item.characterSentences.length - 1) {
                if (!pArr.length) {
                  pArr.push({
                    ...this.oldTextBatchStyle,
                    textAlign: this.textBatchStyle.textAlign,
                    fontFamily: this.textBatchStyle.fontFamily,
                    isBold: this.textBatchStyle.isBold ? 1 : 0,
                    characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    characterText: ' '
                  })
                }
                item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
              }
            })
            brIds.forEach((brId, brPushIdx) => {
              if (item.showTexts[brPushIdx + 1]) {
                item.showTexts[brPushIdx + 1].pId =
                  brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
              }
            })
            item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
            let selectIdx = -1
            if (this.startIdx >= 0 && this.endIdx >= 0) {
              if (this.startIdx > this.endIdx) {
                selectIdx = this.endIdx
              } else {
                selectIdx = this.startIdx
              }
            }
            if (this.startIdx == -1 && deleArr.length) {
              selectIdx = deleArr[0] + 1
            }
            this.$nextTick(() => {
              window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
              window.getSelection().collapseToEnd()
              /*               console.log(selectIdx, e.target.childNodes)
              let nodes = []
              e.target.childNodes.forEach(p => {
                p.childNodes.forEach(d => {
                  nodes.push(d)
                })
              })
              if (selectIdx < nodes.length) {
                window
                  .getSelection()
                  .getRangeAt(0)
                  .setStart(nodes[selectIdx - 1 >= 0 ? selectIdx - 1 : 0], selectIdx - 1 >= 0 ? selectIdx - 1 : 0)
              } else {
                window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                window.getSelection().collapseToEnd()
              } */
            })
          })
        } else if (
          item.characterSentences.length &&
          item.characterSentences[item.characterSentences.length - 1] &&
          item.characterSentences[item.characterSentences.length - 1].characterText == ' ' &&
          item.characterSentences[item.characterSentences.length - 2] &&
          item.characterSentences[item.characterSentences.length - 2].characterText == '<br>'
        ) {
          /* item.characterSentences.pop()
          item.characterSentences.pop() */
          item.characterSentences.forEach((c, cIdx) => {
            if (c.characterText == '') {
              item.characterSentences.splice(cIdx, 1)
            }
          })
          item.showText = item.characterSentences
            .map(c => c.characterText)
            .join('')
            .replaceAll('<br>', '\n')
          let arr = JSON.parse(JSON.stringify(item.characterSentences))
          item.characterSentences = []
          e.target.innerText = ''
          item.showTexts = []
          this.$nextTick(() => {
            item.characterSentences = arr
            let pArr = []
            let brIds = []
            item.brIndexs = []
            item.pIndexs = []
            item.characterSentences?.forEach((t, pushTtextIndex) => {
              if (
                item.characterSentences[pushTtextIndex + 1] &&
                item.characterSentences[pushTtextIndex + 1].phonetic &&
                item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
              ) {
                item.pIndexs.push(pushTtextIndex)
              }
              if (t.characterText != '<br>' && t.characterText != '\n') {
                pArr.push(t)
              } else {
                brIds.push(t.characterSentenceId)
                item.brIndexs.push(pushTtextIndex)
                if (pArr.length > 1) {
                  /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                }
                if (!pArr.length) {
                  pArr.push({
                    ...this.oldTextBatchStyle,
                    textAlign: this.textBatchStyle.textAlign,
                    fontFamily: this.textBatchStyle.fontFamily,
                    isBold: this.textBatchStyle.isBold ? 1 : 0,
                    characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    characterText: ' '
                  })
                }
                item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                pArr = []
              }
              if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
              }
            })
            brIds.forEach((brId, brPushIdx) => {
              if (item.showTexts[brPushIdx + 1]) {
                item.showTexts[brPushIdx + 1].pId =
                  brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
              }
            })
            item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
            this.$nextTick(() => {
              if (deleArr.length) {
                if (item.characterSentences[deleArr[0] - 1]) {
                  var range = window.getSelection().getRangeAt(0)
                  range.selectNode(
                    document.getElementById(
                      item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0] - 1].characterSentenceId
                    )
                  )
                  range.collapse(false)
                  this.$emit(
                    'changeAnchorNode',
                    document.getElementById(
                      item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0] - 1].characterSentenceId
                    )
                  )
                } else if (item.characterSentences[deleArr[0]]) {
                  var range = window.getSelection().getRangeAt(0)
                  range.selectNode(
                    document.getElementById(
                      item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                    )
                  )
                  range.collapse(true)
                  this.$emit(
                    'changeAnchorNode',
                    document.getElementById(
                      item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                    )
                  )
                } else {
                  window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                  window.getSelection().collapseToEnd()
                }
              } else {
                window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                window.getSelection().collapseToEnd()
              }
            })
          })
        } else if (
          item.characterSentences.length &&
          item.characterSentences[item.characterSentences.length - 1] &&
          item.characterSentences[item.characterSentences.length - 1].characterText == ' ' &&
          item.characterSentences[item.characterSentences.length - 2] &&
          item.characterSentences[item.characterSentences.length - 2].characterText == '<br>' &&
          item.characterSentences[item.characterSentences.length - 3] &&
          item.characterSentences[item.characterSentences.length - 3].characterText != '<br>'
        ) {
          /* item.characterSentences.pop()
          item.characterSentences.pop() */
          item.characterSentences.forEach((c, cIdx) => {
            if (c.characterText == '') {
              item.characterSentences.splice(cIdx, 1)
            }
          })
          item.showText = item.characterSentences
            .map(c => c.characterText)
            .join('')
            .replaceAll('<br>', '\n')
          let arr = JSON.parse(JSON.stringify(item.characterSentences))
          item.characterSentences = []
          e.target.innerText = ''
          item.showTexts = []
          this.$nextTick(() => {
            item.characterSentences = arr
            let pArr = []
            let brIds = []
            item.brIndexs = []
            item.pIndexs = []
            item.characterSentences?.forEach((t, pushTtextIndex) => {
              if (
                item.characterSentences[pushTtextIndex + 1] &&
                item.characterSentences[pushTtextIndex + 1].phonetic &&
                item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
              ) {
                item.pIndexs.push(pushTtextIndex)
              }
              if (t.characterText != '<br>' && t.characterText != '\n') {
                pArr.push(t)
              } else {
                brIds.push(t.characterSentenceId)
                item.brIndexs.push(pushTtextIndex)
                if (pArr.length > 1) {
                  /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                }
                if (!pArr.length) {
                  pArr.push({
                    ...this.oldTextBatchStyle,
                    textAlign: this.textBatchStyle.textAlign,
                    fontFamily: this.textBatchStyle.fontFamily,
                    isBold: this.textBatchStyle.isBold ? 1 : 0,
                    characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    characterText: ' '
                  })
                }
                item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                pArr = []
              }
              if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
              }
            })
            brIds.forEach((brId, brPushIdx) => {
              if (item.showTexts[brPushIdx + 1]) {
                item.showTexts[brPushIdx + 1].pId =
                  brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
              }
            })
            item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
            this.$nextTick(() => {
              if (deleArr.length) {
                if (item.characterSentences[deleArr[0] - 1]) {
                  var range = window.getSelection().getRangeAt(0)
                  range.selectNode(
                    document.getElementById(
                      item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0] - 1].characterSentenceId
                    )
                  )
                  range.collapse(false)
                  this.$emit(
                    'changeAnchorNode',
                    document.getElementById(
                      item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0] - 1].characterSentenceId
                    )
                  )
                } else {
                  var range = window.getSelection().getRangeAt(0)
                  range.selectNode(
                    document.getElementById(
                      item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                    )
                  )
                  range.collapse(true)
                  this.$emit(
                    'changeAnchorNode',
                    document.getElementById(
                      item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                    )
                  )
                }
              } else {
                window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                window.getSelection().collapseToEnd()
              }
            })
          })
        } else {
          if (item.characterSentences.length) {
            item.characterSentences.forEach((c, cIdx) => {
              if (c.characterText == '') {
                item.characterSentences.splice(cIdx, 1)
              }
            })
            if (
              item.characterSentences.length &&
              item.characterSentences[0].characterText == '<br>' &&
              ((item.characterSentences[1] && item.characterSentences[1].characterText != ' ') ||
                !item.characterSentences[1])
            ) {
              item.characterSentences.unshift({
                ...this.oldTextBatchStyle,
                textAlign: this.textBatchStyle.textAlign,
                fontFamily: this.textBatchStyle.fontFamily,
                isBold: this.textBatchStyle.isBold ? 1 : 0,
                characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                characterText: ' '
              })
              item.showText = item.characterSentences
                .map(c => c.characterText)
                .join('')
                .replaceAll('<br>', '\n')
              let arr = JSON.parse(JSON.stringify(item.characterSentences))
              item.characterSentences = []
              e.target.innerText = ''
              item.showTexts = []
              this.$nextTick(() => {
                item.characterSentences = arr
                let pArr = []
                let brIds = []
                item.brIndexs = []
                item.pIndexs = []
                item.characterSentences?.forEach((t, pushTtextIndex) => {
                  if (
                    item.characterSentences[pushTtextIndex + 1] &&
                    item.characterSentences[pushTtextIndex + 1].phonetic &&
                    item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                  ) {
                    item.pIndexs.push(pushTtextIndex)
                  }
                  if (t.characterText != '<br>' && t.characterText != '\n') {
                    pArr.push(t)
                  } else {
                    brIds.push(t.characterSentenceId)
                    item.brIndexs.push(pushTtextIndex)
                    if (pArr.length > 1) {
                      /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                    }
                    if (!pArr.length) {
                      pArr.push({
                        ...this.oldTextBatchStyle,
                        textAlign: this.textBatchStyle.textAlign,
                        fontFamily: this.textBatchStyle.fontFamily,
                        isBold: this.textBatchStyle.isBold ? 1 : 0,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      })
                    }
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    pArr = []
                  }
                  if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  }
                })
                brIds.forEach((brId, brPushIdx) => {
                  if (item.showTexts[brPushIdx + 1]) {
                    item.showTexts[brPushIdx + 1].pId =
                      brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                  }
                })
                item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                this.$nextTick(() => {
                  window
                    .getSelection()
                    .getRangeAt(0)
                    .setStart(e.target.childNodes[0], 0)
                  /*                   window
                    .getSelection()
                    .getRangeAt(0)
                    .setStart(e.target.childNodes[1], 0) */
                })
              })
            } else {
              if (
                item.characterSentences.length &&
                item.characterSentences.length > 1 &&
                item.characterSentences[0].characterText == ' '
              ) {
                item.characterSentences.shift()
                spaceNum = 1
              }
              item.showText = item.characterSentences
                .map(c => c.characterText)
                .join('')
                .replaceAll('<br>', '\n')
              let pArr = []
              let brIds = []
              item.showTexts = []
              item.brIndexs = []
              item.pIndexs = []
              item.characterSentences?.forEach((t, pushTtextIndex) => {
                if (
                  item.characterSentences[pushTtextIndex + 1] &&
                  item.characterSentences[pushTtextIndex + 1].phonetic &&
                  item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                ) {
                  item.pIndexs.push(pushTtextIndex)
                }
                if (t.characterText != '<br>' && t.characterText != '\n') {
                  pArr.push(t)
                } else {
                  brIds.push(t.characterSentenceId)
                  item.brIndexs.push(pushTtextIndex)
                  if (pArr.length > 1) {
                    /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                  }
                  if (!pArr.length) {
                    pArr.push({
                      ...this.oldTextBatchStyle,
                      textAlign: this.textBatchStyle.textAlign,
                      fontFamily: this.textBatchStyle.fontFamily,
                      isBold: this.textBatchStyle.isBold ? 1 : 0,
                      characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                      characterText: ' '
                    })
                  }
                  item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  pArr = []
                }
                if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                  item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                }
              })
              brIds.forEach((brId, brPushIdx) => {
                if (item.showTexts[brPushIdx + 1]) {
                  item.showTexts[brPushIdx + 1].pId =
                    brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                }
              })
              item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
              this.$nextTick(() => {
                if (deleArr.length) {
                  if (
                    item.characterSentences[deleArr[0]] &&
                    item.characterSentences[deleArr[0] - 1] &&
                    item.characterSentences[deleArr[0] - 1].characterText != '<br>'
                  ) {
                    var range = window.getSelection().getRangeAt(0)
                    range.selectNode(
                      document.getElementById(
                        item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0] - 1].characterSentenceId
                      )
                    )
                    range.collapse(spaceNum ? true : false)
                    this.$emit(
                      'changeAnchorNode',
                      document.getElementById(
                        item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0] - 1].characterSentenceId
                      )
                    )
                  } else {
                    if (item.characterSentences[deleArr[0]]) {
                      if (
                        item.characterSentences[deleArr[0]].characterText == '<br>' &&
                        item.characterSentences[deleArr[0] - 1] &&
                        item.characterSentences[deleArr[0] - 1].characterText == '<br>'
                      ) {
                        let selectIdx = item.showTexts.findIndex(
                          p => (p.pId + '').indexOf(item.characterSentences[deleArr[0]].characterSentenceId + '') > -1
                        )
                        let selectId = null
                        if (selectIdx > 0) {
                          selectId = item.showTexts[selectIdx - 1].texts[0].characterSentenceId
                        } else {
                          return
                        }
                        var range = window.getSelection().getRangeAt(0)
                        range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                        range.collapse(true)
                        this.$emit(
                          'changeAnchorNode',
                          document.getElementById(
                            item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                          )
                        )
                      } else {
                        var range = window.getSelection().getRangeAt(0)
                        range.selectNode(
                          document.getElementById(
                            item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                          )
                        )
                        range.collapse(true)
                        this.$emit(
                          'changeAnchorNode',
                          document.getElementById(
                            item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                          )
                        )
                      }
                    } else {
                      window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                      window.getSelection().collapseToEnd()
                    }
                  }
                } else {
                  window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                  window.getSelection().collapseToEnd()
                }
              })
            }
          } else {
            e.target.innerText = ''
            item.showTexts = []
            item.showText = ''
            this.$nextTick(() => {
              item.characterSentences.push({
                ...this.oldTextBatchStyle,
                textAlign: this.textBatchStyle.textAlign,
                fontFamily: this.textBatchStyle.fontFamily,
                isBold: this.textBatchStyle.isBold ? 1 : 0,
                characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                characterText: ''
              })
              let pArr = []
              let brIds = []
              item.brIndexs = []
              item.pIndexs = []
              item.characterSentences?.forEach((t, pushTtextIndex) => {
                if (
                  item.characterSentences[pushTtextIndex + 1] &&
                  item.characterSentences[pushTtextIndex + 1].phonetic &&
                  item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                ) {
                  item.pIndexs.push(pushTtextIndex)
                }
                if (t.characterText != '<br>' && t.characterText != '\n') {
                  pArr.push(t)
                } else {
                  brIds.push(t.characterSentenceId)
                  item.brIndexs.push(pushTtextIndex)
                  if (pArr.length > 1) {
                    /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                  }
                  if (!pArr.length) {
                    pArr.push({
                      ...this.oldTextBatchStyle,
                      textAlign: this.textBatchStyle.textAlign,
                      fontFamily: this.textBatchStyle.fontFamily,
                      characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                      characterText: ' '
                    })
                  }
                  item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  pArr = []
                }
                if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                  item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                }
              })
              brIds.forEach((brId, brPushIdx) => {
                if (item.showTexts[brPushIdx + 1]) {
                  item.showTexts[brPushIdx + 1].pId =
                    brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                }
              })
              item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
            })
          }
        }
        this.getYahooOrKdxf(item.showText, item.pageId, item.id, item)
        this.selectionTexts = []
        this.$emit('clearPhoneticTexts')
        return
      } else if (e.key == 'Enter') {
        if (!this.isWindows) return
        if (this.inputStart) return
        if (this.selectionTexts.length) {
          let enterIdx = -1
          let num = 0
          let beforeText = this.getCursortPosition(e.target)
            .replaceAll('\n', '')
            .replaceAll(/\s+/g, '')
          e.target.innerText = ''
          let allText = ''
          let brNum = 0
          let bLength = beforeText.length
          item.characterSentences.forEach((c, cidx) => {
            if (c.characterText != '<br>' && c.characterText != '\n' && c.characterText != '\r') {
              allText = allText + c.characterText
            } else {
              bLength++
              if (bLength >= cidx) {
                brNum++
              }
            }
          })
          this.selectionTexts.forEach(st => {
            if (!num) {
              num++
              enterIdx = item.characterSentences.findIndex(c => c.characterSentenceId == st.characterSentenceId)
              item.characterSentences.splice(
                item.characterSentences.findIndex(c => c.characterSentenceId == st.characterSentenceId),
                1,
                {
                  ...this.oldTextBatchStyle,
                  textAlign: this.textBatchStyle.textAlign,
                  fontFamily: this.textBatchStyle.fontFamily,
                  isBold: this.textBatchStyle.isBold ? 1 : 0,
                  characterSentenceId: 'newP' + Math.round(Math.random() * 10000) + new Date().getTime(),
                  characterText: '<br>'
                }
              )
            } else {
              item.characterSentences.splice(
                item.characterSentences.findIndex(c => c.characterSentenceId == st.characterSentenceId),
                1
              )
            }
          })
          this.selectionTexts = []
          this.$emit('clearPhoneticTexts')
          let arr = JSON.parse(JSON.stringify(item.characterSentences))
          item.characterSentences = []
          item.showTexts = []
          this.$nextTick(() => {
            item.characterSentences = arr
            let pArr = []
            let brIds = []
            item.brIndexs = []
            item.pIndexs = []
            item.characterSentences?.forEach((t, pushTtextIndex) => {
              if (
                item.characterSentences[pushTtextIndex + 1] &&
                item.characterSentences[pushTtextIndex + 1].phonetic &&
                item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
              ) {
                item.pIndexs.push(pushTtextIndex)
              }
              if (t.characterText != '<br>' && t.characterText != '\n') {
                pArr.push(t)
              } else {
                brIds.push(t.characterSentenceId)
                item.brIndexs.push(pushTtextIndex)
                if (pArr.length > 1) {
                  /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                }
                if (!pArr.length) {
                  pArr.push({
                    ...this.oldTextBatchStyle,
                    textAlign: this.textBatchStyle.textAlign,
                    fontFamily: this.textBatchStyle.fontFamily,
                    characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    characterText: ' '
                  })
                }
                item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                pArr = []
              }
              if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
              }
            })
            brIds.forEach((brId, brPushIdx) => {
              if (item.showTexts[brPushIdx + 1]) {
                item.showTexts[brPushIdx + 1].pId =
                  brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
              }
            })
            item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
            let deleteIdx = []
            item.characterSentences.forEach((t, tIdx) => {
              if (
                t.characterText == '<br>' &&
                item.characterSentences[tIdx + 1] &&
                item.characterSentences[tIdx + 1].characterText == ' ' &&
                item.characterSentences[tIdx + 2]
              ) {
                deleteIdx.push(tIdx + 1)
              }
            })
            deleteIdx.forEach((d, dIdx) => {
              item.characterSentences.splice(d - dIdx, 1)
            })
            item.showText = item.characterSentences
              .map(c => c.characterText)
              .join('')
              .replaceAll('<br>', '\n')
            this.$nextTick(() => {
              this.isCenter = false
              var range = window.getSelection().getRangeAt(0)
              /* e.target.childNodes.forEach((child, cIdx) => {
                if (cIdx == enterIdx + brNum - 1) {
                  range.selectNode(child)
                  range.collapse(true)
                  this.$emit('changeAnchorNode', child)
                }
              }) */
              if (item.showTexts[brNum + 1]) {
                let selectId = item.showTexts[brNum + 1].pId
                range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                range.collapse(true)
                this.$emit('changeAnchorNode', document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
              }
            })
          })
        } else {
          let beforeText = this.getCursortPosition(e.target).replaceAll('\n', '')
          /* .replaceAll(/\s+/g, '') */
          let selectEndterIdx = this.getCaretPosition(e.target)
          e.target.innerText = ''
          if (!beforeText) {
            item.characterSentences.splice(0, 0, {
              ...this.oldTextBatchStyle,
              textAlign: this.textBatchStyle.textAlign,
              fontFamily: this.textBatchStyle.fontFamily,
              isBold: this.textBatchStyle.isBold ? 1 : 0,
              characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
              characterText: ' '
            })
            item.characterSentences.splice(1, 0, {
              ...this.oldTextBatchStyle,
              textAlign: this.textBatchStyle.textAlign,
              fontFamily: this.textBatchStyle.fontFamily,
              isBold: this.textBatchStyle.isBold ? 1 : 0,
              characterSentenceId: 'newP' + Math.round(Math.random() * 10000) + new Date().getTime(),
              characterText: '<br>'
            })
            let arr = JSON.parse(JSON.stringify(item.characterSentences))
            item.characterSentences = []
            item.showTexts = []
            this.$nextTick(() => {
              item.characterSentences = arr
              let pArr = []
              let brIds = []
              item.brIndexs = []
              item.pIndexs = []
              item.characterSentences?.forEach((t, pushTtextIndex) => {
                if (
                  item.characterSentences[pushTtextIndex + 1] &&
                  item.characterSentences[pushTtextIndex + 1].phonetic &&
                  item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                ) {
                  item.pIndexs.push(pushTtextIndex)
                }
                if (t.characterText != '<br>' && t.characterText != '\n') {
                  pArr.push(t)
                } else {
                  brIds.push(t.characterSentenceId)
                  item.brIndexs.push(pushTtextIndex)
                  if (pArr.length > 1) {
                    /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                  }
                  if (!pArr.length) {
                    pArr.push({
                      ...this.oldTextBatchStyle,
                      textAlign: this.textBatchStyle.textAlign,
                      fontFamily: this.textBatchStyle.fontFamily,
                      isBold: this.textBatchStyle.isBold ? 1 : 0,
                      characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                      characterText: ' '
                    })
                  }
                  item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  pArr = []
                }
                if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                  item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                }
              })
              brIds.forEach((brId, brPushIdx) => {
                if (item.showTexts[brPushIdx + 1]) {
                  item.showTexts[brPushIdx + 1].pId =
                    brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                }
              })
              item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
              item.showText = item.characterSentences
                .map(c => c.characterText)
                .join('')
                .replaceAll('<br>', '\n')
              this.isCenter = false
              var range = window.getSelection().getRangeAt(0)
              if (
                item.characterSentences[selectEndterIdx + selectEndterIdx + 1] &&
                item.characterSentences[selectEndterIdx + selectEndterIdx + 1].characterText == '<br>'
              ) {
                let selectId = item.showTexts.find(
                  p =>
                    p.pId.split('-')[0] ==
                    item.characterSentences[selectEndterIdx + selectEndterIdx + 1].characterSentenceId
                ).pId
                let dom = document.getElementById(item.pageId + '-' + item.id + '-' + selectId)
                if (!dom) {
                  let brNum = 0
                  item.characterSentences.forEach(text => {
                    if (brNum - 1 == selectEndterIdx) {
                      selectId = text.characterSentenceId
                      brNum = 1000
                    }
                    if (text.characterText == '<br>') {
                      brNum++
                    }
                  })
                  this.$nextTick(() => {
                    range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                    range.collapse(true)
                    this.$emit(
                      'changeAnchorNode',
                      document.getElementById(item.pageId + '-' + item.id + '-' + selectId)
                    )
                  })
                } else {
                  this.$nextTick(() => {
                    range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                    range.collapse(true)
                    this.$emit(
                      'changeAnchorNode',
                      document.getElementById(item.pageId + '-' + item.id + '-' + selectId)
                    )
                  })
                }
                return
              }
              if (item.showTexts[0]) {
                let selectId = item.showTexts[0].pId
                range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                range.collapse(true)
                this.$emit('changeAnchorNode', document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
              }
            })
            return
          }
          let allText = ''
          let brNum = 0
          let bLength = beforeText.length
          let spaceNum = 0
          item.characterSentences.forEach((c, cidx) => {
            if (
              /* c.characterText != ' ' && */
              c.characterText != '<br>' &&
              c.characterText != '\n' &&
              c.characterText != '\r'
            ) {
              allText = allText + c.characterText
            } else {
              bLength++
              if (bLength > cidx) {
                brNum++
              }
            }
            if (
              c.characterText == '<br>' &&
              item.characterSentences[cidx - 1] &&
              item.characterSentences[cidx - 1].characterText == '<br>'
            ) {
              spaceNum++
            }
          })
          if (beforeText.length && allText.length + spaceNum != beforeText.length) {
            //中间换行
            let afterText = allText.split(beforeText)[1]
            let enterIdx = this.getTextIndex(allText, beforeText, afterText, 0)
            let arr = JSON.parse(JSON.stringify(item.characterSentences))
            item.characterSentences = []
            let pushId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
            arr.splice(enterIdx + brNum, 0, {
              ...this.oldTextBatchStyle,
              textAlign: this.textBatchStyle.textAlign,
              fontFamily: this.textBatchStyle.fontFamily,
              isBold: this.textBatchStyle.isBold ? 1 : 0,
              characterSentenceId: pushId,
              characterText: '<br>'
            })
            /* arr.splice(enterIdx + brNum + 1, 0, {
              ...this.oldTextBatchStyle,
              textAlign: this.textBatchStyle.textAlign,
              fontFamily: this.textBatchStyle.fontFamily,
              characterSentenceId: 'new' + Math.round(Math.random() * 20000) + new Date().getTime(),
              characterText: ' '
            }) */
            item.showTexts = []
            this.$nextTick(() => {
              item.characterSentences = arr
              let pArr = []
              let brIds = []
              item.brIndexs = []
              item.pIndexs = []
              item.characterSentences?.forEach((t, pushTtextIndex) => {
                if (
                  item.characterSentences[pushTtextIndex + 1] &&
                  item.characterSentences[pushTtextIndex + 1].phonetic &&
                  item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                ) {
                  item.pIndexs.push(pushTtextIndex)
                }
                if (t.characterText != '<br>' && t.characterText != '\n') {
                  pArr.push(t)
                } else {
                  brIds.push(t.characterSentenceId)
                  item.brIndexs.push(pushTtextIndex)
                  if (pArr.length > 1) {
                    /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                  }
                  if (!pArr.length) {
                    pArr.push({
                      ...this.oldTextBatchStyle,
                      textAlign: this.textBatchStyle.textAlign,
                      fontFamily: this.textBatchStyle.fontFamily,
                      isBold: this.textBatchStyle.isBold ? 1 : 0,
                      characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                      characterText: ' '
                    })
                    if (item.showTexts.length && item.showTexts[item.showTexts.length - 1].texts.length > 1) {
                      brNum = brNum - 1
                    }
                  }
                  item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  pArr = []
                }
                if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                  item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                }
                if (pushTtextIndex == item.characterSentences.length - 1 && !pArr.length && t.characterText == '<br>') {
                  item.showTexts.push({
                    pId: '',
                    texts: [
                      {
                        ...this.oldTextBatchStyle,
                        textAlign: this.textBatchStyle.textAlign,
                        fontFamily: this.textBatchStyle.fontFamily,
                        isBold: this.textBatchStyle.isBold ? 1 : 0,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      }
                    ],
                    pushTtextIndex
                  })
                }
              })
              brIds.forEach((brId, brPushIdx) => {
                if (item.showTexts[brPushIdx + 1]) {
                  item.showTexts[brPushIdx + 1].pId =
                    brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                }
              })
              item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
              item.showText = item.characterSentences
                .map(c => c.characterText)
                .join('')
                .replaceAll('<br>', '\n')
              this.$nextTick(() => {
                this.isCenter = false
                var range = window.getSelection().getRangeAt(0)
                if (item.characterSentences[selectEndterIdx + 1]) {
                  let selectId = item.characterSentences[selectEndterIdx + 1].characterSentenceId
                  if (
                    item.characterSentences[selectEndterIdx + 1].characterText == '<br>' &&
                    item.showTexts[item.showTexts.findIndex(p => p.pId.split('-')[0] == selectId) - 1]
                  ) {
                    selectId =
                      item.showTexts[item.showTexts.findIndex(p => p.pId.split('-')[0] == selectId) - 1].texts[0]
                        .characterSentenceId
                  }
                  range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                  range.collapse(true)
                  this.$emit('changeAnchorNode', document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                } else {
                  if (e.target.lastChild && e.target.lastChild.lastChild) {
                    window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                    window.getSelection().collapseToEnd()
                  }
                }

                /* if (allText.split(beforeText).length && allText.split(beforeText)[1] == ' ') {
                  if (item.showTexts[brNum + 2] && item.showTexts[brNum + 2]) {
                    let selectId = item.showTexts[brNum + 2].pId
                    range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId).firstChild)
                    range.collapse(true)
                    this.$emit(
                      'changeAnchorNode',
                      document.getElementById(item.pageId + '-' + item.id + '-' + selectId).firstChild
                    )
                  }
                } else {
                  if (item.showTexts[brNum + 1] && item.showTexts[brNum + 1]) {
                    let selectId = item.showTexts[brNum + 1].pId
                    range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId).firstChild)
                    range.collapse(true)
                    this.$emit(
                      'changeAnchorNode',
                      document.getElementById(item.pageId + '-' + item.id + '-' + selectId).firstChild
                    )
                  }
                } */

                /*                 e.target.childNodes.forEach((child, cIdx) => {
                  if (cIdx == enterIdx + brNum + 1) {
                    range.selectNode(child)
                    range.collapse(true)
                    this.$emit('changeAnchorNode', child)
                  }
                }) */
              })
            })
          } else {
            this.isCenter = true
            let arr = JSON.parse(JSON.stringify(item.characterSentences))
            item.characterSentences = []
            arr.push({
              ...this.oldTextBatchStyle,
              textAlign: this.textBatchStyle.textAlign,
              fontFamily: this.textBatchStyle.fontFamily,
              isBold: this.textBatchStyle.isBold ? 1 : 0,
              characterSentenceId: 'newP' + Math.round(Math.random() * 10000) + new Date().getTime(),
              characterText: '<br>'
            })
            arr.push({
              ...this.oldTextBatchStyle,
              textAlign: this.textBatchStyle.textAlign,
              fontFamily: this.textBatchStyle.fontFamily,
              isBold: this.textBatchStyle.isBold ? 1 : 0,
              characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
              characterText: ' '
            })
            item.showTexts = []
            this.$nextTick(() => {
              item.characterSentences = arr
              let pArr = []
              let brIds = []
              item.brIndexs = []
              item.pIndexs = []
              item.characterSentences?.forEach((t, pushTtextIndex) => {
                if (
                  item.characterSentences[pushTtextIndex + 1] &&
                  item.characterSentences[pushTtextIndex + 1].phonetic &&
                  item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                ) {
                  item.pIndexs.push(pushTtextIndex)
                }
                if (t.characterText != '<br>' && t.characterText != '\n') {
                  pArr.push(t)
                } else {
                  brIds.push(t.characterSentenceId)
                  item.brIndexs.push(pushTtextIndex)
                  if (pArr.length > 1) {
                    /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                  }
                  if (!pArr.length) {
                    pArr.push({
                      ...this.oldTextBatchStyle,
                      textAlign: this.textBatchStyle.textAlign,
                      fontFamily: this.textBatchStyle.fontFamily,
                      isBold: this.textBatchStyle.isBold ? 1 : 0,
                      characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                      characterText: ' '
                    })
                  }
                  item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  pArr = []
                }
                if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                  item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                }
              })
              brIds.forEach((brId, brPushIdx) => {
                if (item.showTexts[brPushIdx + 1]) {
                  item.showTexts[brPushIdx + 1].pId =
                    brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                }
              })
              item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()

              item.showText = item.characterSentences
                .map(c => c.characterText)
                .join('')
                .replaceAll('<br>', '\n')
              this.$nextTick(() => {
                var range = window.getSelection().getRangeAt(0)
                if (item.showTexts[brNum + 1]) {
                  let selectId = item.showTexts[brNum + 1].texts[0].characterSentenceId
                  range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                  range.collapse(true)
                  this.$emit('changeAnchorNode', document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                  this.isCenter = false
                }
              })
            })
          }
        }

        return
      } else if (e.key == 'ArrowUp' || e.key == 'ArrowDown' || e.key == 'PageDown' || e.key == 'PageUp') {
        this.selectionTexts = []
        this.$emit('clearPhoneticTexts')
      } else {
        /*         if (this.inputStart) {
          this.textChangeSim(e, item)
        } else {
          let addText = e.data
          this.textChangeSim(e, item)
        } */
      }
    },
    async textDownChange(e, item) {
      if (this.isCenter) {
        e.preventDefault()
        this.isCenter = false
        return
      }
      if (!this.isCenter && e.key == 'Enter') {
        this.isCenter = true
      }
      if (!this.isWindows) {
        if (e.key == 'Enter') {
          if (this.inputStart) return
          setTimeout(() => {
            if (this.selectionTexts.length) {
              let enterIdx = -1
              let num = 0
              let beforeText = this.getCursortPosition(e.target)
                .replaceAll('\n', '')
                .replaceAll(/\s+/g, '')
              e.target.innerText = ''
              let allText = ''
              let brNum = 0
              let bLength = beforeText.length
              item.characterSentences.forEach((c, cidx) => {
                if (c.characterText != '<br>' && c.characterText != '\n' && c.characterText != '\r') {
                  allText = allText + c.characterText
                } else {
                  bLength++
                  if (bLength >= cidx) {
                    brNum++
                  }
                }
              })
              this.selectionTexts.forEach(st => {
                if (!num) {
                  num++
                  enterIdx = item.characterSentences.findIndex(c => c.characterSentenceId == st.characterSentenceId)
                  item.characterSentences.splice(
                    item.characterSentences.findIndex(c => c.characterSentenceId == st.characterSentenceId),
                    1,
                    {
                      ...this.oldTextBatchStyle,
                      textAlign: this.textBatchStyle.textAlign,
                      fontFamily: this.textBatchStyle.fontFamily,
                      characterSentenceId: 'newP' + Math.round(Math.random() * 10000) + new Date().getTime(),
                      characterText: '<br>'
                    }
                  )
                } else {
                  item.characterSentences.splice(
                    item.characterSentences.findIndex(c => c.characterSentenceId == st.characterSentenceId),
                    1
                  )
                }
              })
              this.selectionTexts = []
              this.$emit('clearPhoneticTexts')
              let arr = JSON.parse(JSON.stringify(item.characterSentences))
              item.characterSentences = []
              item.showTexts = []
              this.$nextTick(() => {
                item.characterSentences = arr
                let pArr = []
                let brIds = []
                item.brIndexs = []
                item.pIndexs = []
                item.characterSentences?.forEach((t, pushTtextIndex) => {
                  if (
                    item.characterSentences[pushTtextIndex + 1] &&
                    item.characterSentences[pushTtextIndex + 1].phonetic &&
                    item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                  ) {
                    item.pIndexs.push(pushTtextIndex)
                  }
                  if (t.characterText != '<br>' && t.characterText != '\n') {
                    pArr.push(t)
                  } else {
                    brIds.push(t.characterSentenceId)
                    item.brIndexs.push(pushTtextIndex)
                    if (pArr.length > 1) {
                      /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                    }
                    if (!pArr.length) {
                      pArr.push({
                        ...this.oldTextBatchStyle,
                        textAlign: this.textBatchStyle.textAlign,
                        fontFamily: this.textBatchStyle.fontFamily,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      })
                    }
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    pArr = []
                  }
                  if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  }
                })
                brIds.forEach((brId, brPushIdx) => {
                  if (item.showTexts[brPushIdx + 1]) {
                    item.showTexts[brPushIdx + 1].pId =
                      brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                  }
                })
                item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                let deleteIdx = []
                item.characterSentences.forEach((t, tIdx) => {
                  if (
                    t.characterText == '<br>' &&
                    item.characterSentences[tIdx + 1] &&
                    item.characterSentences[tIdx + 1].characterText == ' ' &&
                    item.characterSentences[tIdx + 2]
                  ) {
                    deleteIdx.push(tIdx + 1)
                  }
                })
                deleteIdx.forEach((d, dIdx) => {
                  item.characterSentences.splice(d - dIdx, 1)
                })
                item.showText = item.characterSentences
                  .map(c => c.characterText)
                  .join('')
                  .replaceAll('<br>', '\n')
                this.$nextTick(() => {
                  this.isCenter = false
                  var range = window.getSelection().getRangeAt(0)
                  /* e.target.childNodes.forEach((child, cIdx) => {
                if (cIdx == enterIdx + brNum - 1) {
                  range.selectNode(child)
                  range.collapse(true)
                  this.$emit('changeAnchorNode', child)
                }
              }) */
                  if (item.showTexts[brNum + 1]) {
                    let selectId = item.showTexts[brNum + 1].pId
                    range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                    range.collapse(true)
                    this.$emit(
                      'changeAnchorNode',
                      document.getElementById(item.pageId + '-' + item.id + '-' + selectId)
                    )
                  }
                })
              })
            } else {
              let beforeText = this.getCursortPosition(e.target).replaceAll('\n', '')
              /* .replaceAll(/\s+/g, '') */
              let selectEndterIdx = this.getCaretPosition(e.target)
              e.target.innerText = ''
              if (!beforeText) {
                item.characterSentences.splice(0, 0, {
                  ...this.oldTextBatchStyle,
                  textAlign: this.textBatchStyle.textAlign,
                  fontFamily: this.textBatchStyle.fontFamily,
                  characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                  characterText: ' '
                })
                item.characterSentences.splice(1, 0, {
                  ...this.oldTextBatchStyle,
                  textAlign: this.textBatchStyle.textAlign,
                  fontFamily: this.textBatchStyle.fontFamily,
                  characterSentenceId: 'newP' + Math.round(Math.random() * 10000) + new Date().getTime(),
                  characterText: '<br>'
                })
                let arr = JSON.parse(JSON.stringify(item.characterSentences))
                item.characterSentences = []
                item.showTexts = []
                item.characterSentences = arr
                let pArr = []
                let brIds = []
                item.brIndexs = []
                item.pIndexs = []
                item.characterSentences?.forEach((t, pushTtextIndex) => {
                  if (
                    item.characterSentences[pushTtextIndex + 1] &&
                    item.characterSentences[pushTtextIndex + 1].phonetic &&
                    item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                  ) {
                    item.pIndexs.push(pushTtextIndex)
                  }
                  if (t.characterText != '<br>' && t.characterText != '\n') {
                    pArr.push(t)
                  } else {
                    brIds.push(t.characterSentenceId)
                    item.brIndexs.push(pushTtextIndex)
                    if (pArr.length > 1) {
                      /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                    }
                    if (!pArr.length) {
                      pArr.push({
                        ...this.oldTextBatchStyle,
                        textAlign: this.textBatchStyle.textAlign,
                        fontFamily: this.textBatchStyle.fontFamily,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      })
                    }
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    pArr = []
                  }
                  if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  }
                })
                brIds.forEach((brId, brPushIdx) => {
                  if (item.showTexts[brPushIdx + 1]) {
                    item.showTexts[brPushIdx + 1].pId =
                      brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                  }
                })
                item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                item.showText = item.characterSentences
                  .map(c => c.characterText)
                  .join('')
                  .replaceAll('<br>', '\n')
                this.$nextTick(() => {
                  this.isCenter = false
                  var range = window.getSelection().getRangeAt(0)
                  if (
                    item.characterSentences[selectEndterIdx + selectEndterIdx + 1] &&
                    item.characterSentences[selectEndterIdx + selectEndterIdx + 1].characterText == '<br>'
                  ) {
                    let selectId = item.showTexts.find(
                      p =>
                        p.pId.split('-')[0] ==
                        item.characterSentences[selectEndterIdx + selectEndterIdx + 1].characterSentenceId
                    ).pId
                    range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                    range.collapse(true)
                    this.$emit(
                      'changeAnchorNode',
                      document.getElementById(item.pageId + '-' + item.id + '-' + selectId)
                    )
                    return
                  }
                  if (item.showTexts[0]) {
                    let selectId = item.showTexts[0].pId
                    range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                    range.collapse(true)
                    this.$emit(
                      'changeAnchorNode',
                      document.getElementById(item.pageId + '-' + item.id + '-' + selectId)
                    )
                  }
                })
                return
              }
              let allText = ''
              let brNum = 0
              let bLength = beforeText.length
              let spaceNum = 0
              item.characterSentences.forEach((c, cidx) => {
                if (
                  /* c.characterText != ' ' && */
                  c.characterText != '<br>' &&
                  c.characterText != '\n' &&
                  c.characterText != '\r'
                ) {
                  allText = allText + c.characterText
                } else {
                  bLength++
                  if (bLength > cidx) {
                    brNum++
                  }
                }
                if (
                  c.characterText == '<br>' &&
                  item.characterSentences[cidx - 1] &&
                  item.characterSentences[cidx - 1].characterText == '<br>'
                ) {
                  spaceNum++
                }
              })
              if (beforeText.length && allText.length + spaceNum != beforeText.length) {
                //中间换行
                let afterText = allText.split(beforeText)[1]
                let enterIdx = this.getTextIndex(allText, beforeText, afterText, 0)
                let arr = JSON.parse(JSON.stringify(item.characterSentences))
                item.characterSentences = []
                let pushId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                arr.splice(enterIdx + brNum, 0, {
                  ...this.oldTextBatchStyle,
                  textAlign: this.textBatchStyle.textAlign,
                  fontFamily: this.textBatchStyle.fontFamily,
                  characterSentenceId: pushId,
                  characterText: '<br>'
                })
                /* arr.splice(enterIdx + brNum + 1, 0, {
              ...this.oldTextBatchStyle,
              textAlign: this.textBatchStyle.textAlign,
              fontFamily: this.textBatchStyle.fontFamily,
              characterSentenceId: 'new' + Math.round(Math.random() * 20000) + new Date().getTime(),
              characterText: ' '
            }) */
                item.showTexts = []
                this.$nextTick(() => {
                  item.characterSentences = arr
                  let pArr = []
                  let brIds = []
                  item.brIndexs = []
                  item.pIndexs = []
                  item.characterSentences?.forEach((t, pushTtextIndex) => {
                    if (
                      item.characterSentences[pushTtextIndex + 1] &&
                      item.characterSentences[pushTtextIndex + 1].phonetic &&
                      item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                    ) {
                      item.pIndexs.push(pushTtextIndex)
                    }
                    if (t.characterText != '<br>' && t.characterText != '\n') {
                      pArr.push(t)
                    } else {
                      brIds.push(t.characterSentenceId)
                      item.brIndexs.push(pushTtextIndex)
                      if (pArr.length > 1) {
                        /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                      }
                      if (!pArr.length) {
                        pArr.push({
                          ...this.oldTextBatchStyle,
                          textAlign: this.textBatchStyle.textAlign,
                          fontFamily: this.textBatchStyle.fontFamily,
                          characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                          characterText: ' '
                        })
                        if (item.showTexts.length && item.showTexts[item.showTexts.length - 1].texts.length > 1) {
                          brNum = brNum - 1
                        }
                      }
                      item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                      pArr = []
                    }
                    if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                      item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    }
                    if (
                      pushTtextIndex == item.characterSentences.length - 1 &&
                      !pArr.length &&
                      t.characterText == '<br>'
                    ) {
                      item.showTexts.push({
                        pId: '',
                        texts: [
                          {
                            ...this.oldTextBatchStyle,
                            textAlign: this.textBatchStyle.textAlign,
                            fontFamily: this.textBatchStyle.fontFamily,
                            characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                            characterText: ' '
                          }
                        ],
                        pushTtextIndex
                      })
                    }
                  })
                  brIds.forEach((brId, brPushIdx) => {
                    if (item.showTexts[brPushIdx + 1]) {
                      item.showTexts[brPushIdx + 1].pId =
                        brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                    }
                  })
                  item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                  item.showText = item.characterSentences
                    .map(c => c.characterText)
                    .join('')
                    .replaceAll('<br>', '\n')
                  this.$nextTick(() => {
                    this.isCenter = false
                    var range = window.getSelection().getRangeAt(0)
                    if (item.characterSentences[selectEndterIdx + 1]) {
                      let selectId = item.characterSentences[selectEndterIdx + 1].characterSentenceId
                      if (
                        item.characterSentences[selectEndterIdx + 1].characterText == '<br>' &&
                        item.showTexts[item.showTexts.findIndex(p => p.pId.split('-')[0] == selectId) - 1]
                      ) {
                        selectId =
                          item.showTexts[item.showTexts.findIndex(p => p.pId.split('-')[0] == selectId) - 1].texts[0]
                            .characterSentenceId
                      }
                      range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                      range.collapse(true)
                      this.$emit(
                        'changeAnchorNode',
                        document.getElementById(item.pageId + '-' + item.id + '-' + selectId)
                      )
                    } else {
                      if (e.target.lastChild && e.target.lastChild.lastChild) {
                        window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                        window.getSelection().collapseToEnd()
                      }
                    }

                    /* if (allText.split(beforeText).length && allText.split(beforeText)[1] == ' ') {
                  if (item.showTexts[brNum + 2] && item.showTexts[brNum + 2]) {
                    let selectId = item.showTexts[brNum + 2].pId
                    range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId).firstChild)
                    range.collapse(true)
                    this.$emit(
                      'changeAnchorNode',
                      document.getElementById(item.pageId + '-' + item.id + '-' + selectId).firstChild
                    )
                  }
                } else {
                  if (item.showTexts[brNum + 1] && item.showTexts[brNum + 1]) {
                    let selectId = item.showTexts[brNum + 1].pId
                    range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId).firstChild)
                    range.collapse(true)
                    this.$emit(
                      'changeAnchorNode',
                      document.getElementById(item.pageId + '-' + item.id + '-' + selectId).firstChild
                    )
                  }
                } */

                    /*                 e.target.childNodes.forEach((child, cIdx) => {
                  if (cIdx == enterIdx + brNum + 1) {
                    range.selectNode(child)
                    range.collapse(true)
                    this.$emit('changeAnchorNode', child)
                  }
                }) */
                  })
                })
              } else {
                this.isCenter = true
                let arr = JSON.parse(JSON.stringify(item.characterSentences))
                item.characterSentences = []
                arr.push({
                  ...this.oldTextBatchStyle,
                  textAlign: this.textBatchStyle.textAlign,
                  fontFamily: this.textBatchStyle.fontFamily,
                  characterSentenceId: 'newP' + Math.round(Math.random() * 10000) + new Date().getTime(),
                  characterText: '<br>'
                })
                arr.push({
                  ...this.oldTextBatchStyle,
                  textAlign: this.textBatchStyle.textAlign,
                  fontFamily: this.textBatchStyle.fontFamily,
                  characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                  characterText: ' '
                })
                item.showTexts = []
                this.$nextTick(() => {
                  item.characterSentences = arr
                  let pArr = []
                  let brIds = []
                  item.brIndexs = []
                  item.pIndexs = []
                  item.characterSentences?.forEach((t, pushTtextIndex) => {
                    if (
                      item.characterSentences[pushTtextIndex + 1] &&
                      item.characterSentences[pushTtextIndex + 1].phonetic &&
                      item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                    ) {
                      item.pIndexs.push(pushTtextIndex)
                    }
                    if (t.characterText != '<br>' && t.characterText != '\n') {
                      pArr.push(t)
                    } else {
                      brIds.push(t.characterSentenceId)
                      item.brIndexs.push(pushTtextIndex)
                      if (pArr.length > 1) {
                        /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                      }
                      if (!pArr.length) {
                        pArr.push({
                          ...this.oldTextBatchStyle,
                          textAlign: this.textBatchStyle.textAlign,
                          fontFamily: this.textBatchStyle.fontFamily,
                          characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                          characterText: ' '
                        })
                      }
                      item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                      pArr = []
                    }
                    if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                      item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    }
                  })
                  brIds.forEach((brId, brPushIdx) => {
                    if (item.showTexts[brPushIdx + 1]) {
                      item.showTexts[brPushIdx + 1].pId =
                        brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                    }
                  })
                  item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()

                  item.showText = item.characterSentences
                    .map(c => c.characterText)
                    .join('')
                    .replaceAll('<br>', '\n')
                  this.$nextTick(() => {
                    var range = window.getSelection().getRangeAt(0)
                    if (item.showTexts[brNum + 1]) {
                      let selectId = item.showTexts[brNum + 1].texts[0].characterSentenceId
                      range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                      range.collapse(true)
                      this.$emit(
                        'changeAnchorNode',
                        document.getElementById(item.pageId + '-' + item.id + '-' + selectId)
                      )
                      this.isCenter = false
                    }
                  })
                })
              }
            }
          }, 0)
          return
        } else if (e.metaKey && (e.key == 'a' || e.key == 'A')) {
          if (item.characterSentences && item.characterSentences.length) {
            this.selectionTexts = JSON.parse(JSON.stringify(item.characterSentences))
            this.startIdx = 0
            this.endIdx = item.characterSentences.length - 1
            this.selectionString = window.getSelection().toString()
            this.$emit('changeSelectTexts', this.selectionTexts)
          }
        } else if (e.metaKey && (e.key == 'c' || e.key == 'C')) {
          e.preventDefault()
          this.copyContent = window.getSelection().toString()
          this.copyContent = this.copyContent.replaceAll('\n\n', '\n')
          this.copyContent = this.copyContent.replaceAll('\n ', '\n')
          document.execCommand('copy')
          localStorage.setItem('translate_copyContent', this.copyContent)
        } else if (e.metaKey && (e.key == 'x' || e.key == 'X')) {
          setTimeout(() => {
            /*         if (item.characterSentences.length == 1) {
          e.target.innerText = ''
        } */
            if (this.inputStart) return
            if (item.characterSentences.length == 1 && window.getSelection().anchorNode.tagName == 'P') {
              if (
                !window.getSelection().anchorNode.childNodes.length ||
                window.getSelection().anchorNode.childNodes[0].tagName == 'BR'
              ) {
                let arr = JSON.parse(JSON.stringify(item.characterSentences))
                item.characterSentences = [
                  {
                    ...this.oldTextBatchStyle,
                    textAlign: this.textBatchStyle.textAlign,
                    fontFamily: this.textBatchStyle.fontFamily,
                    characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    characterText: ''
                  }
                ]
                item.showTexts = []
                e.target.innerText = ''
                this.$nextTick(() => {
                  this.changeShowText(item)
                })
                return
              }
            }
            let textIds = []
            e.target.childNodes.forEach((p, idx) => {
              if (idx && p.id) {
                textIds.push(p.id.split('-')[2])
              }
              p.childNodes.forEach(child => {
                let id = child.id.split('-')[2]
                let char = item.characterSentences.find(c => c.characterSentenceId == id)
                if (!child.innerText && child.tagName != 'BR') return
                if ((char && !char.phonetic && !char.hasMark) || child.tagName == 'BR') {
                  if (id) textIds.push(id)
                } else {
                  if (char && char.characterText && child.innerText.indexOf(char.characterText) > -1) {
                    if (id) textIds.push(id)
                  }
                }
                if (child.innerText && !child.id) {
                  let id = window.getSelection().anchorNode.parentNode.id.split('-')[0]
                  let index = item.characterSentences.findIndex(c => c.characterSentenceId == id)
                  if (item.characterSentences[index + 2]) {
                    textIds.push(item.characterSentences[index + 2].characterSentenceId)
                  }
                }
              })
            })
            if (
              e.target.childNodes.length == 1 &&
              e.target.childNodes[0].childNodes.length == 1 &&
              e.target.childNodes[0].childNodes[0].tagName == 'SPAN' &&
              !e.target.childNodes[0].childNodes[0].id
            ) {
              textIds = [item.characterSentences[item.characterSentences.length - 1].characterSentenceId]
            }
            let backSelectNode = window.getSelection().anchorNode.parentNode
            if (
              backSelectNode.tagName == 'SPAN' &&
              backSelectNode.id.indexOf('text') > -1 &&
              item.characterSentences.every(t => t.characterSentenceId != backSelectNode.id)
            ) {
              item.showTexts.forEach(p => {
                if (textIds.every(tId => (p.pId + '').split('-').length > 1 && tId != (p.pId + '').split('-')[0])) {
                  textIds = [...textIds, ...p.texts.map(t => t.characterSentenceId)]
                }
              })
            }
            let deleArr = []
            item.characterSentences.forEach((c, cIdx) => {
              if (textIds.every(tId => tId != c.characterSentenceId)) {
                deleArr.push(cIdx)
              }
            })
            if (deleArr.length) {
              if (item.characterSentences.length > 1) {
                /* console.log(item.characterSentences[deleArr[0]], item.characterSentences[deleArr[0] - 1])
            debugger */
                deleArr.forEach((deleIdx, index) => {
                  item.characterSentences.forEach(text => {
                    if (
                      text.phonetic &&
                      text.phonetic.targetCharacterIds &&
                      text.phonetic.targetCharacterIds.length > 1
                    ) {
                      if (
                        text.phonetic.targetCharacterIds.some(
                          targetId => targetId == item.characterSentences[deleIdx].characterSentenceId
                        )
                      ) {
                        text.phonetic = null
                      }
                    }
                  })
                  if (
                    item.characterSentences[deleIdx] &&
                    item.characterSentences[deleIdx].characterText == '<br>' &&
                    item.characterSentences[deleIdx + 1] &&
                    item.characterSentences[deleIdx + 1].characterText == ' '
                  ) {
                    item.characterSentences.splice(deleIdx - index + 1, 1)
                  }
                  let num = 0
                  if (
                    item.characterSentences[deleIdx] &&
                    item.characterSentences[deleIdx].characterText == ' ' &&
                    item.characterSentences[deleIdx - 1] &&
                    item.characterSentences[deleIdx - 1].characterText == '<br>'
                  ) {
                    num = 1
                    item.characterSentences.splice(deleIdx - index - 1, 1)
                  }
                  item.characterSentences.splice(deleIdx - index - num, 1)
                })
              } else {
                item.characterSentences[0].characterText = ''
              }
            } else {
              return
            }
            if (
              item.characterSentences.length &&
              item.characterSentences[item.characterSentences.length - 1] &&
              item.characterSentences[item.characterSentences.length - 1].characterText == '<br>'
            ) {
              item.characterSentences.push({
                ...this.oldTextBatchStyle,
                textAlign: this.textBatchStyle.textAlign,
                fontFamily: this.textBatchStyle.fontFamily,
                characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                characterText: ' '
              })
              item.characterSentences.forEach((c, cIdx) => {
                if (c.characterText == '') {
                  item.characterSentences.splice(cIdx, 1)
                }
              })
              item.showText = item.characterSentences
                .map(c => c.characterText)
                .join('')
                .replaceAll('<br>', '\n')
              let arr = JSON.parse(JSON.stringify(item.characterSentences))
              item.characterSentences = []
              item.showTexts = []
              e.target.innerText = ''
              this.$nextTick(() => {
                item.characterSentences = arr
                let pArr = []
                let brIds = []
                item.brIndexs = []
                item.pIndexs = []
                item.characterSentences?.forEach((t, pushTtextIndex) => {
                  if (
                    item.characterSentences[pushTtextIndex + 1] &&
                    item.characterSentences[pushTtextIndex + 1].phonetic &&
                    item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                  ) {
                    item.pIndexs.push(pushTtextIndex)
                  }
                  if (t.characterText != '<br>' && t.characterText != '\n') {
                    pArr.push(t)
                  } else {
                    brIds.push(t.characterSentenceId)
                    item.brIndexs.push(pushTtextIndex)
                    if (pArr.length > 1) {
                      /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                    }
                    if (!pArr.length) {
                      pArr.push({
                        ...this.oldTextBatchStyle,
                        textAlign: this.textBatchStyle.textAlign,
                        fontFamily: this.textBatchStyle.fontFamily,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      })
                    }
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    pArr = []
                  }
                  if (pushTtextIndex == item.characterSentences.length - 1) {
                    if (!pArr.length) {
                      pArr.push({
                        ...this.oldTextBatchStyle,
                        textAlign: this.textBatchStyle.textAlign,
                        fontFamily: this.textBatchStyle.fontFamily,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      })
                    }
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  }
                })
                brIds.forEach((brId, brPushIdx) => {
                  if (item.showTexts[brPushIdx + 1]) {
                    item.showTexts[brPushIdx + 1].pId =
                      brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                  }
                })
                item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                let selectIdx = -1
                if (this.startIdx >= 0 && this.endIdx >= 0) {
                  if (this.startIdx > this.endIdx) {
                    selectIdx = this.endIdx
                  } else {
                    selectIdx = this.startIdx
                  }
                }
                if (this.startIdx == -1 && deleArr.length) {
                  selectIdx = deleArr[0] + 1
                }
                this.$nextTick(() => {
                  let nodes = []
                  e.target.childNodes.forEach(p => {
                    p.childNodes.forEach(d => {
                      nodes.push(d)
                    })
                  })
                  if (selectIdx < nodes.length) {
                    window
                      .getSelection()
                      .getRangeAt(0)
                      .setStart(nodes[selectIdx - 1 >= 0 ? selectIdx - 1 : 0], selectIdx - 1 >= 0 ? selectIdx - 1 : 0)
                  } else {
                    window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                    window.getSelection().collapseToEnd()
                  }
                })
              })
            } else if (
              item.characterSentences.length &&
              item.characterSentences[item.characterSentences.length - 1] &&
              item.characterSentences[item.characterSentences.length - 1].characterText == ' ' &&
              item.characterSentences[item.characterSentences.length - 2] &&
              item.characterSentences[item.characterSentences.length - 2].characterText == '<br>'
            ) {
              /* item.characterSentences.pop()
          item.characterSentences.pop() */
              item.characterSentences.forEach((c, cIdx) => {
                if (c.characterText == '') {
                  item.characterSentences.splice(cIdx, 1)
                }
              })
              item.showText = item.characterSentences
                .map(c => c.characterText)
                .join('')
                .replaceAll('<br>', '\n')
              let arr = JSON.parse(JSON.stringify(item.characterSentences))
              item.characterSentences = []
              e.target.innerText = ''
              item.showTexts = []
              this.$nextTick(() => {
                item.characterSentences = arr
                let pArr = []
                let brIds = []
                item.brIndexs = []
                item.pIndexs = []
                item.characterSentences?.forEach((t, pushTtextIndex) => {
                  if (
                    item.characterSentences[pushTtextIndex + 1] &&
                    item.characterSentences[pushTtextIndex + 1].phonetic &&
                    item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                  ) {
                    item.pIndexs.push(pushTtextIndex)
                  }
                  if (t.characterText != '<br>' && t.characterText != '\n') {
                    pArr.push(t)
                  } else {
                    brIds.push(t.characterSentenceId)
                    item.brIndexs.push(pushTtextIndex)
                    if (pArr.length > 1) {
                      /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                    }
                    if (!pArr.length) {
                      pArr.push({
                        ...this.oldTextBatchStyle,
                        textAlign: this.textBatchStyle.textAlign,
                        fontFamily: this.textBatchStyle.fontFamily,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      })
                    }
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    pArr = []
                  }
                  if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  }
                })
                brIds.forEach((brId, brPushIdx) => {
                  if (item.showTexts[brPushIdx + 1]) {
                    item.showTexts[brPushIdx + 1].pId =
                      brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                  }
                })
                item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                this.$nextTick(() => {
                  if (deleArr.length) {
                    if (item.characterSentences[deleArr[0] - 1]) {
                      var range = window.getSelection().getRangeAt(0)
                      range.selectNode(
                        document.getElementById(
                          item.pageId +
                            '-' +
                            item.id +
                            '-' +
                            item.characterSentences[deleArr[0] - 1].characterSentenceId
                        )
                      )
                      range.collapse(false)
                      this.$emit(
                        'changeAnchorNode',
                        document.getElementById(
                          item.pageId +
                            '-' +
                            item.id +
                            '-' +
                            item.characterSentences[deleArr[0] - 1].characterSentenceId
                        )
                      )
                    } else if (item.characterSentences[deleArr[0]]) {
                      var range = window.getSelection().getRangeAt(0)
                      range.selectNode(
                        document.getElementById(
                          item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                        )
                      )
                      range.collapse(true)
                      this.$emit(
                        'changeAnchorNode',
                        document.getElementById(
                          item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                        )
                      )
                    } else {
                      window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                      window.getSelection().collapseToEnd()
                    }
                  } else {
                    window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                    window.getSelection().collapseToEnd()
                  }
                })
              })
            } else if (
              item.characterSentences.length &&
              item.characterSentences[item.characterSentences.length - 1] &&
              item.characterSentences[item.characterSentences.length - 1].characterText == ' ' &&
              item.characterSentences[item.characterSentences.length - 2] &&
              item.characterSentences[item.characterSentences.length - 2].characterText == '<br>' &&
              item.characterSentences[item.characterSentences.length - 3] &&
              item.characterSentences[item.characterSentences.length - 3].characterText != '<br>'
            ) {
              /* item.characterSentences.pop()
          item.characterSentences.pop() */
              item.characterSentences.forEach((c, cIdx) => {
                if (c.characterText == '') {
                  item.characterSentences.splice(cIdx, 1)
                }
              })
              item.showText = item.characterSentences
                .map(c => c.characterText)
                .join('')
                .replaceAll('<br>', '\n')
              let arr = JSON.parse(JSON.stringify(item.characterSentences))
              item.characterSentences = []
              e.target.innerText = ''
              item.showTexts = []
              this.$nextTick(() => {
                item.characterSentences = arr
                let pArr = []
                let brIds = []
                item.brIndexs = []
                item.pIndexs = []
                item.characterSentences?.forEach((t, pushTtextIndex) => {
                  if (
                    item.characterSentences[pushTtextIndex + 1] &&
                    item.characterSentences[pushTtextIndex + 1].phonetic &&
                    item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                  ) {
                    item.pIndexs.push(pushTtextIndex)
                  }
                  if (t.characterText != '<br>' && t.characterText != '\n') {
                    pArr.push(t)
                  } else {
                    brIds.push(t.characterSentenceId)
                    item.brIndexs.push(pushTtextIndex)
                    if (pArr.length > 1) {
                      /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                    }
                    if (!pArr.length) {
                      pArr.push({
                        ...this.oldTextBatchStyle,
                        textAlign: this.textBatchStyle.textAlign,
                        fontFamily: this.textBatchStyle.fontFamily,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      })
                    }
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    pArr = []
                  }
                  if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  }
                })
                brIds.forEach((brId, brPushIdx) => {
                  if (item.showTexts[brPushIdx + 1]) {
                    item.showTexts[brPushIdx + 1].pId =
                      brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                  }
                })
                item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                this.$nextTick(() => {
                  if (deleArr.length) {
                    if (item.characterSentences[deleArr[0] - 1]) {
                      var range = window.getSelection().getRangeAt(0)
                      range.selectNode(
                        document.getElementById(
                          item.pageId +
                            '-' +
                            item.id +
                            '-' +
                            item.characterSentences[deleArr[0] - 1].characterSentenceId
                        )
                      )
                      range.collapse(false)
                      this.$emit(
                        'changeAnchorNode',
                        document.getElementById(
                          item.pageId +
                            '-' +
                            item.id +
                            '-' +
                            item.characterSentences[deleArr[0] - 1].characterSentenceId
                        )
                      )
                    } else {
                      var range = window.getSelection().getRangeAt(0)
                      range.selectNode(
                        document.getElementById(
                          item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                        )
                      )
                      range.collapse(true)
                      this.$emit(
                        'changeAnchorNode',
                        document.getElementById(
                          item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                        )
                      )
                    }
                  } else {
                    window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                    window.getSelection().collapseToEnd()
                  }
                })
              })
            } else {
              if (item.characterSentences.length) {
                item.characterSentences.forEach((c, cIdx) => {
                  if (c.characterText == '') {
                    item.characterSentences.splice(cIdx, 1)
                  }
                })
                if (item.characterSentences.length && item.characterSentences[0].characterText == '<br>') {
                  item.characterSentences.unshift({
                    ...this.oldTextBatchStyle,
                    textAlign: this.textBatchStyle.textAlign,
                    fontFamily: this.textBatchStyle.fontFamily,
                    characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    characterText: ' '
                  })
                  item.showText = item.characterSentences
                    .map(c => c.characterText)
                    .join('')
                    .replaceAll('<br>', '\n')
                  let arr = JSON.parse(JSON.stringify(item.characterSentences))
                  item.characterSentences = []
                  e.target.innerText = ''
                  item.showTexts = []
                  this.$nextTick(() => {
                    item.characterSentences = arr
                    let pArr = []
                    let brIds = []
                    item.brIndexs = []
                    item.pIndexs = []
                    item.characterSentences?.forEach((t, pushTtextIndex) => {
                      if (
                        item.characterSentences[pushTtextIndex + 1] &&
                        item.characterSentences[pushTtextIndex + 1].phonetic &&
                        item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                      ) {
                        item.pIndexs.push(pushTtextIndex)
                      }
                      if (t.characterText != '<br>' && t.characterText != '\n') {
                        pArr.push(t)
                      } else {
                        brIds.push(t.characterSentenceId)
                        item.brIndexs.push(pushTtextIndex)
                        if (pArr.length > 1) {
                          /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                        }
                        if (!pArr.length) {
                          pArr.push({
                            ...this.oldTextBatchStyle,
                            textAlign: this.textBatchStyle.textAlign,
                            fontFamily: this.textBatchStyle.fontFamily,
                            characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                            characterText: ' '
                          })
                        }
                        item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                        pArr = []
                      }
                      if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                        item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                      }
                    })
                    brIds.forEach((brId, brPushIdx) => {
                      if (item.showTexts[brPushIdx + 1]) {
                        item.showTexts[brPushIdx + 1].pId =
                          brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                      }
                    })
                    item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                    this.$nextTick(() => {
                      window
                        .getSelection()
                        .getRangeAt(0)
                        .setStart(e.target.childNodes[1], 0)
                    })
                  })
                } else {
                  item.showText = item.characterSentences
                    .map(c => c.characterText)
                    .join('')
                    .replaceAll('<br>', '\n')
                  let pArr = []
                  let brIds = []
                  item.showTexts = []
                  item.brIndexs = []
                  item.pIndexs = []
                  item.characterSentences?.forEach((t, pushTtextIndex) => {
                    if (
                      item.characterSentences[pushTtextIndex + 1] &&
                      item.characterSentences[pushTtextIndex + 1].phonetic &&
                      item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                    ) {
                      item.pIndexs.push(pushTtextIndex)
                    }
                    if (t.characterText != '<br>' && t.characterText != '\n') {
                      pArr.push(t)
                    } else {
                      brIds.push(t.characterSentenceId)
                      item.brIndexs.push(pushTtextIndex)
                      if (pArr.length > 1) {
                        /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                      }
                      if (!pArr.length) {
                        pArr.push({
                          ...this.oldTextBatchStyle,
                          textAlign: this.textBatchStyle.textAlign,
                          fontFamily: this.textBatchStyle.fontFamily,
                          characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                          characterText: ' '
                        })
                      }
                      item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                      pArr = []
                    }
                    if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                      item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    }
                  })
                  brIds.forEach((brId, brPushIdx) => {
                    if (item.showTexts[brPushIdx + 1]) {
                      item.showTexts[brPushIdx + 1].pId =
                        brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                    }
                  })
                  item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                  this.$nextTick(() => {
                    if (deleArr.length) {
                      if (
                        item.characterSentences[deleArr[0] - 1] &&
                        item.characterSentences[deleArr[0] - 1].characterText != '<br>'
                      ) {
                        var range = window.getSelection().getRangeAt(0)
                        range.selectNode(
                          document.getElementById(
                            item.pageId +
                              '-' +
                              item.id +
                              '-' +
                              item.characterSentences[deleArr[0] - 1].characterSentenceId
                          )
                        )
                        range.collapse(false)
                        this.$emit(
                          'changeAnchorNode',
                          document.getElementById(
                            item.pageId +
                              '-' +
                              item.id +
                              '-' +
                              item.characterSentences[deleArr[0] - 1].characterSentenceId
                          )
                        )
                      } else {
                        if (item.characterSentences[deleArr[0]]) {
                          if (
                            item.characterSentences[deleArr[0]].characterText == '<br>' &&
                            item.characterSentences[deleArr[0] - 1] &&
                            item.characterSentences[deleArr[0] - 1].characterText == '<br>'
                          ) {
                            let selectIdx = item.showTexts.findIndex(
                              p =>
                                (p.pId + '').indexOf(item.characterSentences[deleArr[0]].characterSentenceId + '') > -1
                            )
                            let selectId = null
                            if (selectIdx > 0) {
                              selectId = item.showTexts[selectIdx - 1].texts[0].characterSentenceId
                            } else {
                              return
                            }
                            var range = window.getSelection().getRangeAt(0)
                            range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                            range.collapse(true)
                            this.$emit(
                              'changeAnchorNode',
                              document.getElementById(
                                item.pageId +
                                  '-' +
                                  item.id +
                                  '-' +
                                  item.characterSentences[deleArr[0]].characterSentenceId
                              )
                            )
                          } else {
                            var range = window.getSelection().getRangeAt(0)
                            range.selectNode(
                              document.getElementById(
                                item.pageId +
                                  '-' +
                                  item.id +
                                  '-' +
                                  item.characterSentences[deleArr[0]].characterSentenceId
                              )
                            )
                            range.collapse(true)
                            this.$emit(
                              'changeAnchorNode',
                              document.getElementById(
                                item.pageId +
                                  '-' +
                                  item.id +
                                  '-' +
                                  item.characterSentences[deleArr[0]].characterSentenceId
                              )
                            )
                          }
                        } else {
                          window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                          window.getSelection().collapseToEnd()
                        }
                      }
                    } else {
                      window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                      window.getSelection().collapseToEnd()
                    }
                  })
                }
              } else {
                e.target.innerText = ''
                item.showTexts = []
                this.$nextTick(() => {
                  item.characterSentences.push({
                    ...this.oldTextBatchStyle,
                    textAlign: this.textBatchStyle.textAlign,
                    fontFamily: this.textBatchStyle.fontFamily,
                    characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    characterText: ''
                  })
                  let pArr = []
                  let brIds = []
                  item.brIndexs = []
                  item.pIndexs = []
                  item.characterSentences?.forEach((t, pushTtextIndex) => {
                    if (
                      item.characterSentences[pushTtextIndex + 1] &&
                      item.characterSentences[pushTtextIndex + 1].phonetic &&
                      item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                    ) {
                      item.pIndexs.push(pushTtextIndex)
                    }
                    if (t.characterText != '<br>' && t.characterText != '\n') {
                      pArr.push(t)
                    } else {
                      brIds.push(t.characterSentenceId)
                      item.brIndexs.push(pushTtextIndex)
                      if (pArr.length > 1) {
                        /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                      }
                      if (!pArr.length) {
                        pArr.push({
                          ...this.oldTextBatchStyle,
                          textAlign: this.textBatchStyle.textAlign,
                          fontFamily: this.textBatchStyle.fontFamily,
                          characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                          characterText: ' '
                        })
                      }
                      item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                      pArr = []
                    }
                    if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                      item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    }
                  })
                  brIds.forEach((brId, brPushIdx) => {
                    if (item.showTexts[brPushIdx + 1]) {
                      item.showTexts[brPushIdx + 1].pId =
                        brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                    }
                  })
                  item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                })
              }
            }
            this.getYahooPhonetics(item.showText, item.pageId, item.id, item)
            this.selectionTexts = []
            this.$emit('clearPhoneticTexts')
          }, 0)
          return
        }
        if (e.metaKey) return
        if (e.code == 'Space') {
          if (this.inputStart) return
          this.inputBackspace = true
        }
      } else {
        if ((e.key == 'V' || e.key == 'v') && e.ctrlKey) {
          return
        } else if (e.ctrlKey && (e.key == 'a' || e.key == 'A')) {
          if (item.characterSentences && item.characterSentences.length) {
            this.selectionTexts = JSON.parse(JSON.stringify(item.characterSentences))
            this.startIdx = 0
            this.endIdx = item.characterSentences.length - 1
            this.selectionString = window.getSelection().toString()
            this.$emit('changeSelectTexts', this.selectionTexts)
          }
        } else if (e.ctrlKey && (e.key == 'c' || e.key == 'C')) {
          /* e.preventDefault() */
          /*           this.copyContent = window.getSelection().toString()
          this.copyContent = this.copyContent.replaceAll('\n\n', '\n')
          this.copyContent = this.copyContent.replaceAll('\n ', '\n')
          document.execCommand('copy')
          localStorage.setItem('translate_copyContent', this.copyContent) */
        } else if (e.ctrlKey && (e.key == 'x' || e.key == 'X')) {
          setTimeout(() => {
            /*         if (item.characterSentences.length == 1) {
          e.target.innerText = ''
        } */
            if (this.inputStart) return
            if (item.characterSentences.length == 1 && window.getSelection().anchorNode.tagName == 'P') {
              if (
                !window.getSelection().anchorNode.childNodes.length ||
                window.getSelection().anchorNode.childNodes[0].tagName == 'BR'
              ) {
                let arr = JSON.parse(JSON.stringify(item.characterSentences))
                item.characterSentences = [
                  {
                    ...this.oldTextBatchStyle,
                    textAlign: this.textBatchStyle.textAlign,
                    fontFamily: this.textBatchStyle.fontFamily,
                    characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    characterText: ''
                  }
                ]
                item.showTexts = []
                e.target.innerText = ''
                this.$nextTick(() => {
                  this.changeShowText(item)
                })
                return
              }
            }
            let textIds = []
            e.target.childNodes.forEach((p, idx) => {
              if (idx && p.id) {
                textIds.push(p.id.split('-')[2])
              }
              p.childNodes.forEach(child => {
                let id = child.id.split('-')[2]
                let char = item.characterSentences.find(c => c.characterSentenceId == id)
                if (!child.innerText && child.tagName != 'BR') return
                if ((char && !char.phonetic && !char.hasMark) || child.tagName == 'BR') {
                  if (id) textIds.push(id)
                } else {
                  if (char && char.characterText && child.innerText.indexOf(char.characterText) > -1) {
                    if (id) textIds.push(id)
                  }
                }
                if (child.innerText && !child.id) {
                  let id = window.getSelection().anchorNode.parentNode.id.split('-')[0]
                  let index = item.characterSentences.findIndex(c => c.characterSentenceId == id)
                  if (item.characterSentences[index + 2]) {
                    textIds.push(item.characterSentences[index + 2].characterSentenceId)
                  }
                }
              })
            })
            if (
              e.target.childNodes.length == 1 &&
              e.target.childNodes[0].childNodes.length == 1 &&
              e.target.childNodes[0].childNodes[0].tagName == 'SPAN' &&
              !e.target.childNodes[0].childNodes[0].id
            ) {
              textIds = [item.characterSentences[item.characterSentences.length - 1].characterSentenceId]
            }
            let backSelectNode = window.getSelection().anchorNode.parentNode
            if (
              backSelectNode.tagName == 'SPAN' &&
              backSelectNode.id.indexOf('text') > -1 &&
              item.characterSentences.every(t => t.characterSentenceId != backSelectNode.id)
            ) {
              item.showTexts.forEach(p => {
                if (textIds.every(tId => (p.pId + '').split('-').length > 1 && tId != (p.pId + '').split('-')[0])) {
                  textIds = [...textIds, ...p.texts.map(t => t.characterSentenceId)]
                }
              })
            }
            let deleArr = []
            item.characterSentences.forEach((c, cIdx) => {
              if (textIds.every(tId => tId != c.characterSentenceId)) {
                deleArr.push(cIdx)
              }
            })
            if (deleArr.length) {
              if (item.characterSentences.length > 1) {
                /* console.log(item.characterSentences[deleArr[0]], item.characterSentences[deleArr[0] - 1])
            debugger */
                deleArr.forEach((deleIdx, index) => {
                  item.characterSentences.forEach(text => {
                    if (
                      text.phonetic &&
                      text.phonetic.targetCharacterIds &&
                      text.phonetic.targetCharacterIds.length > 1
                    ) {
                      if (
                        text.phonetic.targetCharacterIds.some(
                          targetId => targetId == item.characterSentences[deleIdx].characterSentenceId
                        )
                      ) {
                        text.phonetic = null
                      }
                    }
                  })
                  if (
                    item.characterSentences[deleIdx] &&
                    item.characterSentences[deleIdx].characterText == '<br>' &&
                    item.characterSentences[deleIdx + 1] &&
                    item.characterSentences[deleIdx + 1].characterText == ' '
                  ) {
                    item.characterSentences.splice(deleIdx - index + 1, 1)
                  }
                  let num = 0
                  if (
                    item.characterSentences[deleIdx] &&
                    item.characterSentences[deleIdx].characterText == ' ' &&
                    item.characterSentences[deleIdx - 1] &&
                    item.characterSentences[deleIdx - 1].characterText == '<br>'
                  ) {
                    num = 1
                    item.characterSentences.splice(deleIdx - index - 1, 1)
                  }
                  item.characterSentences.splice(deleIdx - index - num, 1)
                })
              } else {
                item.characterSentences[0].characterText = ''
              }
            } else {
              return
            }
            if (
              item.characterSentences.length &&
              item.characterSentences[item.characterSentences.length - 1] &&
              item.characterSentences[item.characterSentences.length - 1].characterText == '<br>'
            ) {
              item.characterSentences.push({
                ...this.oldTextBatchStyle,
                textAlign: this.textBatchStyle.textAlign,
                fontFamily: this.textBatchStyle.fontFamily,
                characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                characterText: ' '
              })
              item.characterSentences.forEach((c, cIdx) => {
                if (c.characterText == '') {
                  item.characterSentences.splice(cIdx, 1)
                }
              })
              item.showText = item.characterSentences
                .map(c => c.characterText)
                .join('')
                .replaceAll('<br>', '\n')
              let arr = JSON.parse(JSON.stringify(item.characterSentences))
              item.characterSentences = []
              item.showTexts = []
              e.target.innerText = ''
              this.$nextTick(() => {
                item.characterSentences = arr
                let pArr = []
                let brIds = []
                item.brIndexs = []
                item.pIndexs = []
                item.characterSentences?.forEach((t, pushTtextIndex) => {
                  if (
                    item.characterSentences[pushTtextIndex + 1] &&
                    item.characterSentences[pushTtextIndex + 1].phonetic &&
                    item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                  ) {
                    item.pIndexs.push(pushTtextIndex)
                  }
                  if (t.characterText != '<br>' && t.characterText != '\n') {
                    pArr.push(t)
                  } else {
                    brIds.push(t.characterSentenceId)
                    item.brIndexs.push(pushTtextIndex)
                    if (pArr.length > 1) {
                      /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                    }
                    if (!pArr.length) {
                      pArr.push({
                        ...this.oldTextBatchStyle,
                        textAlign: this.textBatchStyle.textAlign,
                        fontFamily: this.textBatchStyle.fontFamily,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      })
                    }
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    pArr = []
                  }
                  if (pushTtextIndex == item.characterSentences.length - 1) {
                    if (!pArr.length) {
                      pArr.push({
                        ...this.oldTextBatchStyle,
                        textAlign: this.textBatchStyle.textAlign,
                        fontFamily: this.textBatchStyle.fontFamily,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      })
                    }
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  }
                })
                brIds.forEach((brId, brPushIdx) => {
                  if (item.showTexts[brPushIdx + 1]) {
                    item.showTexts[brPushIdx + 1].pId =
                      brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                  }
                })
                item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                let selectIdx = -1
                if (this.startIdx >= 0 && this.endIdx >= 0) {
                  if (this.startIdx > this.endIdx) {
                    selectIdx = this.endIdx
                  } else {
                    selectIdx = this.startIdx
                  }
                }
                if (this.startIdx == -1 && deleArr.length) {
                  selectIdx = deleArr[0] + 1
                }
                this.$nextTick(() => {
                  let nodes = []
                  e.target.childNodes.forEach(p => {
                    p.childNodes.forEach(d => {
                      nodes.push(d)
                    })
                  })
                  if (selectIdx < nodes.length) {
                    window
                      .getSelection()
                      .getRangeAt(0)
                      .setStart(nodes[selectIdx - 1 >= 0 ? selectIdx - 1 : 0], selectIdx - 1 >= 0 ? selectIdx - 1 : 0)
                  } else {
                    window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                    window.getSelection().collapseToEnd()
                  }
                })
              })
            } else if (
              item.characterSentences.length &&
              item.characterSentences[item.characterSentences.length - 1] &&
              item.characterSentences[item.characterSentences.length - 1].characterText == ' ' &&
              item.characterSentences[item.characterSentences.length - 2] &&
              item.characterSentences[item.characterSentences.length - 2].characterText == '<br>'
            ) {
              /* item.characterSentences.pop()
          item.characterSentences.pop() */
              item.characterSentences.forEach((c, cIdx) => {
                if (c.characterText == '') {
                  item.characterSentences.splice(cIdx, 1)
                }
              })
              item.showText = item.characterSentences
                .map(c => c.characterText)
                .join('')
                .replaceAll('<br>', '\n')
              let arr = JSON.parse(JSON.stringify(item.characterSentences))
              item.characterSentences = []
              e.target.innerText = ''
              item.showTexts = []
              this.$nextTick(() => {
                item.characterSentences = arr
                let pArr = []
                let brIds = []
                item.brIndexs = []
                item.pIndexs = []
                item.characterSentences?.forEach((t, pushTtextIndex) => {
                  if (
                    item.characterSentences[pushTtextIndex + 1] &&
                    item.characterSentences[pushTtextIndex + 1].phonetic &&
                    item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                  ) {
                    item.pIndexs.push(pushTtextIndex)
                  }
                  if (t.characterText != '<br>' && t.characterText != '\n') {
                    pArr.push(t)
                  } else {
                    brIds.push(t.characterSentenceId)
                    item.brIndexs.push(pushTtextIndex)
                    if (pArr.length > 1) {
                      /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                    }
                    if (!pArr.length) {
                      pArr.push({
                        ...this.oldTextBatchStyle,
                        textAlign: this.textBatchStyle.textAlign,
                        fontFamily: this.textBatchStyle.fontFamily,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      })
                    }
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    pArr = []
                  }
                  if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  }
                })
                brIds.forEach((brId, brPushIdx) => {
                  if (item.showTexts[brPushIdx + 1]) {
                    item.showTexts[brPushIdx + 1].pId =
                      brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                  }
                })
                item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                this.$nextTick(() => {
                  if (deleArr.length) {
                    if (item.characterSentences[deleArr[0] - 1]) {
                      var range = window.getSelection().getRangeAt(0)
                      range.selectNode(
                        document.getElementById(
                          item.pageId +
                            '-' +
                            item.id +
                            '-' +
                            item.characterSentences[deleArr[0] - 1].characterSentenceId
                        )
                      )
                      range.collapse(false)
                      this.$emit(
                        'changeAnchorNode',
                        document.getElementById(
                          item.pageId +
                            '-' +
                            item.id +
                            '-' +
                            item.characterSentences[deleArr[0] - 1].characterSentenceId
                        )
                      )
                    } else if (item.characterSentences[deleArr[0]]) {
                      var range = window.getSelection().getRangeAt(0)
                      range.selectNode(
                        document.getElementById(
                          item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                        )
                      )
                      range.collapse(true)
                      this.$emit(
                        'changeAnchorNode',
                        document.getElementById(
                          item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                        )
                      )
                    } else {
                      window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                      window.getSelection().collapseToEnd()
                    }
                  } else {
                    window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                    window.getSelection().collapseToEnd()
                  }
                })
              })
            } else if (
              item.characterSentences.length &&
              item.characterSentences[item.characterSentences.length - 1] &&
              item.characterSentences[item.characterSentences.length - 1].characterText == ' ' &&
              item.characterSentences[item.characterSentences.length - 2] &&
              item.characterSentences[item.characterSentences.length - 2].characterText == '<br>' &&
              item.characterSentences[item.characterSentences.length - 3] &&
              item.characterSentences[item.characterSentences.length - 3].characterText != '<br>'
            ) {
              /* item.characterSentences.pop()
          item.characterSentences.pop() */
              item.characterSentences.forEach((c, cIdx) => {
                if (c.characterText == '') {
                  item.characterSentences.splice(cIdx, 1)
                }
              })
              item.showText = item.characterSentences
                .map(c => c.characterText)
                .join('')
                .replaceAll('<br>', '\n')
              let arr = JSON.parse(JSON.stringify(item.characterSentences))
              item.characterSentences = []
              e.target.innerText = ''
              item.showTexts = []
              this.$nextTick(() => {
                item.characterSentences = arr
                let pArr = []
                let brIds = []
                item.brIndexs = []
                item.pIndexs = []
                item.characterSentences?.forEach((t, pushTtextIndex) => {
                  if (
                    item.characterSentences[pushTtextIndex + 1] &&
                    item.characterSentences[pushTtextIndex + 1].phonetic &&
                    item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                  ) {
                    item.pIndexs.push(pushTtextIndex)
                  }
                  if (t.characterText != '<br>' && t.characterText != '\n') {
                    pArr.push(t)
                  } else {
                    brIds.push(t.characterSentenceId)
                    item.brIndexs.push(pushTtextIndex)
                    if (pArr.length > 1) {
                      /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                    }
                    if (!pArr.length) {
                      pArr.push({
                        ...this.oldTextBatchStyle,
                        textAlign: this.textBatchStyle.textAlign,
                        fontFamily: this.textBatchStyle.fontFamily,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      })
                    }
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    pArr = []
                  }
                  if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                    item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  }
                })
                brIds.forEach((brId, brPushIdx) => {
                  if (item.showTexts[brPushIdx + 1]) {
                    item.showTexts[brPushIdx + 1].pId =
                      brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                  }
                })
                item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                this.$nextTick(() => {
                  if (deleArr.length) {
                    if (item.characterSentences[deleArr[0] - 1]) {
                      var range = window.getSelection().getRangeAt(0)
                      range.selectNode(
                        document.getElementById(
                          item.pageId +
                            '-' +
                            item.id +
                            '-' +
                            item.characterSentences[deleArr[0] - 1].characterSentenceId
                        )
                      )
                      range.collapse(false)
                      this.$emit(
                        'changeAnchorNode',
                        document.getElementById(
                          item.pageId +
                            '-' +
                            item.id +
                            '-' +
                            item.characterSentences[deleArr[0] - 1].characterSentenceId
                        )
                      )
                    } else {
                      var range = window.getSelection().getRangeAt(0)
                      range.selectNode(
                        document.getElementById(
                          item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                        )
                      )
                      range.collapse(true)
                      this.$emit(
                        'changeAnchorNode',
                        document.getElementById(
                          item.pageId + '-' + item.id + '-' + item.characterSentences[deleArr[0]].characterSentenceId
                        )
                      )
                    }
                  } else {
                    window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                    window.getSelection().collapseToEnd()
                  }
                })
              })
            } else {
              if (item.characterSentences.length) {
                item.characterSentences.forEach((c, cIdx) => {
                  if (c.characterText == '') {
                    item.characterSentences.splice(cIdx, 1)
                  }
                })
                if (item.characterSentences.length && item.characterSentences[0].characterText == '<br>') {
                  item.characterSentences.unshift({
                    ...this.oldTextBatchStyle,
                    textAlign: this.textBatchStyle.textAlign,
                    fontFamily: this.textBatchStyle.fontFamily,
                    characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    characterText: ' '
                  })
                  item.showText = item.characterSentences
                    .map(c => c.characterText)
                    .join('')
                    .replaceAll('<br>', '\n')
                  let arr = JSON.parse(JSON.stringify(item.characterSentences))
                  item.characterSentences = []
                  e.target.innerText = ''
                  item.showTexts = []
                  this.$nextTick(() => {
                    item.characterSentences = arr
                    let pArr = []
                    let brIds = []
                    item.brIndexs = []
                    item.pIndexs = []
                    item.characterSentences?.forEach((t, pushTtextIndex) => {
                      if (
                        item.characterSentences[pushTtextIndex + 1] &&
                        item.characterSentences[pushTtextIndex + 1].phonetic &&
                        item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                      ) {
                        item.pIndexs.push(pushTtextIndex)
                      }
                      if (t.characterText != '<br>' && t.characterText != '\n') {
                        pArr.push(t)
                      } else {
                        brIds.push(t.characterSentenceId)
                        item.brIndexs.push(pushTtextIndex)
                        if (pArr.length > 1) {
                          /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                        }
                        if (!pArr.length) {
                          pArr.push({
                            ...this.oldTextBatchStyle,
                            textAlign: this.textBatchStyle.textAlign,
                            fontFamily: this.textBatchStyle.fontFamily,
                            characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                            characterText: ' '
                          })
                        }
                        item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                        pArr = []
                      }
                      if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                        item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                      }
                    })
                    brIds.forEach((brId, brPushIdx) => {
                      if (item.showTexts[brPushIdx + 1]) {
                        item.showTexts[brPushIdx + 1].pId =
                          brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                      }
                    })
                    item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                    this.$nextTick(() => {
                      window
                        .getSelection()
                        .getRangeAt(0)
                        .setStart(e.target.childNodes[1], 0)
                    })
                  })
                } else {
                  item.showText = item.characterSentences
                    .map(c => c.characterText)
                    .join('')
                    .replaceAll('<br>', '\n')
                  let pArr = []
                  let brIds = []
                  item.showTexts = []
                  item.brIndexs = []
                  item.pIndexs = []
                  item.characterSentences?.forEach((t, pushTtextIndex) => {
                    if (
                      item.characterSentences[pushTtextIndex + 1] &&
                      item.characterSentences[pushTtextIndex + 1].phonetic &&
                      item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                    ) {
                      item.pIndexs.push(pushTtextIndex)
                    }
                    if (t.characterText != '<br>' && t.characterText != '\n') {
                      pArr.push(t)
                    } else {
                      brIds.push(t.characterSentenceId)
                      item.brIndexs.push(pushTtextIndex)
                      if (pArr.length > 1) {
                        /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                      }
                      if (!pArr.length) {
                        pArr.push({
                          ...this.oldTextBatchStyle,
                          textAlign: this.textBatchStyle.textAlign,
                          fontFamily: this.textBatchStyle.fontFamily,
                          characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                          characterText: ' '
                        })
                      }
                      item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                      pArr = []
                    }
                    if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                      item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    }
                  })
                  brIds.forEach((brId, brPushIdx) => {
                    if (item.showTexts[brPushIdx + 1]) {
                      item.showTexts[brPushIdx + 1].pId =
                        brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                    }
                  })
                  item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                  this.$nextTick(() => {
                    if (deleArr.length) {
                      if (
                        item.characterSentences[deleArr[0] - 1] &&
                        item.characterSentences[deleArr[0] - 1].characterText != '<br>'
                      ) {
                        var range = window.getSelection().getRangeAt(0)
                        range.selectNode(
                          document.getElementById(
                            item.pageId +
                              '-' +
                              item.id +
                              '-' +
                              item.characterSentences[deleArr[0] - 1].characterSentenceId
                          )
                        )
                        range.collapse(false)
                        this.$emit(
                          'changeAnchorNode',
                          document.getElementById(
                            item.pageId +
                              '-' +
                              item.id +
                              '-' +
                              item.characterSentences[deleArr[0] - 1].characterSentenceId
                          )
                        )
                      } else {
                        if (item.characterSentences[deleArr[0]]) {
                          if (
                            item.characterSentences[deleArr[0]].characterText == '<br>' &&
                            item.characterSentences[deleArr[0] - 1] &&
                            item.characterSentences[deleArr[0] - 1].characterText == '<br>'
                          ) {
                            let selectIdx = item.showTexts.findIndex(
                              p =>
                                (p.pId + '').indexOf(item.characterSentences[deleArr[0]].characterSentenceId + '') > -1
                            )
                            let selectId = null
                            if (selectIdx > 0) {
                              selectId = item.showTexts[selectIdx - 1].texts[0].characterSentenceId
                            } else {
                              return
                            }
                            var range = window.getSelection().getRangeAt(0)
                            range.selectNode(document.getElementById(item.pageId + '-' + item.id + '-' + selectId))
                            range.collapse(true)
                            this.$emit(
                              'changeAnchorNode',
                              document.getElementById(
                                item.pageId +
                                  '-' +
                                  item.id +
                                  '-' +
                                  item.characterSentences[deleArr[0]].characterSentenceId
                              )
                            )
                          } else {
                            var range = window.getSelection().getRangeAt(0)
                            range.selectNode(
                              document.getElementById(
                                item.pageId +
                                  '-' +
                                  item.id +
                                  '-' +
                                  item.characterSentences[deleArr[0]].characterSentenceId
                              )
                            )
                            range.collapse(true)
                            this.$emit(
                              'changeAnchorNode',
                              document.getElementById(
                                item.pageId +
                                  '-' +
                                  item.id +
                                  '-' +
                                  item.characterSentences[deleArr[0]].characterSentenceId
                              )
                            )
                          }
                        } else {
                          window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                          window.getSelection().collapseToEnd()
                        }
                      }
                    } else {
                      window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                      window.getSelection().collapseToEnd()
                    }
                  })
                }
              } else {
                e.target.innerText = ''
                item.showTexts = []
                this.$nextTick(() => {
                  item.characterSentences.push({
                    ...this.oldTextBatchStyle,
                    textAlign: this.textBatchStyle.textAlign,
                    fontFamily: this.textBatchStyle.fontFamily,
                    characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    characterText: ''
                  })
                  let pArr = []
                  let brIds = []
                  item.brIndexs = []
                  item.pIndexs = []
                  item.characterSentences?.forEach((t, pushTtextIndex) => {
                    if (
                      item.characterSentences[pushTtextIndex + 1] &&
                      item.characterSentences[pushTtextIndex + 1].phonetic &&
                      item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                    ) {
                      item.pIndexs.push(pushTtextIndex)
                    }
                    if (t.characterText != '<br>' && t.characterText != '\n') {
                      pArr.push(t)
                    } else {
                      brIds.push(t.characterSentenceId)
                      item.brIndexs.push(pushTtextIndex)
                      if (pArr.length > 1) {
                        /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                      }
                      if (!pArr.length) {
                        pArr.push({
                          ...this.oldTextBatchStyle,
                          textAlign: this.textBatchStyle.textAlign,
                          fontFamily: this.textBatchStyle.fontFamily,
                          characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                          characterText: ' '
                        })
                      }
                      item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                      pArr = []
                    }
                    if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
                      item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    }
                  })
                  brIds.forEach((brId, brPushIdx) => {
                    if (item.showTexts[brPushIdx + 1]) {
                      item.showTexts[brPushIdx + 1].pId =
                        brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                    }
                  })
                  item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                })
              }
            }
            this.getYahooPhonetics(item.showText, item.pageId, item.id, item)
            this.selectionTexts = []
            this.$emit('clearPhoneticTexts')
          }, 0)
          return
        }
        if (e.ctrlKey) return
        if (e.code == 'Space') {
          if (this.inputStart) return
          this.inputBackspace = true
        }
      }
    },
    openTermBack(word) {
      this.$emit('topCenterMouseover')
      this.$emit('copyData')
      this.$emit('savePageList')
      this.$nextTick(() => {
        this.$emit('changeAnchorNodeBefore')
      })
      this.$emit('openTermBackEditModal', this.allTermBacks.find(w => w.backgroundBook.id == word.id).backgroundBook)
    },
    async feedbackItemEditEnter(e, feedbackItem, item) {
      //禁止回车的默认换行
      if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea) //获取光标位置
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          feedbackItem.feedback = textarea.value
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          feedbackItem.feedback = textarea.value
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
        //增加换行符
      } else if (e.keyCode == 13) {
        e.preventDefault()
        //陈立说的谁都可以反馈
        /*         if (this.taskDetail.stageName != '校对' && this.taskDetail.stageName != '终审') {
          this.$message.error('当前阶段无法反馈')
          return
        } */
        if (!feedbackItem.feedback) {
          this.$message.error('内容不能为空')
          return
        }
        let params = {
          feedback: feedbackItem.feedback,
          textAreaId: item.textAreaId,
          stageName: this.taskDetail.stageName,
          id: feedbackItem.id,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
        const res = await v1postAction('/textarea/feedback/edit', params)
        if (res.code == 200) {
          this.$message.success('编辑成功')
          this.$emit('getRevisionList')
        } else {
          this.$message.error(res.msg || res.errorMsg)
        }
      }
    },
    textInput(e, item, page) {
      if (this.selectionTexts.length) {
        if (this.inputStart) {
          let addText = this.inputValue
          if (this.startIdx > this.endIdx) {
            this.textChangeSim(e, item, this.endIdx, this.startIdx, addText)
          } else {
            this.textChangeSim(e, item, this.startIdx, this.endIdx, addText)
          }
        } else {
          if (!e.data) return
          let addText = e.data
          if (this.startIdx > this.endIdx) {
            this.textChangeSim2(e, item, this.endIdx, this.startIdx, addText)
          } else {
            this.textChangeSim2(e, item, this.startIdx, this.endIdx, addText)
          }
        }
      } else {
        let id = window.getSelection().anchorNode.parentNode.parentNode.id
        if (window.getSelection().anchorNode.parentNode.parentNode.className == 'mark') {
          id = window.getSelection().anchorNode.parentNode.parentNode.parentNode.id
        }
        let startIdx = item.characterSentences.findIndex(text => id == text.characterSentenceId)
        if (
          startIdx == -1 &&
          (id + '').indexOf('right') == -1 &&
          window.getSelection().anchorNode.parentNode.parentNode.parentNode.parentNode.previousSibling &&
          window.getSelection().anchorNode.parentNode.parentNode.parentNode.parentNode.previousSibling.tagName != 'IMG'
        ) {
          if (window.getSelection().anchorNode.parentNode.parentNode.parentNode.parentNode.previousSibling) {
            if (
              window.getSelection().anchorNode.parentNode.parentNode.parentNode.parentNode.previousSibling.lastChild
            ) {
              id = window.getSelection().anchorNode.parentNode.parentNode.parentNode.parentNode.previousSibling
                .lastChild.lastChild.id
              startIdx = item.characterSentences.findIndex(text => id == text.characterSentenceId)
              startIdx++
            }
          }
        }
        if (item.characterSentences[startIdx]) {
          let text = item.characterSentences[startIdx].characterText
          if (text) {
            if (window.getSelection().anchorNode.parentNode.innerText.indexOf(text) > 0) {
              startIdx = startIdx - 1
            }
          }
        }
        let endIdx = startIdx
        if (this.inputStart) {
          /*           let addText = this.inputValue
          if (
            window.getSelection().anchorOffset == 1 &&
            (!startIdx ||
              (item.characterSentences[startIdx] && addText == item.characterSentences[startIdx].characterText))
          ) {
            startIdx = startIdx - 1
            endIdx = startIdx
          }
          if (
            startIdx == -1 &&
            window.getSelection().anchorNode.parentNode.parentNode.parentNode.parentNode.tagName == 'P'
          ) {
            let id = window.getSelection().anchorNode.parentNode.parentNode.parentNode.parentNode.id.split('-')[2]
            startIdx = item.characterSentences.findIndex(t => t.characterSentenceId == id)
            endIdx = startIdx
          }
          if (startIdx > endIdx) {
            this.textChangeSim(e, item, endIdx, startIdx, addText)
          } else {
            this.textChangeSim(e, item, startIdx, endIdx, addText)
          } */
        } else {
          if (!e.data) return
          let addText = e.data
          if (
            window.getSelection().anchorOffset == addText.length &&
            (!startIdx ||
              (item.characterSentences[startIdx] && addText == item.characterSentences[startIdx].characterText))
          ) {
            startIdx = startIdx - 1
            endIdx = startIdx
          }
          if (startIdx > endIdx) {
            this.textChangeSim2(e, item, endIdx, startIdx, addText)
          } else {
            this.textChangeSim2(e, item, startIdx, endIdx, addText)
          }
        }
      }
    },
    textChangeSim2(e, item, startIdx, endIdx, addText) {
      this.addText = this.addText + addText
      this.textChangeSim1(e, item, startIdx, endIdx, this.addText)
    },
    textChangeSim1: simpleDebounce(async function(e, item, startIdx, endIdx, addText) {
      this.textChangeSim(e, item, startIdx, endIdx, addText)
      this.addText = ''
    }, 100),
    async textChangeSim(e, item, startIdx, endIdx, addText) {
      if (this.inputStart || !addText) return
      if (
        (item.characterSentences.length == 1 &&
          (item.characterSentences[0].characterText == '' || item.characterSentences[0].characterText == ' ')) ||
        !item.characterSentences.length
      ) {
        /* if (item.characterSentences.length > 1) return */
        /* console.log('空文本框正常输入', addText) */
        e.target.innerText = ''
        item.characterSentences = []
        item.showTexts = []
        this.$nextTick(() => {
          addText.split('').forEach((t, tIdx) => {
            item.characterSentences.push({
              ...this.oldTextBatchStyle,
              fontSize: this.textBatchStyle.fontSize,
              textAlign: this.textBatchStyle.textAlign,
              fontFamily: this.textBatchStyle.fontFamily,
              isBold: this.textBatchStyle.isBold ? 1 : 0,
              fontColor: this.textBatchStyle.fontColor || '#000000',
              characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime() + tIdx,
              characterText: t == '\n' ? '<br>' : t == '\r' ? ' ' : t
            })
          })
          this.changeShowText(item)
          this.$nextTick(() => {
            if (e.target.tagName == 'SPAN' && e.target.parentNode.parentNode.parentNode.tagName == 'P') {
              let id = e.target.parentNode.parentNode.parentNode.id
              window
                .getSelection()
                .selectAllChildren(
                  document.getElementById(id.split('-')[0] + '-' + id.split('-')[1] + '-' + 'right').firstChild
                    .lastChild.lastChild
                )
              window.getSelection().collapseToEnd()
              this.$emit(
                'changeAnchorNode',
                document.getElementById(id.split('-')[0] + '-' + id.split('-')[1] + '-' + 'right').firstChild.lastChild
                  .lastChild
              )
              return
            }
            if (e.target.lastChild && e.target.lastChild.lastChild) {
              window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
              window.getSelection().collapseToEnd()
              this.$emit('changeAnchorNode', e.target.lastChild.lastChild)
            }
          })
        })
        return
      }
      let range = window.getSelection().getRangeAt(0)
      /* if (startIdx == 0 && endIdx == 0 && range.endOffset == addText.length) {
        startIdx = -1
        endIdx = -1
      } */
      if (!this.selectionTexts.length) {
        if (
          item.characterSentences[startIdx] &&
          item.characterSentences[startIdx].characterText == '<br>' &&
          item.characterSentences[startIdx + 1] &&
          item.characterSentences[startIdx + 1].characterText == ' '
        ) {
          item.characterSentences.splice(startIdx + 1, 1)
        }
        /*         if (
          item.characterSentences[startIdx] &&
          item.characterSentences[startIdx].characterText == ' ' &&
          item.characterSentences[startIdx - 1] &&
          item.characterSentences[startIdx - 1].characterText == '<br>'
        ) {
          item.characterSentences.splice(startIdx, 1)
        } */
        if (
          addText == ' ' &&
          item.characterSentences[0] &&
          item.characterSentences[1] &&
          item.characterSentences[0] == ' ' &&
          item.characterSentences[1] != ' '
        ) {
          addText = '  '
        } else if (
          addText == ' ' &&
          item.characterSentences[0] &&
          !item.characterSentences[1] &&
          item.characterSentences[0] == ' '
        ) {
          addText = '  '
        }
        addText.split('').forEach((t, tIdx) => {
          item.characterSentences.splice(startIdx + tIdx + 1, 0, {
            ...this.oldTextBatchStyle,
            fontSize: this.isChangeFontSize
              ? this.textBatchStyle.fontSize
              : item.characterSentences[startIdx + tIdx]
              ? item.characterSentences[startIdx + tIdx].fontSize
              : item.characterSentences[startIdx + tIdx + 1]
              ? item.characterSentences[startIdx + tIdx + 1].fontSize
              : this.oldTextBatchStyle.fontSize,
            textAlign: this.textBatchStyle.textAlign,
            fontFamily: this.textBatchStyle.fontFamily,
            isBold: this.textBatchStyle.isBold ? 1 : 0,
            fontColor: this.textBatchStyle.fontColor || '#000000',
            characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime() + tIdx,
            characterText: t == '\n' ? '<br>' : t == '\r' ? ' ' : t
          })
        })
        if (
          item.characterSentences[startIdx + addText.length + 1] &&
          item.characterSentences[startIdx + addText.length + 1].characterText == ' ' &&
          item.characterSentences[startIdx + addText.length + 2] &&
          item.characterSentences[startIdx + addText.length + 2].characterText == '<br>'
        ) {
          item.characterSentences.splice(startIdx + addText.length + 1, 1)
        }
        if (
          addText.indexOf(' ') == -1 &&
          item.characterSentences[startIdx] &&
          item.characterSentences[startIdx].characterText == ' ' &&
          item.characterSentences[startIdx - 1] &&
          item.characterSentences[startIdx - 1].characterText == '<br>'
        ) {
          item.characterSentences.splice(startIdx, 1)
        }
        let arr = JSON.parse(JSON.stringify(item.characterSentences))
        if (this.isCopy) {
          e.target.innerText = ''
        }
        item.characterSentences = []
        item.showTexts = []
        this.$nextTick(() => {
          item.characterSentences = arr
          this.changeShowText(item)
          this.$nextTick(() => {
            if (endIdx > startIdx) {
              if (item.characterSentences[endIdx + addText.length]) {
                range.selectNode(
                  document.getElementById(
                    item.pageId +
                      '-' +
                      item.id +
                      '-' +
                      item.characterSentences[endIdx + addText.length].characterSentenceId
                  )
                )
                range.collapse(false)
                this.$emit(
                  'changeAnchorNode',
                  document.getElementById(
                    item.pageId +
                      '-' +
                      item.id +
                      '-' +
                      item.characterSentences[endIdx + addText.length].characterSentenceId
                  )
                )
              } else {
                window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                window.getSelection().collapseToEnd()
              }
            } else {
              if (item.characterSentences[startIdx + addText.length]) {
                range.selectNode(
                  document.getElementById(
                    item.pageId +
                      '-' +
                      item.id +
                      '-' +
                      item.characterSentences[startIdx + addText.length].characterSentenceId
                  )
                )
                range.collapse(false)
                this.$emit(
                  'changeAnchorNode',
                  document.getElementById(
                    item.pageId +
                      '-' +
                      item.id +
                      '-' +
                      item.characterSentences[startIdx + addText.length].characterSentenceId
                  )
                )
              } else {
                if (e.target.lastChild && e.target.lastChild.lastChild) {
                  window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                  window.getSelection().collapseToEnd()
                }
              }
            }
          })
        })
      } else {
        let selectLength = this.selectionTexts.length
        if (startIdx < endIdx) {
          for (let i = startIdx; i <= endIdx; i++) {
            item.characterSentences.splice(startIdx, 1)
          }
          addText.split('').forEach((t, tIdx) => {
            item.characterSentences.splice(startIdx + tIdx, 0, {
              ...this.oldTextBatchStyle,
              fontSize: this.isChangeFontSize
                ? this.textBatchStyle.fontSize
                : item.characterSentences[startIdx + tIdx]
                ? item.characterSentences[startIdx + tIdx].fontSize
                : item.characterSentences[startIdx + tIdx + 1]
                ? item.characterSentences[startIdx + tIdx + 1].fontSize
                : this.oldTextBatchStyle.fontSize,
              textAlign: this.textBatchStyle.textAlign,
              fontFamily: this.textBatchStyle.fontFamily,
              isBold: this.textBatchStyle.isBold ? 1 : 0,
              fontColor: this.textBatchStyle.fontColor || '#000000',
              characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime() + tIdx,
              characterText: t == '\n' ? '<br>' : t == '\r' ? ' ' : t
            })
          })
          let arr = JSON.parse(JSON.stringify(item.characterSentences))
          e.target.innerText = ''
          item.characterSentences = []
          item.showTexts = []
          this.$nextTick(() => {
            item.characterSentences = arr
            this.changeShowText(item)
            this.$nextTick(() => {
              if (endIdx > startIdx) {
                if (item.characterSentences[endIdx + addText.length - selectLength]) {
                  range.selectNode(
                    document.getElementById(
                      item.pageId +
                        '-' +
                        item.id +
                        '-' +
                        item.characterSentences[endIdx + addText.length - selectLength].characterSentenceId
                    )
                  )
                  range.collapse(false)
                  this.$emit(
                    'changeAnchorNode',
                    document.getElementById(
                      item.pageId +
                        '-' +
                        item.id +
                        '-' +
                        item.characterSentences[endIdx + addText.length - selectLength].characterSentenceId
                    )
                  )
                } else {
                  window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                  window.getSelection().collapseToEnd()
                }
              } else {
                if (item.characterSentences[startIdx + addText.length - selectLength]) {
                  range.selectNode(
                    document.getElementById(
                      item.pageId +
                        '-' +
                        item.id +
                        '-' +
                        item.characterSentences[startIdx + addText.length - selectLength].characterSentenceId
                    )
                  )
                  this.$emit(
                    'changeAnchorNode',
                    document.getElementById(
                      item.pageId +
                        '-' +
                        item.id +
                        '-' +
                        item.characterSentences[startIdx + addText.length - selectLength].characterSentenceId
                    )
                  )
                  range.collapse(false)
                } else {
                  window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                  window.getSelection().collapseToEnd()
                }
              }
            })
          })
        } else {
          for (let i = endIdx; i <= startIdx; i++) {
            item.characterSentences.splice(endIdx, 1)
          }
          addText.split('').forEach((t, tIdx) => {
            item.characterSentences.splice(endIdx + tIdx, 0, {
              ...this.oldTextBatchStyle,
              textAlign: this.textBatchStyle.textAlign,
              fontFamily: this.textBatchStyle.fontFamily,
              isBold: this.textBatchStyle.isBold ? 1 : 0,
              fontColor: this.textBatchStyle.fontColor || '#000000',
              characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
              characterText: t == '\n' ? '<br>' : t == '\r' ? ' ' : t
            })
          })

          let arr = JSON.parse(JSON.stringify(item.characterSentences))
          e.target.innerText = ''
          item.characterSentences = []
          item.showTexts = []
          this.$nextTick(() => {
            item.characterSentences = arr
            this.changeShowText(item)
            this.$nextTick(() => {
              if (endIdx > startIdx) {
                if (item.characterSentences[endIdx + addText.length - selectLength]) {
                  range.selectNode(
                    document.getElementById(
                      item.pageId +
                        '-' +
                        item.id +
                        '-' +
                        item.characterSentences[endIdx + addText.length - selectLength].characterSentenceId
                    )
                  )
                  range.collapse(false)
                  this.$emit(
                    'changeAnchorNode',
                    document.getElementById(
                      item.pageId +
                        '-' +
                        item.id +
                        '-' +
                        item.characterSentences[endIdx + addText.length - selectLength].characterSentenceId
                    )
                  )
                } else {
                  window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                  window.getSelection().collapseToEnd()
                }
              } else {
                if (item.characterSentences[startIdx + addText.length - selectLength]) {
                  range.selectNode(
                    document.getElementById(
                      item.pageId +
                        '-' +
                        item.id +
                        '-' +
                        item.characterSentences[startIdx + addText.length - selectLength].characterSentenceId
                    )
                  )
                  range.collapse(false)
                  if (
                    document.getElementById(
                      item.pageId +
                        '-' +
                        item.id +
                        '-' +
                        item.characterSentences[endIdx + addText.length - selectLength].characterSentenceId
                    )
                  ) {
                    this.$emit(
                      'changeAnchorNode',
                      document.getElementById(
                        item.pageId +
                          '-' +
                          item.id +
                          '-' +
                          item.characterSentences[endIdx + addText.length - selectLength].characterSentenceId
                      )
                    )
                  } else {
                    window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                    window.getSelection().collapseToEnd()
                  }
                } else {
                  window.getSelection().selectAllChildren(e.target.lastChild.lastChild)
                  window.getSelection().collapseToEnd()
                }
              }
            })
          })
        }
      }
      this.selectionString = ''
      this.selectionTexts = []
      this.$emit('clearPhoneticTexts')
    },
    changeShowText(item) {
      /*       if (
        item.characterSentences[item.characterSentences.length - 1] &&
        item.characterSentences[item.characterSentences.length - 1].characterText == ' '
      ) {
        item.characterSentences.pop()
      } */
      if (item.characterSentences.length) {
        item.characterSentences.forEach((c, cIdx) => {
          if (c.characterText == '') {
            item.characterSentences.splice(cIdx, 1)
          }
        })
        if (this.isCopy) {
          if (!this.inputBackspace) {
            if (
              item.characterSentences[item.characterSentences.length - 1] &&
              item.characterSentences[item.characterSentences.length - 1].characterText == ' ' &&
              item.characterSentences[item.characterSentences.length - 2] &&
              item.characterSentences[item.characterSentences.length - 2].characterText == ' ' &&
              !this.inputBackspace
            ) {
              item.characterSentences.pop()
            }
          }
        } else {
          if (!this.inputBackspace) {
            if (
              item.characterSentences[item.characterSentences.length - 1] &&
              item.characterSentences[item.characterSentences.length - 1].characterText == ' ' &&
              item.characterSentences[item.characterSentences.length - 2] &&
              item.characterSentences[item.characterSentences.length - 2].characterText == ' ' &&
              !this.inputBackspace
            ) {
              item.characterSentences.pop()
            }
          }
        }
        this.isCopy = false
        this.inputBackspace = false
        let pArr = []
        let brIds = []
        item.brIndexs = []
        item.pIndexs = []
        item.characterSentences?.forEach((t, pushTtextIndex) => {
          if (t.characterText == ' ' || t.characterText == '<br>') {
            if (item.characterSentences[0]) t.isBold = item.characterSentences[0].isBold
          }
          if (
            item.characterSentences[pushTtextIndex + 1] &&
            item.characterSentences[pushTtextIndex + 1].phonetic &&
            item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
          ) {
            item.pIndexs.push(pushTtextIndex)
          }
          if (t.characterText != '<br>' && t.characterText != '\n') {
            pArr.push(t)
          } else {
            brIds.push(t.characterSentenceId)
            item.brIndexs.push(pushTtextIndex)
            if (pArr.length > 1) {
              /* pArr = pArr.filter(pt => pt.characterText != ' ') */
            }
            if (!pArr.length) {
              pArr.push({
                ...this.oldTextBatchStyle,
                textAlign: this.textBatchStyle.textAlign,
                fontFamily: this.textBatchStyle.fontFamily,
                characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                characterText: ' '
              })
            }
            item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
            pArr = []
          }
          if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
            item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
          }
        })
        if (!item.showTexts.length) {
          item.showTexts.push({
            pId: '',
            texts: [
              {
                ...this.oldTextBatchStyle,
                textAlign: this.textBatchStyle.textAlign,
                isBold: this.textBatchStyle.isBold ? 1 : 0,
                fontColor: this.textBatchStyle.fontColor || '#000000',
                fontFamily: this.textBatchStyle.fontFamily,
                characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                characterText: ' '
              }
            ],
            pushTtextIndex: 0
          })
        }

        brIds.forEach((brId, brPushIdx) => {
          if (item.showTexts[brPushIdx + 1]) {
            item.showTexts[brPushIdx + 1].pId = brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
          }
        })
        item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
        item.showText = item.characterSentences
          .map(c => c.characterText)
          .join('')
          .replaceAll('<br>', '\n')
        this.getYahooPhonetics(item.showText, item.pageId, item.id, item)
        this.$emit('getTermsBack')
      }
    },
    leftOut(e, item) {
      if (item.textAreaRegion.selected) {
        e.target.style.cursor = 'default'
        this.isDelete = false
      }
    },
    leftMove(e, item) {
      if (
        item.textAreaRegion.selected &&
        e.target.className &&
        typeof e.target.className == 'string' &&
        e.target.className.indexOf('ocr-show-item') > -1
      ) {
        this.isDelete = true
        let width = e.target.clientWidth
        let height = e.target.clientHeight
        let offsetX = e.offsetX
        let offsetY = e.offsetY
        if (offsetX <= 10 || offsetX >= width - 10 || (offsetY <= 10 && offsetY > 0) || offsetY >= height - 10) {
          e.target.style.cursor = 'move'
          this.isDelete = false
        } else {
          e.target.style.cursor = 'default'
          this.isDelete = true
        }
      } else if (
        item.textAreaRegion.selected &&
        e.target.className &&
        typeof e.target.className == 'string' &&
        e.target.className.indexOf('ocr-show-item') == -1
      ) {
        e.target.style.cursor = 'default'
        this.isDelete = true
      }
    },
    rightMove(e, item) {
      if (item.textAreaRegion.selected && !item.translateTextAreaRegion.editble) {
        this.isDelete = true
        let width = e.target.clientWidth
        let height = e.target.clientHeight
        let offsetX = e.offsetX
        let offsetY = e.offsetY
        if (offsetX <= 10 || offsetX >= width - 10 || (offsetY <= 10 && offsetY > 0) || offsetY >= height - 10) {
          e.target.style.cursor = 'move'
          this.isDelete = false
        } else {
          e.target.style.cursor = 'default'
          this.isDelete = true
        }
      }
      if (item.textAreaRegion.selected && e.target.className && e.target.className.indexOf('ocr-show-item') == -1) {
        e.target.style.cursor = 'default'
        this.isDelete = true
      }
      if (!item.translateTextAreaRegion.editble) return
      if (this.isSelection) {
        e.preventDefault()
        this.endDom = document.getElementById(item.characterSentences[0].characterSentenceId)
        if (!window.getSelection().anchorNode) return
        if (this.startDom.getClientRects()[0].top < e.clientY) {
          this.endDom = document.getElementById(
            item.characterSentences[item.characterSentences.length - 1].characterSentenceId
          )
          window
            .getSelection()
            .getRangeAt(0)
            .setStart(this.startDom, 0)
          window
            .getSelection()
            .getRangeAt(0)
            .setEndAfter(this.endDom, 1)
        } else {
          this.endDom = document.getElementById(item.characterSentences[0].characterSentenceId)
          window
            .getSelection()
            .getRangeAt(0)
            .setStart(this.endDom, 0)
          window
            .getSelection()
            .getRangeAt(0)
            .setEndAfter(this.startDom, 1)
        }
        this.selectTextArea = item
      }
    },
    stopMouseMove(e) {
      if (this.reSizeLine || this.moveLine) return
      e.stopPropagation()
    },
    disMove(e, item) {
      if (this.reSizeLine || this.moveLine) return
      e.stopPropagation()
      if (!item.translateTextAreaRegion.editble) return
      if (this.isSelection) {
        e.preventDefault()
        if (!window.getSelection().anchorNode) return
        if (this.startDom.getClientRects()[0] && this.startDom.getClientRects()[0].top < e.clientY) {
          this.endDom = document.getElementById(
            item.characterSentences[item.characterSentences.length - 1].characterSentenceId
          )
          window
            .getSelection()
            .getRangeAt(0)
            .setStart(this.startDom, 0)
          window
            .getSelection()
            .getRangeAt(0)
            .setEndAfter(this.endDom, 1)
        } else {
          this.endDom = document.getElementById(item.characterSentences[0].characterSentenceId)
          window
            .getSelection()
            .getRangeAt(0)
            .setStart(this.endDom, 0)
          /*         if (this.startDom.parentNode.previousElementSibling) {
          window
            .getSelection()
            .getRangeAt(0)
            .setEndAfter(this.endDom.parentNode.previousElementSibling.childNodes[0], 0)
        } else {
          window
            .getSelection()
            .getRangeAt(0)
            .setEndAfter(this.endDom, 1)
        } */
          window
            .getSelection()
            .getRangeAt(0)
            .setEndAfter(this.startDom, 1)
        }
        this.selectTextArea = item
      }
    },
    disMovePage(e, page) {
      if (this.isSelection) {
        e.preventDefault()
        if (!window.getSelection().anchorNode || !this.selectTextArea) return
        if (this.startDom.getClientRects()[0].top < e.clientY) {
          this.endDom = document.getElementById(
            this.selectTextArea.characterSentences[this.selectTextArea.characterSentences.length - 1]
              .characterSentenceId
          )
          window
            .getSelection()
            .getRangeAt(0)
            .setStart(this.startDom, 0)
          window
            .getSelection()
            .getRangeAt(0)
            .setEndAfter(this.endDom, 1)
        } else {
          this.endDom = document.getElementById(this.selectTextArea.characterSentences[0].characterSentenceId)
          window
            .getSelection()
            .getRangeAt(0)
            .setStart(this.endDom, 0)
          window
            .getSelection()
            .getRangeAt(0)
            .setEndAfter(this.startDom, 1)
        }
      }
    },
    characterClickBefore(e, item) {
      if (item.translateTextAreaRegion.editble && !this.isSelection) {
        if (!window.getSelection().anchorNode) return
        var range = window.getSelection().getRangeAt(0)
        if (item.textLayout == 1) {
          if (e.clientX - e.target.getClientRects()[0].x > (e.target.offsetWidth / 2).toFixed(0) - 0) {
            e.preventDefault()
            range.selectNode(e.target.parentNode)
            range.collapse(false)
          } else {
            e.preventDefault()
            range.selectNode(e.target.parentNode)
            range.collapse(true)
            this.$emit('changeAnchorNodeBefore')
          }
        } else {
          if (e.clientY - e.target.getClientRects()[0].y > (e.target.offsetHeight / 2).toFixed(0) - 0) {
            e.preventDefault()
            range.selectNode(e.target.parentNode)
            range.collapse(false)
          } else {
            e.preventDefault()
            range.selectNode(e.target.parentNode)
            range.collapse(true)
            this.$emit('changeAnchorNodeBefore')
          }
        }

        this.startDom = e.target.parentNode
        this.$emit('changeAnchorNode', e.target.parentNode)
        this.isSelection = true
      }
    },
    characterUp(e) {
      if (e.button == 2) {
        this.$emit('changeAllAdd')
      }
      this.endDom = null
      this.isSelection = false
      const timeOut = setTimeout(() => {
        this.selectTextArea = null
        clearTimeout(timeOut)
      }, 50)
    },
    characterClick(e, item) {
      if (this.reSizeLine || this.moveLine) return
      e.stopPropagation()
      if (item.translateTextAreaRegion.editble) {
        if (this.isSelection) {
          e.preventDefault()
          if (!window.getSelection().anchorNode) return
          window
            .getSelection()
            .getRangeAt(0)
            .selectNode(this.startDom)
          this.endDom = e.target.parentNode
          let startIdx = -1
          let endIdx = -1
          if (e.target.id) {
            startIdx = item.characterSentences.findIndex(c => c.characterSentenceId == this.startDom.id)
            endIdx = item.characterSentences.findIndex(c => c.characterSentenceId == this.endDom.id)
          } else {
            startIdx = item.characterSentences.findIndex(c => c.characterSentenceId == this.startDom.parentNode.id)
            endIdx = item.characterSentences.findIndex(c => c.characterSentenceId == this.endDom.parentNode.id)
          }
          if (endIdx >= startIdx) {
            /* console.log('后') */
            window
              .getSelection()
              .getRangeAt(0)
              .setStart(this.startDom, 0)
            window
              .getSelection()
              .getRangeAt(0)
              .setEndAfter(this.endDom, 1)
          } else {
            /* console.log('前') */
            window
              .getSelection()
              .getRangeAt(0)
              .setStart(this.endDom, 0)
            window
              .getSelection()
              .getRangeAt(0)
              .setEndAfter(this.startDom, 0)
          }
          this.selectTextArea = item
        }
      }
    },
    textClick(item, ciickType, page) {
      /*       Array.from(this.$refs[page.id + '-' + item.id + '-' + 'editDiv'][0].childNodes).forEach(node => {
        if (item.characterSentences.length == 1 && item.characterSentences[0].characterText == '') {
          if (node && node.nodeValue) {
            let text = node.nodeValue
            node.nodeValue = ''
            item.characterSentences = []
            for (let i = 0; i < text; i++) {
              if (!text[i]) break
              item.characterSentences.push({
                ...this.oldTextBatchStyle,
                characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                characterText: text[i]
              })
            }
          } else {
            return
          }
        } else if (node.tagName == 'RUBY') {
          if (item.characterSentences.every(text => text.characterText.indexOf(node.innerText) == -1)) {
            let index = item.characterSentences.findIndex(text => text.characterSentenceId == node.id.split('-')[2])
            let findText = item.characterSentences[index].characterText
            let findItem = item.characterSentences[index]
            let oldItem = item.characterSentences[index]
            if (this.inputDirection) {
              if (index - 1 >= 0) {
                findItem = item.characterSentences[index - 1]
              }
              let isPush = false
              let copyText = node.innerText
              for (let i = 0; i < node.innerText.length; i++) {
                let nodeText = copyText[i]
                if (nodeText != '\n') {
                  if (findText.indexOf(nodeText) == -1) {
                    item.characterSentences.splice(index, 0, {
                      ...findItem,
                      characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                      characterText: nodeText
                    })
                  } else {
                    if (!isPush) {
                      this.$nextTick(() => {
                        let deleteIdx = item.characterSentences.findIndex(
                          text => text.characterSentenceId == node.id.split('-')[2]
                        )
                        item.characterSentences.splice(deleteIdx, 1)
                        item.characterSentences.splice(deleteIdx, 0, {
                          ...oldItem
                        })
                        node.childNodes[0].innerText = findText
                      })
                    } else {
                      item.characterSentences.splice(index, 0, {
                        ...findItem,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: nodeText
                      })
                    }

                    isPush = true
                  }
                } else {
                  item.characterSentences.splice(index, 0, {
                    ...this.oldTextBatchStyle,
                    characterSentenceId: node.id + '-' + i,
                    characterText: '<br>'
                  })
                }
              }
            } else {
              let isPush = false
              let copyText = node.innerText
              for (let i = node.innerText.length - 1; i >= 0; i--) {
                let nodeText = copyText[i]
                if (nodeText != '\n') {
                  if (findText.indexOf(nodeText) == -1) {
                    item.characterSentences.splice(index, 0, {
                      ...findItem,
                      characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                      characterText: nodeText
                    })
                  } else {
                    if (!isPush) {
                      this.$nextTick(() => {
                        let deleteIdx = item.characterSentences.findIndex(
                          text => text.characterSentenceId == node.id.split('-')[2]
                        )
                        item.characterSentences.splice(deleteIdx, 1)
                        item.characterSentences.splice(index, 0, {
                          ...oldItem
                        })
                        node.childNodes[0].innerText = findText
                      })
                    } else {
                      item.characterSentences.splice(index, 0, {
                        ...findItem,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: nodeText
                      })
                    }
                    isPush = true
                  }
                } else {
                  item.characterSentences.splice(index, 0, {
                    ...this.oldTextBatchStyle,
                    characterSentenceId: node.id + '-' + i,
                    characterText: '<br>'
                  })
                }
              }
            }
          }
        }
      })
      this.inputDirection = 0 */
    },
    changeErrorValue(value) {
      this.errorValue = value
    },
    errorChange(item) {
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && this.taskDetail.rejectTaskId) return
      this.$emit('changeError', { value: this.errorValue, type: this.selectErrorType, item })
      /* this.selectErrorType = 0 */
      this.errorValue = undefined
    },
    errorChangeBottom(item) {
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && this.taskDetail.rejectTaskId) return
      this.$emit('changeError', { value: this.errorValueBottom, type: this.selectErrorTypeBottom, item })
      /* this.selectErrorTypeBottom = 0 */
      this.errorValueBottom = undefined
    },
    async errorEnter(e, item, selectErrorType) {
      //禁止回车的默认换行
      if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea) //获取光标位置
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          this.errorValue = textarea.value
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          this.errorValue = textarea.value
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
        //增加换行符
      } else if (e.keyCode == 13) {
        e.preventDefault()
        if (!selectErrorType) {
          let params = {
            feedback: this.errorValue,
            textAreaId: item.id,
            stageName: this.taskDetail.stageName,
            replyId: null,
            userId: this.userInfo.id,
            username: this.userInfo.nickName,
            avatar: this.userInfo.userFile.avatar
          }
          const res = await v1postAction('/textarea/feedback/edit', params)
          if (res.code == 200) {
            this.errorValue = ''
            this.selectErrorType = 0
            this.$message.success('反馈成功')
            this.getRevisionList(item)
            this.$emit('getRevisionList')
          } else {
            this.$message.error(res.msg || res.errorMsg)
          }
          this.errorValue = ''
          return
        }
        if (this.taskDetail.stageName != '校对' && this.taskDetail.stageName != '终审') {
          this.$message.error('当前阶段无法驳回')
          return
        }
        let params = {
          productionId: this.taskDetail.productionId,
          chapterId: this.taskDetail.chapterId,
          pageId: item.pageId,
          textAreaId: item.textAreaId,
          taskId: this.taskDetail.taskId,
          comment: this.errorValue,
          rejectType: this.taskDetail.stageName == '校对' ? 1 : 2,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
        const res = await v1postAction('/textarea/reject', params)
        if (res.code == 1) {
          this.errorValue = ''
          /* this.selectErrorType = 0 */
          item.isReject = true
          this.$message.success('驳回成功')
          this.$forceUpdate()
          /* this.getRevision(item) */
          this.getRevisionList(item)
          this.$emit('getRevisionList')
        } else {
          this.$message.error(res.msg || res.errorMsg)
        }
        /*         this.$emit('saveLocal', { type, item, value: this.errorValue })
        this.$forceUpdate() */
      }
    },
    async postRejectValue(item) {
      if (this.taskDetail.stageName != '校对' && this.taskDetail.stageName != '终审') {
        this.$message.error('当前阶段无法驳回')
        return
      }
      let params = {
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        pageId: item.pageId,
        textAreaId: item.textAreaId,
        taskId: this.taskDetail.taskId,
        comment: this.errorValue,
        rejectType: this.taskDetail.stageName == '校对' ? 1 : 2,
        userId: this.userInfo.id,
        username: this.userInfo.nickName,
        avatar: this.userInfo.userFile.avatar
      }
      const res = await v1postAction('/textarea/reject', params)
      if (res.code == 1) {
        this.errorValue = ''
        item.isReject = true
        this.$message.success('驳回成功')
        this.$forceUpdate()
        this.getRevisionList(item)
        this.$emit('getRevisionList')
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    topCenterMouseout() {
      this.$emit('topCenterMouseout')
    },
    async errorEnterBottom(e, item, selectErrorType) {
      //禁止回车的默认换行
      if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea) //获取光标位置
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          this.errorValueBottom = textarea.value
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          this.errorValueBottom = textarea.value
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
        //增加换行符
      } else if (e.keyCode == 13) {
        e.preventDefault()
        if (!selectErrorType) {
          let params = {
            feedback: this.errorValueBottom,
            textAreaId: item.id,
            stageName: this.taskDetail.stageName,
            replyId: null,
            userId: this.userInfo.id,
            username: this.userInfo.nickName,
            avatar: this.userInfo.userFile.avatar
          }
          const res = await v1postAction('/textarea/feedback/edit', params)
          if (res.code == 200) {
            this.errorValueBottom = ''
            this.selectErrorTypeBottom = 0
            this.$message.success('反馈成功')
            this.getRevisionList(item)
            this.$emit('getRevisionList')
          } else {
            this.$message.error(res.msg || res.errorMsg)
          }
          this.errorValueBottom = ''
          return
        }
        if (this.taskDetail.stageName != '校对' && this.taskDetail.stageName != '终审') {
          this.$message.error('当前阶段无法驳回')
          return
        }
        let params = {
          productionId: this.taskDetail.productionId,
          chapterId: this.taskDetail.chapterId,
          pageId: item.pageId,
          textAreaId: item.textAreaId,
          taskId: this.taskDetail.taskId,
          comment: this.errorValueBottom,
          rejectType: this.taskDetail.stageName == '校对' ? 1 : 2,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
        const res = await v1postAction('/textarea/reject', params)
        if (res.code == 1) {
          this.errorValueBottom = ''
          /* this.selectErrorType = 0 */
          item.isReject = true
          this.$message.success('驳回成功')
          this.$forceUpdate()
          /* this.getRevision(item) */
          this.getRevisionList(item)
          this.$emit('getRevisionList')
        } else {
          this.$message.error(res.msg || res.errorMsg)
        }
        /*         this.$emit('saveLocal', { type, item, value: this.errorValue })
        this.$forceUpdate() */
      }
    },
    //陈立说后续不会追加新功能，使用回车发送，alt回车换行
    localEnter(e, item, type) {
      /*       if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea)
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          if (type == 'left') {
            this.localLeftValue = textarea.value
          } else {
            this.localRightValue = textarea.value
          }
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          if (type == 'left') {
            this.localLeftValue = textarea.value
          } else {
            this.localRightValue = textarea.value
          }
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
      } else if (e.keyCode == 13) {
        e.preventDefault()
        let localValue = type == 'left' ? this.localLeftValue : this.localRightValue
        if (!localValue) return
        if (type == 'left') {
          this.$emit('saveLocal', { type, item, value: this.localLeftValue })
        } else {
          this.$emit('saveLocal', { type, item, value: this.localRightValue })
        }
        this.localLeftValue = ''
        this.localRightValue = ''
      } */
      let localValue = type == 'left' ? this.localLeftValue : this.localRightValue
      if (!localValue) return
      if (type == 'left') {
        this.$emit('saveLocal', { type, item, value: this.localLeftValue })
      } else {
        this.$emit('saveLocal', { type, item, value: this.localRightValue })
      }
      this.localLeftValue = ''
      this.localRightValue = ''
    },
    getDateYYYYMMddHHMMSS(format) {
      const date = new Date()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const strDate = date
        .getDate()
        .toString()
        .padStart(2, '0')
      const starHours = date
        .getHours()
        .toString()
        .padStart(2, '0')
      const starMinutes = date
        .getMinutes()
        .toString()
        .padStart(2, '0')
      const starSeconds = date
        .getSeconds()
        .toString()
        .padStart(2, '0')
      format = `${date.getFullYear()}-${month}-${strDate}
    ${starHours}:${starMinutes}:${starSeconds}`
      return format
    },
    textMouseup(e) {
      this.textAreaMouseup()
      if (window.getSelection().toString()) {
        if (this.isSelection) {
          e.preventDefault()
        }
        let selection = window.getSelection()
        this.selectionString = window.getSelection().toString()
        this.selectionTexts = []
        let anchorNodeId = null
        let extentNodeId = null
        if (!this.selectTextAreaData.length) return
        anchorNodeId = selection.anchorNode.id
        extentNodeId = selection.extentNode.id
        if (!selection.anchorNode.id) {
          anchorNodeId = selection.anchorNode.parentNode.id
          if (!selection.anchorNode.parentNode.id) {
            anchorNodeId = selection.anchorNode.parentNode.parentNode.id
          }
        } else {
          if ((anchorNodeId + '').indexOf('text') > -1) {
            anchorNodeId = anchorNodeId.split('-')[0]
          }
          if ((anchorNodeId + '').indexOf('-') > -1) {
            anchorNodeId = anchorNodeId.split('-')[2]
          }
        }
        if (!selection.extentNode.id) {
          extentNodeId = selection.extentNode.parentNode.id
          if (!selection.extentNode.parentNode.id) {
            extentNodeId = selection.extentNode.parentNode.parentNode.id
          }
        } else {
          if ((extentNodeId + '').indexOf('text') > -1) {
            extentNodeId = extentNodeId.split('-')[0]
          }
          if ((extentNodeId + '').indexOf('-') > -1) {
            extentNodeId = extentNodeId.split('-')[2]
          }
        }
        if ((anchorNodeId + '').indexOf('text') > -1) {
          anchorNodeId = anchorNodeId.split('-')[0]
        }
        if ((extentNodeId + '').indexOf('text') > -1) {
          extentNodeId = extentNodeId.split('-')[0]
        }
        let startIdx = this.selectTextAreaData[0].characterSentences.findIndex(
          c => c.characterSentenceId == extentNodeId
        )
        let endIdx = this.selectTextAreaData[0].characterSentences.findIndex(c => c.characterSentenceId == anchorNodeId)

        if (startIdx > endIdx) {
          this.startIdx = endIdx
          this.endIdx = startIdx
        } else {
          this.startIdx = startIdx
          this.endIdx = endIdx
        }
        this.selectTextAreaData[0].characterSentences.forEach((text, tIdx) => {
          if (startIdx <= endIdx) {
            if (tIdx >= startIdx && tIdx <= endIdx) {
              this.selectionTexts.push(text)
            }
          } else {
            if (tIdx <= startIdx && tIdx >= endIdx) {
              this.selectionTexts.push(text)
            }
          }
        })
        this.$emit('changeSelectTexts', this.selectionTexts)
      } else {
        this.selectionString = ''
        this.selectionTexts = []
        this.$emit('clearPhoneticTexts')
      }
      /*       if (window.getSelection().toString()) {
        this.selectionString = window.getSelection().toString()
        let dom = this.$refs[page.id + '-' + item.id + '-' + 'textarea'][0].$el
        let { start, end } = this.getPositionForTextArea(dom)
        let changeArr = []
        for (var i = start; i < end; i++) {
          changeArr.push(item.characterSentences[i])
        }
        this.$emit('changeSelectTexts', changeArr)
      } else {
        this.$emit('clearPhoneticTexts')
      } */
    },
    textInputEnd(e) {
      this.inputStart = false
      this.inputValue = e.data
      let item = this.selectTextAreaData[0]
      if (!this.inputValue) return
      if (this.selectionTexts.length) {
        let addText = this.inputValue
        this.textChangeSim(e, item, this.startIdx, this.endIdx, addText)
      } else {
        let id = window.getSelection().anchorNode.parentNode.parentNode.id
        if (window.getSelection().anchorNode.parentNode.parentNode.className == 'mark') {
          id = window.getSelection().anchorNode.parentNode.parentNode.parentNode.id
        }
        if (!id) {
          id = window.getSelection().anchorNode.parentNode.parentNode.parentNode.id
        }
        let startIdx = item.characterSentences.findIndex(text => id == text.characterSentenceId)
        if (startIdx > -1 && item.characterSentences[startIdx]) {
          let text = item.characterSentences[startIdx].characterText
          if (text) {
            if (
              window.getSelection().anchorNode.parentNode.innerText.indexOf(text) > 0 &&
              window.getSelection().anchorNode.parentNode.parentNode.className != 'mark'
            ) {
              startIdx = startIdx - 1
            }
          }
          /*           if (
            window.getSelection().anchorOffset == this.inputValue.length &&
            ((item.characterSentences[startIdx - 1] && item.characterSentences[startIdx - 1].characterText == '<br>') ||
              !startIdx)
          ) {
            startIdx = startIdx - 1
          } */
        } else {
        }
        if (
          startIdx == -1 &&
          window.getSelection().anchorNode.parentNode.parentNode.parentNode.parentNode.tagName == 'P'
        ) {
          let id = window.getSelection().anchorNode.parentNode.parentNode.parentNode.parentNode.id.split('-')[2]
          startIdx = item.characterSentences.findIndex(t => t.characterSentenceId == id)
        }
        let endIdx = startIdx
        let addText = this.inputValue
        this.textChangeSim(e, item, startIdx, endIdx, addText)
      }
    },
    textInputStart() {
      this.inputStart = true
      this.inputValue = ''
    },
    textAreaDbclick(e, item, id) {
      /*       if (this.isRejectStatus && !item.isReject) {
        return this.$message.warning('当前文本框禁止操作！')
      } */
      if (item.translateTextAreaRegion.editble) {
        if (!window.getSelection().anchorNode.id) return
        let anchorNodeId = window.getSelection().anchorNode.id.split('-')[2]
        let extentNodeId = window.getSelection().anchorNode.id.split('-')[2]
        window.getSelection().selectAllChildren(window.getSelection().anchorNode)
        this.selectionTexts = []
        let startIdx = item.characterSentences.findIndex(c => c.characterSentenceId == extentNodeId)
        let endIdx = item.characterSentences.findIndex(c => c.characterSentenceId == anchorNodeId)

        if (startIdx > endIdx) {
          this.startIdx = endIdx
          this.endIdx = startIdx
        } else {
          this.startIdx = startIdx
          this.endIdx = endIdx
        }
        item.characterSentences.forEach((text, tIdx) => {
          if (startIdx <= endIdx) {
            if (tIdx >= startIdx && tIdx <= endIdx) {
              this.selectionTexts.push(text)
            }
          } else {
            if (tIdx <= startIdx && tIdx >= endIdx) {
              this.selectionTexts.push(text)
            }
          }
        })
        this.$emit('changeSelectTexts', this.selectionTexts)
        return
      }
      if (!this.changeAll) return
      if (
        this.checkPermission('addTextArea:edit') &&
        (this.taskStatus == '可开始' || (this.taskDetail.rejectTaskId && this.taskStatus == '驳回'))
      ) {
        item.translateTextAreaRegion.editble = 1
      }
      setTimeout(() => {
        if (!item.translateTextAreaRegion.editble) return
        window.getSelection().selectAllChildren(document.getElementById(id).firstChild.lastChild.lastChild)
        window.getSelection().collapseToEnd()
        document.getElementById(id).childNodes[0].addEventListener('compositionstart', this.textInputStart)
        document.getElementById(id).childNodes[0].addEventListener('compositionend', this.textInputEnd)
        /*         this.$emit(
          'changeAnchorNode',
          document.getElementById(id).childNodes[0].childNodes[
            document.getElementById(id).childNodes[0].childNodes.length - 1
          ].childNodes[
            document.getElementById(id).childNodes[0].childNodes[
              document.getElementById(id).childNodes[0].childNodes.length - 1
            ].childNodes.length - 1
          ]
        ) */
        this.$emit(
          'changeAnchorNode',
          document.getElementById(item.pageId + '-' + item.id + '-' + 'right').firstChild.lastChild.lastChild
        )
        this.$emit('textAreaDbclick')
      }, 50)

      /*       this.$nextTick(() => {
        let node = e.target.parentNode
        var range = window.getSelection()
        console.log(node)
        range.selectAllChildren(e.target)
        range.collapseToStart()
      }) */
    },
    textAreaClick(e, item) {
      //文本框输入逻辑
      /*       if (item.translateTextAreaRegion.editble) {
        let node = e.target.parentNode
        var range = window.getSelection()
        console.log(node)
        range.selectAllChildren(e.target)
        range.collapseToStart()
      } */
    },
    getPositionForTextArea(dom) {
      //获取输入框内光标位置
      let CaretPos = {
        start: 0,
        end: 0
      }
      if (dom.selectionStart) {
        // Firefox support
        CaretPos.start = dom.selectionStart
      }
      if (dom.selectionEnd) {
        CaretPos.end = dom.selectionEnd
      }
      return CaretPos
    },
    ocrShowClick(event) {
      if (this.isAddAnnotation) {
        /*         if (event.target.parentNode.className.indexOf('left') > -1) {
          let addPageIndex = this.leftAnnotationList.findIndex(page => page.pageId == event.target.id.split('-')[0])
          let num = this.showImgLeftListPostions[addPageIndex].width / this.pageListData[addPageIndex].width
          let y = event.offsetY
          let x = event.offsetX
          let position = {
            x: (x / num).toFixed(0),
            y: (y / num).toFixed(0)
          }
          this.$emit('changeLeftAnnotationList', { position, idx: addPageIndex })
        } else  */ if (
          event.target.parentNode.className.indexOf('right') > -1
        ) {
          let addPageIndex = this.rightAnnotationList.findIndex(page => page.pageId == event.target.id.split('-')[0])
          let num = this.showImgLeftListPostions[addPageIndex].width / this.pageListData[addPageIndex].width
          let y = event.offsetY
          let x = event.offsetX
          let position = {
            x: (x / num).toFixed(0),
            y: (y / num).toFixed(0)
          }
          this.$emit('changeRightAnnotationList', { position, idx: addPageIndex })
        }
      } else {
        //点击图像层清除选中数据
        /*         setTimeout(() => {
          if (this.selectTextAreaData.length <= 1) {
            this.pageListData.forEach(page => {
              page.textAreas.forEach(item => {
                item.textAreaRegion.selected = 0
              })
            })
            this.clearSelect()
          }
        }, 0) */
      }
    },
    getPadding(item) {
      let style = '8px 8px 8px 8px'
      let brIndexs = [0]
      let pIndexs = []
      if (item.characterSentences.every(t => !t.phonetic)) return style
      if (item.characterSentences.length && item.characterSentences[0].fontSize) {
        item.characterSentences.forEach((t, index) => {
          if (t.characterText == '<br>') brIndexs.push(index)
          if (
            item.characterSentences[index + 1] &&
            item.characterSentences[index + 1].phonetic &&
            item.characterSentences[index + 1].phonetic.phoneticText &&
            pIndexs.every(pIdx => pIdx != brIndexs[brIndexs.length - 1])
          ) {
            pIndexs.push(brIndexs[brIndexs.length - 1])
          }
        })
        let num = (item.characterSentences[0].fontSize / 2) * (pIndexs.length - 0 + 1) + 8
        if (item.textLayout == 1) {
          style = '8px 8px ' + num + 'px 8px'
        } else {
          style = '8px 8px 8px ' + num + 'px'
        }
      }
      return style
    },

    getMarkStyle(text) {
      let style =
        `width:${text.fontSize}px;` +
        `height:${text.fontSize}px;` +
        `background-color:${text.fontColor};display:inline-block;position:relative;top:-10px;user-select:none;white-space:nowrap`
      return style
    },
    getText(characterText) {
      let text = false
      if (characterText) {
        text = characterText.indexOf('<br>') > -1 || characterText.indexOf('\n') > -1 ? false : true
      } else {
        text = true
      }
      return text
    },
    onResizstop(item, ciickType, page) {
      this.$emit('copyData')
      this.$emit('savePageList')
      this.$emit('resizeChange')
      const timeOut = setTimeout(() => {
        this.$emit('resizeChange')
        clearTimeout(timeOut)
      }, 0)
    },
    onLineResizstop(item, page) {
      this.$emit('savePageLine', { item, page })
    },
    onResizingAnnotation(left, top, width, height, item, page, showPostion) {
      let num = showPostion.width / page.width - 0
      item.size = (width / num).toFixed(0) - 0
      item.position.x = (left / num).toFixed(0) - 0 + item.size / 2
      item.position.y = (top / num).toFixed(0) - 0 + item.size / 2
    },
    onDraggingAnnotation(left, top, item, page, showPostion) {
      /* let num = showPostion.width / page.width - 0
      item.position.x = (left / num).toFixed(0) - item.size / 2
      item.position.y = (top / num).toFixed(0) */
      /* this.$forceUpdate() */
    },
    onResizstopAnnotation(left, top, width, height, item, page, showPostion, type) {
      if (item.id) {
        let num = showPostion.width / page.width - 0
        item.size = (width / num).toFixed(0) - 0
        item.position.x = (left / num).toFixed(0) - 0 + item.size / 2
        item.position.y = (top / num).toFixed(0) - 0 + item.size / 2
        this.$emit('saveLocal', { item, type, value: item.commentData[0].content, localId: item.commentData[0].id })
      }
    },
    onDragstopAnnotation(left, top, item, page, showPostion, type) {
      if (item.id) {
        let num = showPostion.width / page.width - 0
        item.position.x = (left / num).toFixed(0) - 0 + ((7 / num).toFixed(0) - 0)
        let yNum = top - 0 + item.size / 2
        item.position.y = (yNum / num).toFixed(0) - 0 + ((4 / num).toFixed(0) - 0)
        if (this.isLocalDrag) {
          this.$emit('saveLocal', { item, type, value: item.commentData[0].content, localId: item.commentData[0].id })
        }
      }
    },
    onDragstop(left, top, item, page, showPostion, type) {
      let num = showPostion.width / page.width - 0
      if (type == 'left') {
        item.textAreaRegion.x = (left / num).toFixed(0) - 0
        item.textAreaRegion.y = (top / num).toFixed(0) - 0
        if (!item.translateTextAreaRegion.moved) {
          item.translateTextAreaRegion.x = (left / num).toFixed(0) - 0
          item.translateTextAreaRegion.y = (top / num).toFixed(0) - 0
        }
      } else if (type == 'right') {
        item.translateTextAreaRegion.moved = true
        item.translateTextAreaRegion.x = (left / num).toFixed(0) - 0
        item.translateTextAreaRegion.y = (top / num).toFixed(0) - 0
      }
      this.$emit('copyData')
      this.$emit('savePageList')
      this.$emit('resizeChange')
      const timeOut = setTimeout(() => {
        this.$emit('resizeChange')
        clearTimeout(timeOut)
      }, 0)
    },
    onDragLinestop(left, top, item, page, showPostion) {
      let num = showPostion.width / page.width - 0
      item.x = (left / num).toFixed(0) - 0
      item.y = (top / num).toFixed(0) - 0
      this.$emit('savePageLine', { item, page })
    },
    onResizing(left, top, width, height, item, page, showPostion) {
      let num = showPostion.width / page.width - 0
      this.getResizing(left, top, width, height, item, num)
    },
    onImgResizing(left, top, width, height, item, page, showPostion) {
      let num = showPostion.width / page.width - 0
      item.textAreaRegion.width = (width / num).toFixed(0) - 0
      item.textAreaRegion.height = (height / num).toFixed(0) - 0
      item.translateTextAreaRegion.width = (width / num).toFixed(0) - 0
      item.translateTextAreaRegion.height = (height / num).toFixed(0) - 0
      item.textAreaRegion.x = (left / num).toFixed(0) - 0
      item.textAreaRegion.y = (top / num).toFixed(0) - 0
      item.translateTextAreaRegion.x = (left / num).toFixed(0) - 0
      item.translateTextAreaRegion.y = (top / num).toFixed(0) - 0
      item.translateTextAreaRegion.moved = false
    },
    onLineResizing(left, top, width, height, item, page, showPostion) {
      let num = showPostion.width / page.width - 0
      item.width = (width / num).toFixed(0) - 0
      item.height = (height / num).toFixed(0) - 0
      item.x = (left / num).toFixed(0) - 0
      item.y = (top / num).toFixed(0) - 0
    },
    getResizing: simpleDebounce(async function(left, top, width, height, item, num) {
      this.getResizingSim(left, top, width, height, item, num)
    }, 100),
    getResizingSim(left, top, width, height, item, num) {
      item.textAreaRegion.width = (width / num).toFixed(0) - 0
      item.textAreaRegion.height = (height / num).toFixed(0) - 0
      item.translateTextAreaRegion.width = (width / num).toFixed(0) - 0
      item.translateTextAreaRegion.height = (height / num).toFixed(0) - 0
      item.textAreaRegion.x = (left / num).toFixed(0) - 0
      item.textAreaRegion.y = (top / num).toFixed(0) - 0
      item.translateTextAreaRegion.x = (left / num).toFixed(0) - 0
      item.translateTextAreaRegion.y = (top / num).toFixed(0) - 0
      item.translateTextAreaRegion.moved = false
    },
    onDragging(left, top, item, page, showPostion, type) {},
    dragBoxmousedown(event) {
      if (this.is_show_mask || !this.isAddText || this.selectTextAreaData.length) return
      if (event.button == 2) return
      this.$emit('clearSelect')
      this.is_show_text_mask = true
      this.changeX = 0
      if (this.showImgListWidth == '100%') {
        this.changeX = 0
      } else {
        if (event.target.className && event.target.className.indexOf('ocr-show-item') > -1) {
          this.changeX =
            event.target.parentNode.parentNode.getClientRects()[0].x -
            document.getElementsByClassName('show-img-bottom-left')[0].getClientRects()[0].x
        } else {
          this.changeX =
            event.target.getClientRects()[0].x -
            document.getElementsByClassName('show-img-bottom-left')[0].getClientRects()[0].x
        }
      }
      this.start_x = event.clientX
      this.start_y = event.clientY
      this.end_x = event.clientX
      this.end_y = event.clientY
      document.body.addEventListener('mousemove', this.handleMouseMove)
      document.body.addEventListener('mouseup', this.handleMouseUp)
      /*       let domX = this.$refs.showImg.getClientRects()[0].x.toFixed(0) - 0
      let domWidth = this.$refs.showImg.getClientRects()[0].width.toFixed(0) - 0
      let domY = this.$refs.showImg.getClientRects()[0].y.toFixed(0) - 0
      if (e.clientX > domX + domWidth - 29 && e.clientX < domX + domWidth - 5 && e.clientY > domY) {
        this.showImgChangeWidth = true
        this.showImgWidth = this.$refs.showImg.clientWidth
        this.optionListWidth = this.$refs.optionList.clientWidth
      } else {
        if (this.showImgChangeWidth) return
        this.is_show_text_mask = true
        this.start_x = e.clientX
        this.start_y = e.clientY
        this.end_x = e.clientX
        this.end_y = e.clientY
        document.body.addEventListener('mousemove', this.handleMouseMove)
        document.body.addEventListener('mouseup', this.handleMouseUp)
      } */
    },
    dragBoxmouseLinedown(event) {
      if (this.is_show_mask || !this.isAddLine || this.selectTextAreaData.length) return
      if (event.button == 2) return
      this.$emit('clearSelect')
      this.is_show_line_mask = true
      this.start_x = event.clientX
      this.start_y = event.clientY
      this.end_x = event.clientX
      this.end_y = event.clientY
      document.body.addEventListener('mousemove', this.handleMouseLineMove)
      document.body.addEventListener('mouseup', this.handleMouseLineUp)
    },
    handleMouseLineMove(event) {
      if (this.is_show_mask || !this.isAddLine) {
        this.start_x = 0
        this.start_y = 0
        this.end_x = 0
        this.end_y = 0
        return
      }
      if (Math.min(this.start_x, event.clientX) - this.box_screen_line_left <= 0) {
        return
      }
      this.end_x = event.clientX
      this.end_y = event.clientY
    },
    textAreaMousedown(e) {
      this.dragTextArea = true
    },
    textAreaMouseup() {
      const timeOut = setTimeout(() => {
        this.dragTextArea = false
        clearTimeout(timeOut)
      }, 0)
    },
    handleMouseLineUp() {
      document.body.removeEventListener('mousemove', this.handleMouseLineMove)
      document.body.removeEventListener('mouseup', this.handleMouseLineUp)
      this.is_show_line_mask = false
      this.changeX = 0
      this.handleLineSelect()
      this.resSetXY()
    },
    handleMouseMove(event) {
      if (this.is_show_mask || !this.isAddText) {
        this.start_x = 0
        this.start_y = 0
        this.end_x = 0
        this.end_y = 0
        return
      }
      if (Math.min(this.start_x, event.clientX) - this.box_screen_left <= 0) {
        return
      }
      this.end_x = event.clientX
      this.end_y = event.clientY
    },
    handleMouseUp() {
      document.body.removeEventListener('mousemove', this.handleMouseMove)
      document.body.removeEventListener('mouseup', this.handleMouseUp)
      this.is_show_text_mask = false
      this.handleDomSelect()
      this.resSetXY()
    },
    resSetXY() {
      this.start_x = 0
      this.start_y = 0
      this.end_x = 0
      this.end_y = 0
    },
    handleLineSelect() {
      const dom_mask = window.document.querySelector('.line_mask')
      const rect_select = dom_mask.getClientRects()[0]
      if (rect_select.width < 15 && rect_select.height < 15) {
        if (this.isAddLine) this.$emit('changeIsAddLine')
        return
      }
      let addPageIndex = 0
      this.showImgLeftListPostions.forEach((page, index) => {
        if (this.start_x - this.showImgLeftListPostions[0].top + this.scrollTop > page.top) {
          addPageIndex = index
        }
      })
      let num = this.showImgLeftListPostions[addPageIndex].width / this.pageListData[addPageIndex].width
      let y =
        rect_select.y -
        this.showImgLeftListPostions[0].top +
        this.scrollTop -
        (this.showImgLeftListPostions[addPageIndex].top - this.showImgLeftListPostions[0].top)
      let x = rect_select.x - this.box_screen_line_left

      let changeX =
        document.getElementsByClassName('ocr-show-right')[0].getClientRects()[0].x -
        document.getElementsByClassName('show-img-bottom-right')[0].getClientRects()[0].x
      if (this.showImgListWidth == '100%') {
        changeX = 0
      }
      changeX = 0
      let textAreaWidth = (rect_select.width / num).toFixed(0) - 0
      let textAreaHeight = (rect_select.height / num).toFixed(0) - 0
      let addLine = {
        id: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
        x: ((x - changeX) / num).toFixed(0),
        y: (y / num).toFixed(0),
        width: textAreaWidth,
        height: textAreaHeight,
        selected: 0,
        startPosition: {
          x: this.start_x,
          y: this.start_y
        },
        endPosition: {
          x: this.end_x,
          y: this.end_y
        }
      }
      if (this.start_x > this.end_x && this.end_y > this.start_y) {
        addLine.startPosition.x = textAreaWidth
        addLine.startPosition.y = 0
        addLine.endPosition.x = 0
        addLine.endPosition.y = textAreaHeight
      } else if (this.start_x >= this.end_x && this.start_y >= this.end_y) {
        addLine.startPosition.x = textAreaWidth
        addLine.startPosition.y = textAreaHeight
        addLine.endPosition.x = 0
        addLine.endPosition.y = 0
      } else if (this.end_x > this.start_x && this.start_y > this.end_y) {
        addLine.startPosition.x = 0
        addLine.startPosition.y = textAreaHeight
        addLine.endPosition.x = textAreaWidth
        addLine.endPosition.y = 0
      } else if (this.end_x >= this.start_x && this.end_y >= this.start_y) {
        addLine.startPosition.x = 0
        addLine.startPosition.y = 0
        addLine.endPosition.x = textAreaWidth
        addLine.endPosition.y = textAreaHeight
      }
      this.$emit('addLine', { addLine, pageId: this.pageListData[addPageIndex].id, addPageIndex })
      if (this.isAddLine) this.$emit('changeIsAddLine')
    },
    handleDomSelect() {
      const dom_mask = window.document.querySelector('.text_mask')
      const rect_select = dom_mask.getClientRects()[0]
      let addPageIndex = 0
      this.showImgLeftListPostions.forEach((page, index) => {
        if (rect_select.top + this.scrollTop > page.top) {
          addPageIndex = index
        }
      })
      let num = this.showImgLeftListPostions[addPageIndex].width / this.pageListData[addPageIndex].width
      let y =
        rect_select.y -
        this.showImgLeftListPostions[0].top +
        this.scrollTop -
        (this.showImgLeftListPostions[addPageIndex].top - this.showImgLeftListPostions[0].top)
      let x = rect_select.x - this.box_screen_left

      let changeX =
        document.getElementsByClassName('ocr-show-left')[0].getClientRects()[0].x -
        document.getElementsByClassName('show-img-bottom-left')[0].getClientRects()[0].x
      if (this.showImgListWidth == '100%') {
        changeX = 0
      }
      changeX = 0
      let pushId = 'new' + Math.round(Math.random() * 10000) + new Date().getTime()
      let textAreaWidth = (rect_select.width / num).toFixed(0) - 0
      let textAreaHeight = (rect_select.height / num).toFixed(0) - 0
      let textType = this.textTypes.find(type => type.sequenceNo == 1)
      let addTextArea = {
        id: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        pageId: this.pageListData[addPageIndex].id,
        textAreaId: 1,
        textAreaRegion: {
          x: ((x - changeX) / num).toFixed(0),
          y: (y / num).toFixed(0),
          width: textAreaWidth > 80 ? textAreaWidth : 80,
          height: textAreaHeight > 60 ? textAreaHeight : 60,
          rotation: 0,
          selected: 0,
          editble: 0,
          moved: false
        },
        translateTextAreaRegion: {
          x: ((x - changeX) / num).toFixed(0),
          y: (y / num).toFixed(0),
          width: textAreaWidth > 80 ? textAreaWidth : 80,
          height: textAreaHeight > 60 ? textAreaHeight : 60,
          rotation: 0,
          selected: 0,
          editble: 0,
          moved: false
        },
        textLayout: this.oldTextBatchStyle.textLayout,
        backgroundColor: '#fff',
        textAreaType: 1,
        originalText: '',
        translateText: '',
        revisionText: '',
        auditText: '',
        superviseText: null,
        sequenceNo: '1',
        showText: '',
        characterSentences: [
          {
            ...this.oldTextBatchStyle,
            textAlign: this.textBatchStyle.textAlign,
            fontFamily: this.textBatchStyle.fontFamily,
            characterSentenceId: pushId,
            characterText: ''
          }
        ]
      }
      if (textType) {
        this.$emit('changeTextStyle', textType)
        addTextArea.characterSentences = [
          {
            ...this.oldTextBatchStyle,
            textAlign: this.textBatchStyle.textAlign,
            isBold: textType.isBold ? 1 : 0,
            fontFamily: textType.fontFamilys[0].fontFamily,
            characterSentenceId: pushId,
            characterText: ''
          }
        ]
      }
      this.pageListData[addPageIndex].textAreas.push(addTextArea)
      this.pageListData[addPageIndex].textAreas.sort((a, b) => a.textAreaRegion.y - b.textAreaRegion.y)
      this.pageListData[addPageIndex].textAreas.forEach((textArea, idx) => {
        textArea.sequenceNo = idx + 1
      })
      this.$emit('addTextArea', addTextArea)
      if (this.isAddText) this.$emit('changeIsAddText')
    },
    collide(rect1, rect2) {
      if (!rect1 || !rect2) return
      const maxX = Math.max(rect1.x + rect1.width, rect2.x + rect2.width)
      const maxY = Math.max(rect1.y + rect1.height, rect2.y + rect2.height)
      const minX = Math.min(rect1.x, rect2.x)
      const minY = Math.min(rect1.y, rect2.y)
      if (maxX - minX <= rect1.width + rect2.width && maxY - minY <= rect1.height + rect2.height) {
        return true
      } else {
        return false
      }
    },
    clearSelect() {
      if (this.is_show_mask) return
      this.$emit('clearSelect')
    },
    showImgClick(e) {
      /*       console.log(this.isCenterClick, 123)
      if (!this.isCenterClick) return */
      if (e.target.className && typeof e.target.className != 'string') return
      if (e.target.className && e.target.className.indexOf('handle handle-') > -1) return
      if (this.isAddAnnotation || this.selectTextArea || this.dragTextArea) return
      this.$emit('showImgClick')
      this.isChangeFontSize = false
      this.$forceUpdate()
    },
    async changeInjuryType(type, item) {
      this.$emit('topCenterMouseover')
      /* this.$emit('copyData') */
      this.$emit('savePageList')
      this.$nextTick(() => {
        this.$emit('changeAnchorNodeBefore')
      })
      this.selectErrorType = type
      this.dictList = []
      this.errorValue = undefined
      if (this.selectErrorType && this.selectErrorType != 4) {
        let code = !this.selectErrorType
          ? ''
          : this.selectErrorType == 1
          ? 'hy-rs'
          : this.selectErrorType == 2
          ? 'hy-ys'
          : this.selectErrorType == 3
          ? 'hy-cf'
          : ''
        if (this.departName.indexOf('日语部') > -1) {
          code = !this.selectErrorType
            ? ''
            : this.selectErrorType == 1
            ? 'ry-rs'
            : this.selectErrorType == 2
            ? 'ry-ys'
            : this.selectErrorType == 3
            ? 'ry-cf'
            : ''
          if (this.taskDetail.stageName == '校对' || this.taskDetail.stageName == '终审') {
            code = !this.selectErrorType
              ? ''
              : this.selectErrorType == 1
              ? 'jd_fy-zs_jd_rs'
              : this.selectErrorType == 2
              ? 'jd_fy-zs_jd_ys'
              : this.selectErrorType == 3
              ? 'jd_fy-zs_jd_cf'
              : ''
          }
        }
        let id = this.errorDictList.find(item => item.dictCode == code).id
        const res = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: id })
        if (res.code == 200) {
          this.dictList = res.data.records
          if (!this.dictList.length) return this.$message.error('无错误类型数据')
          this.$refs.errorModal.open(item)
        }
      }
      if (!this.selectErrorType) {
        this.errorValue = undefined
      } else {
        this.$refs.errorModal.open(item)
      }
    },
    async changeInjuryTypeBottom(type) {
      this.selectErrorTypeBottom = type
      this.dictList = []
      this.errorValueBottom = undefined
      if (this.selectErrorTypeBottom && this.selectErrorTypeBottom != 4) {
        let code = !this.selectErrorTypeBottom
          ? ''
          : this.selectErrorTypeBottom == 1
          ? 'ccc'
          : this.selectErrorTypeBottom == 2
          ? 'bbb'
          : this.selectErrorTypeBottom == 3
          ? 'aaa'
          : ''
        let id = this.errorDictList.find(item => item.dictCode == code).id
        const res = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: id })
        if (res.code == 200) {
          this.dictList = res.data.records
        }
      }
      if (!this.selectErrorTypeBottom) {
        this.errorValueBottom = undefined
      }
    },
    onActivated(item, ciickType, page) {
      if (this.isAddText || this.isAddLine || this.isCheckText || item.translateTextAreaRegion.editble) return
      if (ciickType == 'left') {
        item.textAreaRegion.selected = 1
      } else {
        item.textAreaRegion.selected = 1
      }
      this.inputDirection = 0
      if (this.taskDetail.stageName == '校对' || this.taskDetail.stageName == '终审') {
        /* this.getRevision(item) */
      }
      this.isChangeFontSize = false
      this.$emit('changeIsDragText', { isDragText: true, item, page, ciickType })
      this.getRevisionList(item)
      this.getYahooOrKdxf(item.showText, page.id, item.id, item)
    },
    onLineActivated(item, page) {
      if (this.isAddText || this.isAddLine || this.isCheckText) return
      if (this.$refs['page-line-' + item.id] && this.$refs['page-line-' + item.id][0]) {
        this.$refs['page-line-' + item.id][0].selectLine = item
      }
      item.selected = 1
      this.$emit('changeSelectLine', { item, page })
    },
    onLineDeactivated(item, page) {
      if (this.isAddText || this.isAddLine || this.isCheckText) return
      if (this.$refs['page-line-' + item.id] && this.$refs['page-line-' + item.id][0]) {
        this.$refs['page-line-' + item.id][0].selectLine = null
      }
      item.selected = 0
      this.$emit('clearSelectLine')
    },
    async getRevision(item) {
      const res = await v1postAction('/textarea/fetch_revision', {
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        revisionType: this.taskDetail.stageName == '校对' ? 1 : 2,
        textAreaId: item.textAreaId
      })
      if (res.code == 1 && res.pageList.length) {
        this.isRejected = res.pageList[0].revisionList[0].isRejected
      } else if (res.code != 1) {
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    onActivatedAnnotation(item) {
      if (this.isAddText || this.isAddLine) return
      this.leftAnnotationList.forEach(i => {
        i.annotationList.forEach(a => {
          a.selected = 0
        })
      })
      this.rightAnnotationList.forEach(i => {
        i.annotationList.forEach(a => {
          a.selected = 0
        })
      })
      this.localLeftValue = ''
      this.localRightValue = ''
      item.selected = 1
      this.$forceUpdate()
      this.$emit('onActivatedAnnotation', true)
      setTimeout(() => {
        if (this.$refs.localRightInput && this.$refs.localRightInput[0]) {
          this.$refs.localRightInput[0].EditorOnsize()
        }
      }, 1000)
    },
    onDeactivatedAnnotation(item) {
      if (this.isCenterClick) return
      item.selected = 0
      this.$emit('onActivatedAnnotation', false)
    },
    async onDeactivated(item, ciickType, page) {
      if (this.isCenterClick || this.isDelete || this.isCheckText || this.isOpenModal) {
        /*         this.$refs['draggable-' + item.id][0].active = false
        this.$nextTick(() => {
          this.$refs['draggable-' + item.id][0].active = true
        }) */
        if (this.isCenterClick || this.isOpenModal) {
          const timeOut = setTimeout(() => {
            if (!this.isOpenPhoneticModal) {
              this.$emit('copyData')
              this.$emit('savePageList')
            }
            clearTimeout(timeOut)
          }, 500)
        }
        return
      }
      if (this.$refs.errorModal && this.$refs.errorModal.visible) return
      if (ciickType == 'left') {
        item.textAreaRegion.selected = 0
      } else {
        item.textAreaRegion.selected = 0
      }
      this.errorValue = undefined
      this.selectErrorType = 0
      this.errorValueBottom = undefined
      this.selectErrorTypeBottom = 0
      this.checkPhoneticList = []
      window.getSelection().removeAllRanges()
      item.translateTextAreaRegion.editble = 0
      this.isChangeFontSize = false
      this.$emit('copyData')
      this.$emit('savePageList')
      this.$emit('changeIsDragText', { isDragText: false, item, page, ciickType })
      if (ciickType == 'right') {
        let id = page.id + '-' + item.id + '-' + 'right'
        if (document.getElementById(id).childNodes) {
          document.getElementById(id).childNodes[0].removeEventListener('compositionstart', this.textInputStart)
          document.getElementById(id).childNodes[0].removeEventListener('compositionend', this.textInputEnd)
        }
      }
      this.$emit('clearAnchorNode')
    },
    getActionStyle(showPostion, page, item, type, idx) {
      let left = ''
      let topStyle = ''
      let num = showPostion.width / page.width - 0
      if (type == 'left') {
        let father = document.getElementById(page.id + '-left-' + idx)
        let top = (item.textAreaRegion.y * num).toFixed(0) - 0
        let height =
          item.textAreaRegion.height /* * num */
            .toFixed(0) - 0 || 1
        let domWidth =
          (340) /* * num */
            .toFixed(0) - 0
        let domX = (item.textAreaRegion.x * num).toFixed(0) - 0 + father.getBoundingClientRect().x
        let domMaxX = domX + domWidth
        let domMinX = domX
        let fatherMaxX = father.getBoundingClientRect().width + father.getBoundingClientRect().x
        let fatherMinx = father.getBoundingClientRect().x
        let outWidth = 0
        let dir = 'left'
        if (domMinX < fatherMinx) {
          outWidth = fatherMinx - domMinX
          dir = 'left'
        } else {
          outWidth = domMaxX - fatherMaxX
          dir = 'right'
        }
        if (top + height > father.getBoundingClientRect().height - 250) {
          topStyle = 'bottom: calc(100% + 34px);'
        } else {
          topStyle = 'top:calc(100% + 12px)'
        }
        if (outWidth > 0) {
          if (dir == 'left') {
            left = 'left:' + outWidth + 'px;'
          } else {
            left = 'left:-' + outWidth + 'px;'
          }
        } else {
          left = 'left:0px;'
        }
      }
      if (!this.leftImgShow || !this.rightImgShow) {
        num = num / 2
      }
      let style = /* `transform:scale(${num.toFixed(2)});transform-origin: top left;` +  */ left + topStyle
      return style
    },
    getLocalActionStyle(showPostion, page, type, item) {
      let left = ''
      let topStyle = ''
      let num = showPostion.width / page.width - 0
      if (type) {
        let top = (item.position.y * num).toFixed(0) - 0
        let height = (32 * num).toFixed(0) - 0 || 1
        let domWidth = (265 * num).toFixed(0) - 0
        let domX = (item.position.x * num).toFixed(0) - 0 + (showPostion.x - 0)
        let domMaxX = domX + domWidth
        let domMinX = domX
        let fatherMaxX = showPostion.width - 0 + (showPostion.x - 0)
        let fatherMinx = showPostion.x - 0
        let outWidth = 0
        let dir = 'left'
        if (domMinX < fatherMinx) {
          outWidth = fatherMinx - domMinX
          dir = 'left'
        } else {
          outWidth = domMaxX - fatherMaxX
          dir = 'right'
        }
        if (top + height > showPostion.height - 250) {
          topStyle = 'bottom: calc(100% + 26px);'
        } else {
          topStyle = 'top:calc(100% + 12px)'
        }
        if (outWidth > 0) {
          if (dir == 'left') {
            left = 'left:' + outWidth + 'px;'
          } else {
            left = 'left:-' + outWidth + 'px;'
          }
        } else {
          left = 'left:0px;'
        }
      }
      if (!this.leftImgShow || !this.rightImgShow) {
        num = num / 2
      }
      let style = /* `transform:scale(${num.toFixed(2)});transform-origin: top left;` + */ left + topStyle
      return style
    },
    getActionCloseStyle(showPostion, page) {
      /*       let num = showPostion.width / page.width - 0
      if (!this.leftImgShow || !this.rightImgShow) {
        num = num / 2
      }
      let style = `transform:scale(${num.toFixed(2)});transform-origin: bottom left;` */
      return ''
    },
    getItemStyle(item, showPostion, page, type) {
      let style = {}
      if (this.pageListData.length && this.showImgLeftListPostions.length) {
        if (type == 'left') {
          let num = showPostion.width / page.width - 0
          let width = (item.textAreaRegion.width * num).toFixed(0) - 0 || 1
          let height = (item.textAreaRegion.height * num).toFixed(0) - 0 || 1
          let top = (item.textAreaRegion.y * num).toFixed(0) - 0
          let left = (item.textAreaRegion.x * num).toFixed(0) - 0
          if (top > showPostion.height - height) {
            top = showPostion.height - height - 20
          }
          if (left > showPostion.width - width) {
            left = showPostion.width - width - 10
          }
          style = {
            w: width,
            h: height,
            x: left,
            y: top,
            z: 97
          }
        } else {
          let num = showPostion.width / page.width - 0
          let width, height, top, left
          if (item.translateTextAreaRegion.moved) {
            width = (item.translateTextAreaRegion.width * num).toFixed(0) - 0 || 1
            height = (item.translateTextAreaRegion.height * num).toFixed(0) - 0 || 1
            top = (item.translateTextAreaRegion.y * num).toFixed(0) - 0
            left = (item.translateTextAreaRegion.x * num).toFixed(0) - 0
          } else {
            width = (item.textAreaRegion.width * num).toFixed(0) - 0 || 1
            height = (item.textAreaRegion.height * num).toFixed(0) - 0 || 1
            top = (item.textAreaRegion.y * num).toFixed(0) - 0
            left = (item.textAreaRegion.x * num).toFixed(0) - 0
          }
          if (top > showPostion.height - height) {
            top = showPostion.height - height - 20
          }
          if (left > showPostion.width - width) {
            left = showPostion.width - width - 10
          }
          style = {
            w: width,
            h: height,
            x: left,
            y: top,
            z: 97
          }
          /*           let num = showPostion.width / page.width - 0
          let width = (item.translateTextAreaRegion.width * num).toFixed(0) - 0 || 1
          let height = (item.translateTextAreaRegion.height * num).toFixed(0) - 0 || 1
          let top = (item.translateTextAreaRegion.y * num).toFixed(0) - 0
          let left = (item.translateTextAreaRegion.x * num).toFixed(0) - 0
          if (width > 0 && height > 0 && top > 0 && left > 0) {
            style = {
              w: width,
              h: height,
              x: left,
              y: top,
              z: 98
            }
          } */
        }
      }
      return style
    },
    getItemImgStyle(item, showPostion, page, type) {
      let style = {}
      if (this.pageListData.length && this.showImgLeftListPostions.length) {
        let num = showPostion.width / page.width - 0
        let width, height, top, left
        width = (item.translateTextAreaRegion.width * num).toFixed(0) - 0 || 1
        height = (item.translateTextAreaRegion.height * num).toFixed(0) - 0 || 1
        top = (item.translateTextAreaRegion.y * num).toFixed(0) - 0
        left = (item.translateTextAreaRegion.x * num).toFixed(0) - 0
        if (top > showPostion.height - height) {
          top = showPostion.height - height - 20
        }
        if (left > showPostion.width - width) {
          left = showPostion.width - width - 10
        }
        style = {
          w: width,
          h: height,
          x: left,
          y: top,
          z: 97
        }
      }
      return style
    },
    getLineItemStyle(item, showPostion, page) {
      let style = {}
      if (this.pageListData.length && this.showImgLeftListPostions.length) {
        let num = showPostion.width / page.width - 0
        let width, height, top, left
        if (item.moved) {
          width = (item.width * num).toFixed(0) - 0 || 1
          height = (item.height * num).toFixed(0) - 0 || 1
          top = (item.y * num).toFixed(0) - 0
          left = (item.x * num).toFixed(0) - 0
        } else {
          width = (item.width * num).toFixed(0) - 0 || 1
          height = (item.height * num).toFixed(0) - 0 || 1
          top = (item.y * num).toFixed(0) - 0
          left = (item.x * num).toFixed(0) - 0
        }
        if (top > showPostion.height - height) {
          top = showPostion.height - height - 20
        }
        if (left > showPostion.width - width) {
          left = showPostion.width - width - 10
        }
        style = {
          w: width,
          h: height,
          x: left,
          y: top,
          z: 97
        }
      }
      return style
    },
    getAnnotationStyle(item, showPostion, page, type) {
      let style = {}
      if (this.pageListData.length && this.showImgLeftListPostions.length) {
        let num = showPostion.width / page.width - 0
        let width =
          item.size /* * num */
            .toFixed(0) - 0
        let height =
          item.size /* * num */
            .toFixed(0) - 0
        let topNum = (4 * num).toFixed(0)
        let leftNum = (8 * num).toFixed(0)
        let top = item.position.y - 0 - item.size / 2 - topNum
        top = (top * num).toFixed(0) - 0
        let left = (item.position.x * num).toFixed(0) - 0 - leftNum
        /* if (width > 0 && height > 0 && top > 0 && left > 0) {
          style = {
            w: width,
            h: height,
            x: left,
            y: top,
            z: 97
          }
        } else {
          style = {
            w: width,
            h: height,
            x: 0,
            y: 0,
            z: 97
          }
        } */
        if (!this.leftImgShow || !this.rightImgShow) {
          width = width / 2
          height = height / 2
        }
        style = {
          w: width,
          h: height,
          x: left,
          y: top,
          z: 97
        }
      }
      return style
    },
    changePage() {
      document.getElementsByClassName('show-img-bottom')[0].scrollTop =
        this.showImgLeftListPostions.find(item => item.idx == this.selectIdx).top - 153 + this.changeScrollHeihgt
    },
    showImgScroll(e) {
      let idx = 0
      this.showImgLeftListPostions.forEach((item, index) => {
        if (e.target.scrollTop >= item.top - 153) {
          idx = index
        }
      })
      this.scrollTop = e.target.scrollTop
      if (!this.ischangeScroll) {
        this.oldScrollTop = e.target.scrollTop
      }
      this.$emit('changeSelectIdx', idx)
      this.saveScrollTop()
    },
    saveScrollTop: simpleDebounce(async function() {
      this.saveScrollTopSim()
    }, 1000),
    saveScrollTopSim() {
      putAction('/page/scroll/points', {
        userId: this.userInfo.id,
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        positions: this.scrollTop + '-' + this.selectIdx
      })
    },
    changeLeftOption(value) {
      this.$emit('changeLeftOption', value)
    },
    loadImg() {
      /* console.log('开始加载') */
      let loadImgs = []
      this.pageListData.forEach(item => {
        let that = this
        if (!that.$refs[item.id + 'showLeftImg']) return
        var img = that.$refs[item.id + 'showLeftImg'][0]
        img.onload = function() {
          loadImgs.push(img)
          if (loadImgs.length == that.pageListData.length) {
            that.allUpload()
          }
        }
      })
    },
    allUpload() {
      /* console.log('加载完成') */
      this.$emit('getShowImgListPostions')
      this.getDragTextBox()
    },
    changeRightOption(value) {},
    rightClick(color) {
      this.backgroundColor = color
    }
  }
}
</script>

<style lang="less" scoped>
.local-content-to-stage {
  padding: 5px 0 5px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  background: #fff;
}
.left-show-img-blur {
  /* filter: blur(3px); */
}
.right-show-img-blur {
  /* filter: blur(3px); */
}
.ocr-show-page {
  display: block;
}
.img {
  display: initial;
}
.term-item {
  display: flex;
  justify-content: space-between;
  /*   cursor: pointer;
  &:hover {
    color: var(--theme-color);
  } */
}
.stroke-span-hasMark::after {
  width: var(--mFontSize) !important;
  height: var(--mFontSize) !important;
  background-color: var(--mBack);
  display: inline-block;
  position: absolute;
  user-select: none;
  white-space: nowrap;
  bottom: var(--mBottom) !important;
  left: var(--mLeft) !important;
  border-radius: var(--mBorderRadius) !important;
}
.stroke-span::after {
  content: attr(data-text);
  position: absolute;
  z-index: 0;
  white-space: nowrap;
  right: var(--pLeft);
  top: var(--pTop);
  -webkit-text-stroke: 0;
  text-align: center;
  font-size: var(--pFontSize) !important;
  /* line-height: normal; */
  transform: var(--transform);
  font-weight: var(--bold);
  font-style: var(--isIncline);
  letter-spacing: var(--letterSpacing);
  height: var(--pLineHeightH);
  width: var(--pLineHeightW);
  user-select: none;
  display: inline-block;
  -webkit-text-stroke: var(--stroke);
}
.stroke-span::before {
  content: attr(data-text);
  position: absolute;
  z-index: 1;
  right: var(--pLeft);
  top: var(--pTop);
  -webkit-text-stroke: 0;
  text-align: center;
  font-size: var(--pFontSize) !important;
  /* line-height: normal; */
  transform: var(--transform);
  font-weight: var(--bold);
  font-style: var(--isIncline);
  letter-spacing: var(--letterSpacing);
  height: var(--pLineHeightH);
  width: var(--pLineHeightW);
  user-select: none;
  display: inline-block;
}
.stroke-span-child {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  z-index: 1;
}
.stroke-span-child::after {
  content: attr(data-text);
  position: absolute;
  z-index: 0;
  left: 0px;
  top: 0px;
  -webkit-text-stroke: 0;
}
.stroke-span {
  position: relative;
  z-index: 1000;
}
.stroke-span-phonetic::after {
  content: attr(data-text);
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  -webkit-text-stroke: 0;
}
.stroke-span-phonetic {
  position: relative;
  left: 0;
  top: 0;
  z-index: 0;
}
.revisition-content-error {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    .action-span {
      display: inline-block !important;
    }
  }
  .action-span {
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      color: var(--theme-color) !important;
    }
  }
  .anticon-more {
    font-size: 16px;
  }
  .action-span-select {
    display: inline-block;
    &:hover {
      color: var(--theme-color) !important;
    }
  }
}
.revisition-content {
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}
.char-circle {
  border-radius: 50%;
}
.no-permission {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}
/deep/ .ocr-show-item-action-input {
  .ant-select {
    border: none !important;
    .ant-select-selection {
      box-shadow: none !important;
      border: none !important;
    }
  }
}

.local-content {
  position: absolute;
  /*  top: calc(100% + 12px); */
  left: 0;
  width: 265px;
  z-index: 99;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  .local-content-list {
    padding: 5px;
    width: 265px;
    .local-content-item {
      .local-content-item-top {
        height: 36px;
        display: flex;
        .local-content-item-top-left {
          width: 20px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
        }
        .local-content-item-top-right {
          font-size: 12px;
          margin-left: 5px;
          > div {
            line-height: 18px;
          }
        }
      }
      .local-content-item-bottom {
        width: 100%;
        font-size: 14px;
        line-height: 25px;
        padding-left: 25px;
        color: black;
        user-select: text;
      }
    }
  }
}
.ant-input {
  border: none;
  &:focus {
    box-shadow: none;
  }
}
.text_mask {
  position: absolute;
  background: rgba(0, 0, 0, 0);
  border: 3px solid var(--theme-color);
  z-index: 999;
  transition: none !important;
}
.line_mask {
  position: absolute;
  /* background: rgba(0, 0, 0, 0); */
  z-index: 999;
  border-radius: 5px;
  transition: none !important;
}
/deep/ .ocr-show-page-text .handle {
  border: 1px solid var(--theme-color) !important;
  background: #fff;
  border-radius: 50% !important;
  z-index: 100;
}
/deep/ .ocr-show-page-line .handle {
  display: none !important;
}
/deep/ .ocr-show-page-line-show-tl .handle-tl {
  display: block;
  border: 1px solid var(--theme-color) !important;
  background: #fff;
  border-radius: 50% !important;
  z-index: 100;
}
/deep/ .ocr-show-page-line-show-tl .handle-bl {
  display: none !important;
}
/deep/ .ocr-show-page-line-show-bl .handle-bl {
  display: block;
  border: 1px solid var(--theme-color) !important;
  background: #fff;
  border-radius: 50% !important;
  z-index: 100;
}
/deep/ .ocr-show-page-line-show-bl .handle-tl {
  display: none !important;
}
/deep/ .ocr-show-page-line-show-tr .handle-tr {
  display: block;
  border: 1px solid var(--theme-color) !important;
  background: #fff;
  border-radius: 50% !important;
  z-index: 100;
}
/deep/ .ocr-show-page-line-show-tr .handle-br {
  display: none !important;
}
/deep/ .ocr-show-page-line-show-br .handle-br {
  display: block;
  border: 1px solid var(--theme-color) !important;
  background: #fff;
  border-radius: 50% !important;
  z-index: 100;
}
/deep/ .ocr-show-page-line-show-br .handle-tr {
  display: none !important;
}
/deep/ .handle-tm,
/deep/ .handle-bm,
/deep/ .handle-mr,
/deep/ .handle-ml {
  display: none !important;
}
.right-text-input:focus-visible {
  outline: none;
}
.right-text-input-edit {
  border: 1px dashed black;
}
.right-text-input {
  white-space: nowrap;
  min-width: max-content;
  min-height: max-content;
  span {
    text-align: center;
    /* display: inline-block; */
    position: relative;
    > .mark {
      line-height: 0;
      position: absolute;
      width: 100%;
      text-align: center !important;
      transform: scale(0.2);
      -webkit-font-smoothing: antialiased;
      font-weight: 700;
      bottom: 3px;
      backface-visibility: hidden;
    }
  }
}
.delete-border {
  position: absolute;
  border: 2px dashed red;
  z-index: 99;
  > div {
    position: absolute;
    width: 22px;
    height: 22px;
    top: -22px;
    right: -22px;
    text-align: center;
    line-height: 22px;
    background: red;
  }
}
.show-img {
  width: 100%;
  height: 100%;
  .show-img-content {
    width: 100%;
    height: 100%;
    .show-img-top {
      height: 30px;
      display: flex;
      background-color: #f0f2f5;
      position: relative;
      > div {
        width: 50%;
        background-color: #fff;
        line-height: 30px;
        padding: 0 24px 0 24px;
        display: flex;
        justify-content: space-between;
        .show-img-top-left,
        .show-img-top-right {
          width: 10%;
          position: relative;
          > div {
            position: absolute;
            right: -42px;
            top: 0;
            padding-left: 3px;
            padding-right: 3px;
            background-color: #fff;
            border-radius: 4px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            width: 18px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .show-option {
          padding: 2px 5px 2px 5px;
          background-color: #f0f2f5;
          border-radius: 5px;
          margin-right: 5px;
          /* transition: 0.3s all; */
          cursor: pointer;
          &:hover {
            color: var(--theme-color);
          }
        }
        .show-option-select {
          background-color: #f0f2f5;
          color: var(--theme-color);
          border-radius: 5px;
        }
      }
      .show-img-top-border {
        position: absolute;
        left: calc(50% - 4px);
        width: 3px;
        height: 100%;
        background-color: #f0f2f5;
        padding: 0;
      }
    }
    .show-img-bottom-local {
      cursor: url('../../../assets/本地_local-two.svg'), default;
    }
    .show-img-bottom-textArea {
      .show-img-bottom-left {
        cursor: crosshair;
      }
      .show-img-bottom-right {
        cursor: no-drop;
        /* cursor: crosshair; */
      }
    }
    .show-img-bottom-line {
      .show-img-bottom-left {
        cursor: no-drop;
      }
      .show-img-bottom-right {
        cursor: crosshair;
        /* cursor: crosshair; */
      }
    }
    .show-img-bottom {
      height: calc(100% - 30px);
      overflow-y: scroll;
      display: flex;
      background-color: #f0f2f5;
      position: relative;
      z-index: 2;
      .show-img-bottom-left,
      .show-img-bottom-right {
        flex: 1;
        > div {
          position: relative;
          display: flex;
          justify-content: center;
          > div {
            position: relative;
          }
        }
      }
      /*       .show-img-bottom-left{
        width:0;
        .ocr-show-left{
          opacity: 0;
        }
      } */
      .ocr-show {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .ocr-show-page {
          position: relative;
          overflow: hidden;
        }
        .ocr-show-page-right {
          overflow: initial;
        }
        .ocr-show-item {
          border: 3px solid gray;
          height: 100%;
          width: 100%;
          position: relative;
        }
        .ocr-show-item-reject {
          border: 3px solid orange;
          height: 100%;
          width: 100%;
          position: relative;
        }
        .ocr-show-item-right {
          border: 3px solid rgba(0, 0, 0, 0);
          height: 100%;
          width: 100%;
          overflow-y: visible;
          display: flex;
          justify-content: center;
          position: relative;
          align-items: center;
        }
        .ocr-show-item-selected {
          border: 3px solid var(--theme-color);
          position: relative;
          z-index: 99;
        }
        .ocr-show-item-title {
          position: absolute;
          width: 100%;
          height: 18px;
          top: -24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          > div {
            height: 25px;
            width: 25px;
            font-size: 12px;
            border-radius: 50%;
            background-color: var(--theme-color);
            color: #fff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .ocr-show-item-title-local {
          position: absolute;
          width: 100%;
          height: 18px;
          top: -24px;
          right: -12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          > div {
            height: 14px;
            width: 14px;
            font-size: 10px;
            border-radius: 7px;
            background-color: var(--theme-color);
            color: #fff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .ocr-show-item-action {
          position: absolute;
          left: 0;
          width: 340px;
          z-index: 100;
          .ocr-show-item-action-top,
          .ocr-show-item-action-bottom {
            box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
            padding: 15px;
            max-height: 250px;
            border-radius: 5px;
            overflow-y: auto;
            background: #fff;
            &::-webkit-scrollbar-button {
              height: 0;
            }
            &::-webkit-scrollbar {
              border-radius: 0;
              position: relative;
              width: 5px;
            }
            &::-webkit-scrollbar-track {
              border-radius: 0;
              background: #fff;
            }
            &::-webkit-scrollbar-track-piece {
              border-radius: 0;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 0;
              background-color: #c1c1c1;
            }
          }
          .ocr-show-item-action-bottom {
            margin-top: 10px;
          }
          .ocr-show-item-action-top {
            padding: 8px;
            padding-left: 10px;
            padding-right: 11px;
            display: flex;
            justify-content: space-between;
            .check-phonetic {
              display: flex;
              align-items: center;
              a {
                padding-left: 6px;
                padding-right: 5px;
                display: inline-block;
                border-radius: 4px;
                &:hover {
                  background-color: #f0f2f5;
                }
              }
            }
            .ocr-show-item-action-title {
              font-size: 14px;
              padding-left: 10px;
              font-weight: bold;
              padding-bottom: 10px;
            }
            .ocr-show-item-action-radio {
              font-size: 14px;
              .error-span {
                background-color: #f7f7f7;
                position: relative;
                .anticon-check-circle {
                  position: absolute;
                  top: -2px;
                  right: -4px;
                  font-size: 10px;
                  color: var(--theme-color);
                }
              }
              .no-error-span {
                position: relative;
                .anticon-check-circle {
                  position: absolute;
                  top: -2px;
                  right: -4px;
                  font-size: 10px;
                  color: var(--theme-color);
                }
              }
              > span {
                display: inline-block;
                width: 25px;
                height: 25px;
                text-align: center;
                line-height: 25px;
                cursor: pointer;
                &:hover {
                  background-color: #f7f7f7;
                }
              }
            }
            .ocr-show-item-action-input {
              padding-top: 10px;
            }
            /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
          }
        }
      }
      &::-webkit-scrollbar-button {
        height: 0;
      }
      &::-webkit-scrollbar {
        border-radius: 0;
        position: relative;
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 0;
        background: #fff;
      }
      &::-webkit-scrollbar-track-piece {
        border-radius: 0;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: #c1c1c1;
      }
      .show-img-bottom-left {
        margin-right: 3px;
      }
    }
  }
}
.show-img-bottom-left,
.show-img-bottom-right {
  position: relative;
  /* transition: all 0.3s; */
  .translate-bottom-left-show-icon {
    position: absolute !important;
    z-index: 990;
    top: calc(50% - 50px);
    display: flex;
    height: 100px;
    width: 10px;
    left: 0;
    background: #fff;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 9px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s;
    opacity: 0;
    &:hover {
      color: var(--theme-color);
    }
  }
  &:hover {
    .translate-bottom-left-show-icon {
      opacity: 1;
    }
  }
  .translate-bottom-right-show-icon {
    position: absolute !important;
    z-index: 990;
    top: calc(50% - 50px);
    display: flex;
    height: 100px;
    width: 10px;
    right: 0;
    background: #fff;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    font-size: 9px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s;
    opacity: 0;
    &:hover {
      color: var(--theme-color);
    }
  }
  &:hover {
    .translate-bottom-right-show-icon {
      opacity: 1;
    }
  }
}
.copy-img-div {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 50%;
  outline: none;
}
/deep/ .line-handle {
  cursor: move;
  position: absolute;
  width: 10px;
  height: 10px;
  display: block;
  border: 1px solid var(--theme-color) !important;
  background: #fff;
  border-radius: 50% !important;
}
/deep/ .svg-line line {
  cursor: grab;
}
.copy-img-div {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 50%;
  outline: none;
  color: rgba(0, 0, 0, 0);
}
</style>
